import React from 'react';



class Service extends React.Component {


  render() {


    return (
      <div className="privacy">

        <h1>Terms of Service</h1>

        <p><strong><h3>Thank you for using Hala Homes!</h3></strong></p>
        <span>These Terms of Service (“Terms”) are a binding legal agreement between you and Hala Homes that govern your use of the websites, applications, and other offerings from Hala Homes (collectively, the “Hala Homes Platform”). When used in these Terms, “Hala Homes,” “we,” “us,” or “our” refers to the Hala Homes entity with whom you are contracting.</span>
        <span>The Hala Homes Platform offers an online venue that enables users (“Members”) to publish, offer, search for, and book services. Members who publish and offer services are “Hosts” and Members who search for, book, or use services are “Guests.” Hosts offer accommodations (“Accommodations”), activities, excursions, and events (“Experiences”), and a variety of travel and other services (collectively, “Host Services,” and each Host Service offering, a “Listing”). You must register an account to access and use many features of the Hala Homes Platform, and must keep your account information accurate. As the provider of the Hala Homes Platform, Hala Homes does not own, control, offer or manage any Listings or Host Services. Hala Homes is not a party to the contracts concluded directly between Hosts and Guests, nor is Hala Homes a real estate broker or insurer. Hala Homes is not acting as an agent in any capacity for any Member, except as specified in the (“Payment Terms”).</span>



        <p><strong><h3>Guest Terms</h3></strong></p>
        <p><strong><h3>1. Our Mission.</h3></strong></p>
        <span>Our mission is to create a world where you can belong anywhere. From cabins to castles to cooking classes, browse through millions of Listings to find the ones that fit the way you like to travel. Learn more about a Listing by reviewing the description and photos, the Host profile, and Guest reviews. If you have questions, just message the Host.</span>

        <p><strong><h3>2. Searching and Booking on Hala Homes.</h3></strong></p>

        <p><strong><h4>2.1 Searching</h4></strong></p>
        <span>You can search for Host Services by using criteria like the type of Host Service, travel destination, travel dates, and number of guests. You can also use filters to refine your search results. Search results are based on their relevance to your search and other criteria. Relevance considers factors like price, availability, Reviews, customer service and cancellation history, popularity, previous trips and saved Listings, Host requirements (e.g. minimum or maximum nights), and more.</span>

        <p><strong><h4>2.2 Booking</h4></strong></p>
        <span>When you book a Listing, you are agreeing to pay all charges for your booking including the Listing price, applicable fees like service fees, Taxes, and any other items identified during checkout (collectively, “Total Price”). You are also agreeing that Hala Homes Payments may charge and collect any security deposit identified during checkout. When you receive the booking confirmation, a contract for Host Services (sometimes called a reservation in these Terms) is formed directly between you and the Host. The cancellation policy and any other rules, standards, policies, or requirements identified in the Listing or during checkout form part of your contract with the Host. Be aware that some Hosts work with a co-host or as part of a team to provide their Host Services.</span>

        <p><strong><h4>2.3 Accommodation Reservations. </h4></strong></p>
        <span>An Accommodation reservation is a limited license to enter, occupy, and use the Accommodation. The Host retains the right to re-enter the Accommodation during your stay, to the extent: (i) it is reasonably necessary, (ii) permitted by your contract with the Host, and (iii) consistent with applicable law. If you stay past checkout, the Host has the right to make you leave in a manner consistent with applicable law, including by imposing reasonable overstay penalties. You may not exceed the maximum number of allowed Guests.</span>

        <p><strong><h4>2.4 Reservations for Experiences and Other Host Services.</h4></strong></p>
        <span>An Experience or other Host Service reservation entitles you to participate in, attend, or use that Host Service. You are responsible for confirming that you, and anyone you invite, meet minimum age, proficiency, fitness, or other requirements. You are responsible for informing the Host of any medical or physical conditions, or other circumstances that may impact your ability to participate, attend, or use the Host Service. Except where expressly authorized, you may not allow any person to join a Host Service unless they are included as an additional guest during the booking process.</span>

        <p><strong><h4>3.1 Cancellations, Travel Issues, and Refunds. </h4></strong></p>
        <span> In general, if as a Guest you cancel a reservation, the amount refunded to you is determined by the cancellation policy that applies to that reservation. But, in certain situations, other policies take precedence and determine what amount is refunded to you. If something outside your control forces you to cancel a reservation, you may be eligible for a partial or full refund under certain extenuating conditions. If the Host cancels, or you experience a Travel Issue. Different policies apply to certain categories of Listings; for example Experiences reservations are governed by the guest refund policy.</span>

        <p><strong><h4>3.2 Booking Modifications. </h4></strong></p>
        <span>Guests and Hosts are responsible for any booking modifications they agree to make via the Hala Homes Platform or direct Hala Homes customer service to make on their behalf ("Booking Modifications"), and agree to pay any additional amounts, fees, or taxes associated with any Booking Modification.</span>

        <p><strong><h3>4. Your Responsibilities and Assumption of Risk.</h3></strong></p>

        <p><strong><h4>4.1 Your Responsibilities.  </h4></strong></p>
        <span>You are responsible and liable for your own acts and omissions and are also responsible for the acts and omissions of anyone you invite to join or provide access to any Accommodation, Experience or other Host Service. For example, this means: (i) you are responsible for leaving an Accommodation (and related personal property) in the condition it was in when you arrived, and (ii) you must act with integrity, treat others with respect, and comply with applicable laws at all times. If you are booking for an additional guest who is a minor or if you bring a minor to a Host Service, you must be legally authorized to act on behalf of the minor and you are solely responsible for the supervision of that minor.</span>

        <p><strong><h4>4.2 Your Assumption of Risk </h4></strong></p>
        <span>You acknowledge that many activities carry inherent risks and agree that, to the maximum extent permitted by applicable law, you assume the entire risk arising out of your access to and use of the Hala Homes Platform and any Content (as defined in Section 10), including your stay at any Accommodation, participation in any Experience, use of any other Host Service, or any other interaction you have with other Members whether in person or online. This means it is your responsibility to investigate a Host Service to determine whether it is suitable for you. For example, Host Services may carry risk of illness, bodily injury, disability, or death, and you freely and willfully assume those risks by choosing to participate in those Host Services.</span>

        <p><strong><h3>Payment and Fees</h3></strong></p>
        <p><strong><h3>5. Fees.</h3></strong></p>

        <span>Hala Homes may charge fees (and applicable Taxes) to Hosts and Guests for use of the Hala Homes Platform. Except as otherwise provided on the Hala Homes Platform, service fees are non-refundable. Hala Homes reserves the right to change the service fees at any time, and will provide Members notice of any fee changes before they become effective. Fee changes will not affect bookings made prior to the effective date of the fee change. If you disagree with a fee change you may terminate this agreement at any time pursuant to Section 13.2.</span>

        <p><strong><h3>6. Hala Homes Platform Rules.</h3></strong></p>
        <p><strong><h4>6.1 Rules. You must follow these rules and must not help or induce others to break or circumvent these rules.</h4></strong></p>
        <span>Act with integrity and treat others with respect Do not lie, misrepresent something or someone, or pretend to be someone else.</span>
        <span>Be polite and respectful when you communicate or interact with others. Do not discriminate against or harass others.</span>
        <span>Do not scrape, hack, reverse engineer, compromise or impair the Hala Homes Platform</span>
        <span>Do not use bots, crawlers, scrapers, or other automated means to access or collect data or other content from or otherwise interact with the Hala Homes Platform.</span>
        <span>Do not hack, avoid, remove, impair, or otherwise attempt to circumvent any security or technological measure used to protect the Hala Homes Platform or Content.</span>
        <span>Do not decipher, decompile, disassemble, or reverse engineer any of the software or hardware used to provide the Hala Homes Platform.</span>
        <span>Do not take any action that could damage or adversely affect the performance or proper functioning of the Hala Homes Platform.</span>
        <span>Only use the Hala Homes Platform as authorized by these Terms or another agreement with us.You may only use another Member’s personal information as necessary to facilitate a transaction using the Hala Homes Platform as authorized by these Terms.</span>
        <span>Do not use the Hala Homes Platform, our messaging tools, or Members’ personal information to send commercial messages without the recipient’s express consent.</span>
        <span>You may use Content made available through the Hala Homes Platform solely as necessary to enable your use of the Hala Homes Platform as a Guest or Host.</span>
        <span>Do not use Content unless you have permission from the Content owner or the use is authorized by us in these Terms or another agreement you have with us.</span>
        <span>Do not request, make, or accept a booking or any payment outside of the Hala Homes Platform to avoid paying fees, taxes or for any other reason. </span>
        <span>Do not require or encourage Guests to open an account, leave a review, complete a survey, or otherwise interact, with a third party website, application or service before, during or after a reservation, unless authorized by Hala Homes.</span>
        <span>Do not engage in any practices that are intended to manipulate our search algorithm.</span>
        <span>Do not book Host Services unless you are actually using the Host Services.Do not use, copy, display, mirror or frame the Hala Homes Platform, any Content, any Hala Homes branding, or any page layout or design without our consent.</span>
        <span>Honor your legal obligations Understand and follow the laws that apply to you, including privacy, data protection, and export laws.</span>
        <span>If you provide us with someone else’s personal information, you: (i) must do so in compliance with applicable law, (ii) must be authorized to do so, and (iii) authorize us to process that information under our Privacy Policy.</span>
        <span>Do not organize or facilitate unauthorized parties or events. You are responsible and liable for any party or event during your reservation.</span>
        <span>Do not use the name, logo, branding, or trademarks of Hala Homes or others without permission.</span>
        <span>Do not use or register any domain name, social media handle, trade name, trademark, branding, logo, or other source identifier that may be confused with Hala Homes branding.</span>
        <span>Do not offer Host Services that violate the laws or agreements that apply to you.</span>
        <span>Do not offer or solicit prostitution or participate in or facilitate human trafficking.</span>


        <p><strong><h3>7. Termination, Suspension and other Measures.</h3></strong></p>
        <p><strong><h4>7.1 Term</h4></strong></p>
        <span>The agreement between you and Hala Homes reflected by these Terms is effective when you access the Hala Homes Platform (for example to create an account) and remains in effect until either you or we terminate the agreement in accordance with these Terms.</span>
        
        <p><strong><h4>7.2 Termination</h4></strong></p>
        <span>You may terminate this agreement at any time by sending us an email or by deleting your account. Hala Homes may terminate this agreement and your account for any reason by giving you 30 days’ notice via email or using any other contact information you have provided for your account. Hala Homes may also terminate this agreement immediately and without notice and stop providing access to the Hala Homes Platform if you breach these Terms, you violate our Policies, you violate applicable laws, or we reasonably believe termination is necessary to protect Hala Homes, its Members, or third parties. If your account has been inactive for more than two years, we may terminate your account without prior notice.</span>

        <p><strong><h4>7.3 Member Violations</h4></strong></p>
        <span>If (i) you breach these Terms, our policies, or our standards, (ii) you violate applicable laws, regulations, or third-party rights, or (iii) Hala Homes believes it is reasonably necessary to protect Hala Homes, its Members, or third parties; Hala Homes may, with or without prior notice:</span>
        <span>suspend or limit your access to or use of the Hala Homes Platform and/or your account;suspend or remove Listings, Reviews, or other Content;cancel pending or confirmed bookings; or suspend or revoke any special status associated with your account.For minor violations or where otherwise appropriate as Hala Homes determines in its sole discretion, you will be given notice of any intended measure by Hala Homes and an opportunity to resolve the issue. You may appeal actions taken by us under this Section by contacting customer service. If a reservation is cancelled under this Section, the amount paid to the Host will be reduced by the amount we refund or otherwise provide to the Guest, and by any other costs we incur as a result of the cancellation.</span>

        <p><strong><h4>7.4 Legal Mandates. </h4></strong></p>
        <span>Hala Homes may take any action it determines is reasonably necessary to comply with applicable law, or the order or request of a court, law enforcement, or other administrative agency or governmental body, including the measures described above in Section 7.3.</span>

        <p><strong><h3>8. Modification.</h3></strong></p>
        <span>Hala Homes may modify these Terms at any time. When we make material changes to these Terms, we will post the revised Terms on the Hala Homes Platform and update the “Last Updated” date at the top of these Terms. We will also provide you with notice of any material changes by email at least 30 days before the date they become effective. If you disagree with the revised Terms, you may terminate this agreement immediately as provided in these Terms. If you do not terminate your agreement before the date the revised Terms become effective, your continued access to or use of the Hala Homes Platform will constitute acceptance of the revised Terms.</span>

        <p><strong><h3>9. Resolving Complaints and Damage Claims.</h3></strong></p>
        <span>If a Member provides evidence that another Member damaged their real or personal property ("Damage Claim"), the complaining Member can seek compensation through the damage claim. If the complaining Member escalates a Damage Claim to Hala Homes, the other Member will be given an opportunity to respond. If the responding Member agrees to pay, or Hala Homes determines in its sole discretion that they are responsible for the Damage Claim, Hala Homes via Hala Homes Payments can collect any sums required to cover the Damage Claim from the responding Member and/or against any security deposit. You agree that Hala Homes may seek to recover from you under any insurance policies you maintain and that Hala Homes may also pursue against you any remedies it may have available under applicable law. You agree to cooperate in good faith, provide any information Hala Homes requests, execute documents, and take further reasonable action, in connection with Damage Claims, Member complaints, claims under insurance policies, or other claims related to your provision or use of Host Services.</span>

        <p><strong><h3>10. Member Accounts.</h3></strong></p>
        <span>You must register an account to access and use many features of the Hala Homes Platform. Registration is only permitted for legal entities, partnerships and natural persons who are 18 years or older. You represent and warrant that you are not a person or entity barred from using the Hala Homes Platform under the laws of the United States, your place of residence, or any other applicable jurisdiction. You must provide accurate, current, and complete information during registration and keep your account information up-to-date. You may not register more than one account or transfer your account to someone else. You are responsible for maintaining the confidentiality and security of your account credentials and may not disclose your credentials to any third party. You are responsible and liable for activities conducted through your account and must immediately notify Hala Homes if you suspect that your credentials have been lost, stolen, or your account is otherwise compromised. If and as permitted by applicable law, we may, but have no obligation to (i) ask you to provide identification or other information, (ii) undertake checks designed to help verify your identity or background, (iii) screen you against third-party databases or other sources and request reports from service providers, and (iv) obtain reports from public records of criminal convictions or sex offender registrations or their local equivalents.</span>

        <p><strong><h4>11.3 Accommodation Reservations. </h4></strong></p>
        <span>An Accommodation reservation is a limited license to enter, occupy and use the Accommodation. The Host retains the right to re-enter the Accommodation during your stay, to the extent: (i) it is reasonably necessary, (ii) permitted by your contract with the Host, and (iii) consistent with applicable law. If you stay past checkout, the Host has the right to make you leave in a manner consistent with applicable law, including by imposing reasonable overstay penalties. You may not exceed the maximum number of allowed Guests</span>

        <p><strong><h4>12.1 Cancellations, Travel Issues, and Refunds. </h4></strong></p>
        <span>In general, if you cancel a reservation, the amount refunded to you is determined by the cancellation policy that applies to that reservation. But, in certain situations, other policies take precedence and determine what amount is refunded to you. If something outside your control requires you to cancel a reservation, you may be entitled to a partial or full refund under our Extenuating circumstances. If the Host cancels, or you experience a Travel Issue (as defined in our Guest refund, you may be entitled to rebooking assistance or a partial or full refund under our Guest refund policy Different policies apply to certain categories of Listings; for example Experiences reservations are governed by the Guest refund policy. </span>

        <p><strong><h4>12.2 Booking Modifications.</h4></strong></p>
        <span>Hosts and Guests are responsible for any booking modifications they agree to make via the Hala Homes Platform or direct Hala Homes customer service to make on their behalf ("Booking Modifications"), and agree to pay any additional amounts, fees or taxes associated with any Booking Modification.</span>

        <p><strong><h3>13. Complaints Handling and Mediation.</h3></strong></p>
        <span>We want to be transparent about how we handle complaints and aim to treat all Business Users fairly. Send us an email to facilitate access our internal complaint-handling system and what you can expect as a Business User when you make a complaint in relation to issues.</span>

        <p><strong><h3>14. Access to Data.</h3></strong></p>
        <span>Business Users have access to personal and other data in their Hala Homes account and host dashboard which is provided by the Business User, their Guests or generated through the use of the Hala Homes Platform and which is necessary for the performance of their Host Services as well as aggregated information about searches, bookings and the performance of their listings.</span>

        <p><strong><h3>15. Additional Distribution Channels.</h3></strong></p>
        <span>Hala Homes operates an affiliate program through which Listings may be featured on third party websites, such as those for online travel sites, media outlets, loyalty programs, and search aggregators. Listings may also appear in advertisements for Hala Homes published on third party websites from time to time.</span>
































      </div>
    );
  }
}

export default Service;
