import React from 'react';
import { Row } from 'antd';
import axios from 'axios';
import { Form, Input, Button  } from 'antd';
import Loader2 from '../../widget/Loader2';
import PANEL_BASE_URL from '../../../PANEL_BASE_URL';


class CompanyEditAgent extends React.Component {

  state = {
    processing:false,
    detail_data : "",
  }

   onFinish = values => {
    this.setState({ processing : true});
    const postdata = {
      "token":localStorage.getItem("companytoken"),
      "email":`${this.props.match.params.email}`,
      "name":values.name,
      "languages":values.languages,
      "country":values.country,
    }
    axios.post(PANEL_BASE_URL + `/editAgent`,  postdata )
    .then((res) => {
      if(res.data.result === 'ok'){
        this.setState({
          processing : false,
        });  
        return window.location.href = '/panel/company/listagent'
      }
      else if (res.data.error === "unauthenticated"){
        localStorage.clear(); 
        return window.location.href = '/login'
      } 
    })
  };


  formRef = React.createRef();


  componentDidMount(){
    this.setState({ processing : true});
    const postdata = {
      "token":localStorage.getItem("companytoken"),
      "email":`${this.props.match.params.email}`,
    }
    axios.post(PANEL_BASE_URL + `/selectAgent`,  postdata )
    .then((res) => {
      if(res.data.result === 'ok'){
        this.setState({
          detail_data : res.data.data ,
          processing : false,
        });  
        this.formRef.current.setFieldsValue(this.state.detail_data);
      }
      else if (res.data.error === "unauthenticated"){
        localStorage.clear(); 
        return window.location.href = '/login'
      } 
    })
    
  }  
 
  render() {
    const layout = {
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
    };
    
    return (
      <>
        {this.state.processing ? <Loader2/> : null}
        <Form ref={this.formRef} {...layout} name="nest-messages" onFinish={this.onFinish} >
          <Form.Item name='name' label="name" >
            <Input />
          </Form.Item>
          <Form.Item name='languages' label="languages" >
            <Input />
          </Form.Item>
          <Form.Item name='country' label="country" >
            <Input />
          </Form.Item>
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
      </Form>
      </>
     
    );
  }
}


export default CompanyEditAgent;
