import React from 'react';
import Head from '../widget/Head';
import HeadList from '../widget/HeadList';
import Footer from '../widget/Footer';
import ListHotelPage from '../widget/ListHotelPage';




class ListCar extends React.Component {


  render() {


    return (
      <div className="main">
        <Head/>
        <HeadList/>
        <ListHotelPage/>
        <Footer/>
      </div>
    );
  }
}

export default ListCar;
