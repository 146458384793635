import React from 'react';
import { Form, Input, Button ,Select } from 'antd';
import BASE_URL from '../../../BASE_URL';
import axios from 'axios';
import { UserOutlined , KeyOutlined  } from '@ant-design/icons';
// import logologin from '../../img/logo-login.png'
import { Link} from 'react-router-dom';
import Loader2 from '../../widget/Loader2';
import Logo from '../../img/logo2.png';


class AdminLogin extends React.Component {

  state = {
    processing : false ,
  }
  onFinish = values => {
    this.setState({processing: true});
    const postdata = {
      "username":values.email ,
      "password":values.password,
    }
    axios.post(BASE_URL + `/admin_login` , postdata)
    .then((res) => {
      if(res.data.result === 'ok'){ 
          localStorage.setItem("role", "admin");
          localStorage.setItem("admintoken", res.data.data.token);
          this.setState({processing: false});
          return window.location.href = '/panel/admin/dashboard'
      } 
      else {
        this.setState({processing: false});
        alert("You entered the username or password incorrectly. Please be careful in choosing the role.")
      }
    })
  };
 
  render() {

    return (
      <div className="login-amaz">
        {this.state.processing ? <Loader2/> : null}
        <div className="login-amaz-main">
          {/* <div className="login-amaz-head">
            <div className="login-amaz-head-button login-amaz-head-button-active">Login</div>
            <Link to="/register"><div className="login-amaz-head-button">Register</div></Link>
            <Link to="/companyregister"><div className="login-amaz-head-button">Company</div></Link>
          </div> */}
          <div className="login-amaz-cont">
            <Link to="/"><img src={Logo} alt="logo"></img></Link>
            {/* <h4>Login</h4> */}
            <Form
              name="loginAmaz"
              onFinish={this.onFinish}
            >
              <p style={{fontWeight:"bold"}}>Email Address</p>
              <Form.Item 
                name="email"  
                rules={[{ required: true, message: 'Please input your Email!'  }]}
              >
                <Input placeholder="Email" prefix={<UserOutlined />}/>
              </Form.Item>
              <p style={{fontWeight:"bold"}}>Password</p>
              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
              >
                <Input.Password placeholder="Password" prefix={<KeyOutlined />}/>
              </Form.Item>
              <Form.Item >
                <Button className="login-btn-panels" type="primary" htmlType="submit">
                  Login
                </Button>
              </Form.Item>
            </Form>  
          </div>
        </div>
      </div>
    );
  }
}


export default AdminLogin;
