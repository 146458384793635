import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ChangeColor from './component/widget/ChangeColor';
import First from './component/page/First';
import List from './component/page/List';
import ListCar from './component/page/ListCar';
import ListHotel from './component/page/ListHotel';
import Detail from './component/page/Detail';
import Login from './component/panel/Login/Login';
import Register from './component/panel/Login/Register';
import AdminLogin from './component/panel/Login/AdminLogin';
import CompanyRegister from './component/panel/Login/CompanyRegister';
import PrivateRoute from './PrivateRoute';
import Panel from './component/panel/Panel';
import './css/style.css';
import './css/stylePanel.css';
import DetailHotel from './component/page/DetailHotel';
import DetailCar from './component/page/DetailCar';
import ModalUserAuth from './component/panel/Login/ModalUserAuth';
import Privacy from './component/page/Privacy/Privacy';
import Service from './component/page/Privacy/Service';

function App() {
  return (
  <>  
    <ChangeColor/>
    <ModalUserAuth/>
    <Router>
        <Route exact path='/' component={First} />
        <Route exact path='/list' component={List} />
        <Route exact path='/listcar' component={ListCar} />
        <Route exact path='/listhotel' component={ListHotel} />
        <Route exact path='/detail/:id' component={Detail} />
        <Route exact path='/detailhotel/:id' component={DetailHotel} />
        <Route exact path='/detailcar/:id' component={DetailCar} />

        {/* Route For Login */}
        <Route exact path='/login' component={Login} />
        <Route exact path='/adminlogin' component={AdminLogin} />
        <Route exact path='/register' component={Register} />
        <Route exact path='/companyregister' component={CompanyRegister} />
        <PrivateRoute  path='/panel' component={Panel} />

        {/* Route For Privacy */}
        <Route exact path='/privacy' component={Privacy} />
        <Route exact path='/service' component={Service} />

    </Router> 
  </>  
  );
}

export default App;
