import React from 'react';


function CheckUserAuth(){
  if (localStorage.getItem("usertoken") != null && localStorage.getItem("role") == "user"){
    return true
  }
  else {
    return false
  }
}

export default CheckUserAuth;
