import React from 'react';
import { Carousel  } from 'antd';
import { LineChartOutlined , BlockOutlined , FundViewOutlined , SketchOutlined } from '@ant-design/icons';
import Fade from 'react-reveal/Fade';

class AgentSection extends React.Component {


  render() {
    return (
    <div className="agent-section">
      <div className="agent-section-cover">
        <div className="agent-section-cover-init">
          <Fade left>
            <div className="agent-section-cover-init-two">
              <div className="agent-section-cover-init-two-init">
                <h3>Counters</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              </div>
            </div>
          </Fade>
          <Fade right>
          `  <div className="agent-section-cover-init-one">
              <div className="agent-section-cover-init-one-init">
                <div className="agent-section-cover-init-one-init-item">
                  <div className="agent-section-cover-init-one-init-item-init">
                    <LineChartOutlined />
                    <h5>Listings For Sale</h5>
                  </div>
                </div>
                <div className="agent-section-cover-init-one-init-item">
                  <div className="agent-section-cover-init-one-init-item-init">
                    <BlockOutlined />
                    <h5>Listings For Sale</h5>
                  </div>
                </div>
                <div className="agent-section-cover-init-one-init-item">
                  <div className="agent-section-cover-init-one-init-item-init">
                    <FundViewOutlined />
                    <h5>Listings For Sale</h5>
                  </div>
                </div>
                <div className="agent-section-cover-init-one-init-item">
                  <div className="agent-section-cover-init-one-init-item-init">
                    <SketchOutlined />
                    <h5>Listings For Sale</h5>
                  </div>
                </div>
              </div>
            </div>`
          </Fade>
        </div>
      </div>  
    </div>
    );
  }
}

export default AgentSection;
