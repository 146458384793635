function ListPeropertyReducer(state = {
    listdata : [] ,
    listcardata : [] ,
    listhoteldata : [] ,
    visibleAuth : false ,
    }, action) {
    switch (action.type) {
        case 'listdata_Loader' :
            return {
                ...state,
                listdata: action.payload,   
            };   
            case 'listcardata_Loader' :
                return {
                    ...state,
                    listcardata: action.payload,   
            };
            case 'listhoteldata_Loader' :
                return {
                    ...state,
                    listhoteldata: action.payload,   
            };   
            case 'visibleAuth_Loader' :
                return {
                    ...state,
                    visibleAuth: action.payload,   
            }; 
        default:
            return state
    }
}
export default ListPeropertyReducer;
