import React from 'react';
import { Menu , Tabs } from 'antd';
import { DatabaseOutlined , HomeOutlined , CarOutlined} from '@ant-design/icons';
import RentSearch from './RentSearch';
import BuySearch from './BuySearch';
import CommercialSearch from './CommercialSearch';
import CarSearch from './CarSearch';
import HotelSearch from './HotelSearch';
import HomeSearch from './HomeSearch';
import Zoom from 'react-reveal/Zoom';



class Filter extends React.Component {


  render() {
    const { TabPane } = Tabs;
    return (
      <Zoom left>
     <div className={this.props.classdata}>
         <Tabs defaultActiveKey="1" >
         <TabPane tab="Hotel" key="1">
            <HotelSearch/>
          </TabPane>
          {/* <TabPane tab="Rent" key="2">
            <RentSearch/>
          </TabPane>
          <TabPane tab="Buy" key="3">
            <BuySearch/>
          </TabPane>
          <TabPane tab="Commercial" key="4">
            <CommercialSearch/>
          </TabPane> */}
          <TabPane tab="Home" key="6">
            <HomeSearch/>
          </TabPane>
          <TabPane tab="Car" key="5">
            <CarSearch/>
          </TabPane>
        </Tabs>
     </div>
     </Zoom>
    );
  }
}

export default Filter;
