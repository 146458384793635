import React from 'react';
import { Form, Input, Button ,Row , Modal } from 'antd';
import BASE_URL from '../../../BASE_URL';
import axios from 'axios';
import { UserOutlined , KeyOutlined , UserAddOutlined , PhoneOutlined  } from '@ant-design/icons';
// import logologin from '../../img/logo-login.png'
import { Link} from 'react-router-dom';
import Loader2 from '../../widget/Loader2';
import Logo from '../../img/logo2.png';
import { Upload } from 'antd';



class CompanyRegister extends React.Component {


  state = {
    processing : false ,
    fileList : [],
    visible:false ,
    cont_modal : " ",
  }


  onChange = ({ fileList: newFileList }) => {
    this.setState({fileList :newFileList});
  };

  onPreview = async file => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  onFinish = values => {
    this.setState({processing:true});
    const bodyFormData = new FormData();
    bodyFormData.append('name', values.name);
    bodyFormData.append('email', values.email);
    bodyFormData.append('password', values.password);
    bodyFormData.append('about', values.about);
    for(var i in this.state.fileList){
      bodyFormData.append("file"+ i,this.state.fileList[i].originFileObj );
    }
    axios.post(BASE_URL + `/createCompany`, bodyFormData , {headers: {"Content-type": "multipart/form-data"}})
    .then((res) => {
      console.log(res);
      if(res.data.result === "ok"){
        this.setState({
          processing:false,
          visible:true,
          cont_modal:"Registration is over. Wait for admin approval",
        });
      }
      else if (res.data.result === "error") {
        this.setState({
          processing:false,
          visible:true,
          cont_modal : res.data.error ,
        })
      }
     })
  };

  handleCancel = () => {
    this.setState({visible :false});
  }
 
  render() {

    return (
      <div className="login-amaz">
        {this.state.processing ? <Loader2/> : null}
        <Modal
            onCancel={this.handleCancel}
            visible={this.state.visible}
            footer={null}
          >
            <p>{this.state.cont_modal}</p>
        </Modal>  
        <div className="login-amaz-main">
          <div className="login-amaz-head">
          <Link to="/login"><div className="login-amaz-head-button ">Login</div></Link>
          <Link to="/register"><div className="login-amaz-head-button ">Register</div></Link>
          <div className="login-amaz-head-button login-amaz-head-button-active">Company</div>
          </div>
          <div className="login-amaz-cont">
            <Link to="/"><img src={Logo} alt="logo"></img></Link>
            <h5>Hello Everyone , Welcome to RealEstate</h5>
            <h4>Company Register</h4>
            <Form
              name="loginAmaz"
              onFinish={this.onFinish}
            >
              <p style={{fontWeight:"bold"}}>Name</p>
              <Form.Item 
                name="name"  
                rules={[{ required: true, message: 'Please input your Name!' }]}
              >
                <Input placeholder="Full Name" prefix={<UserAddOutlined  />}/>
              </Form.Item>
              <p style={{fontWeight:"bold"}}>Email Address</p>
              <Form.Item 
                name="email"  
                rules={[{ required: true, message: 'Please input your Email!' , type: 'email' }]}
              >
                <Input placeholder="Email" prefix={<UserOutlined />}/>
              </Form.Item>
              <p style={{fontWeight:"bold"}}>Password</p>
              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
              >
                <Input.Password placeholder="Password" prefix={<KeyOutlined />}/>
              </Form.Item>
              <p style={{fontWeight:"bold"}}>About</p>
              <Form.Item 
                name="about"  
                rules={[{ required: true, message: 'Please input your about!' }]}
              >
                <Input placeholder="About" prefix={<UserOutlined />}/>
              </Form.Item>
              <p>
                <p>You can upload your documents (png , jpg , pdf)</p>
                    <Upload
                      accept=".png , .jpg , .jpeg"
                      listType="picture-card"
                      fileList={this.state.fileList}
                      onChange={this.onChange}
                      onPreview={this.onPreview}
                      customRequest={this.dummyRequest}
                      >
                      {this.state.fileList.length < 2 && '+ Upload'}
                    </Upload>
              </p>
              <Form.Item >
                <Button className="login-btn-panels" type="primary" htmlType="submit">
                  Register
                </Button>
              </Form.Item>
            </Form>  
          </div>
        </div>
      </div>
    );
  }
}


export default CompanyRegister;
