import React from 'react';
import { Tabs } from 'antd';

import {CheckOutlined } from '@ant-design/icons';



class TabDetail extends React.Component {


  render() {
    const { TabPane } = Tabs;
    return (
     <div className="detail-tab">
         <Tabs defaultActiveKey="2" >
          <TabPane tab="Condition" key="2">
            <h3>Condition</h3>
            <div className="detail-tab-item-panel">
              <div className="detail-tab-item-panel-item"><CheckOutlined /> {this.props.detail.bedroom} Bed</div>
              <div className="detail-tab-item-panel-item"><CheckOutlined /> {this.props.detail.bathroom} Bath</div>
              <div className="detail-tab-item-panel-item"><CheckOutlined /> {this.props.detail.garage_capacity} Garage capacity</div>
              <div className="detail-tab-item-panel-item"><CheckOutlined /> {this.props.detail.year_built} Year built</div>
              <div className="detail-tab-item-panel-item"><CheckOutlined /> {this.props.detail.size} Size</div>
              <div className="detail-tab-item-panel-item"><CheckOutlined /> {this.props.detail.type == 1 ? <>Aparteman</> : <>House</>}</div>

            </div>
          </TabPane>
          <TabPane tab="Description" key="1">
            <h3>Description</h3>
            <p>
              {this.props.detail.description}
            </p>
          </TabPane>
        </Tabs>
     </div>
    );
  }
}

export default TabDetail;
