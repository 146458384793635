import React from 'react';
import { Form, Input, Button , Alert , Select, InputNumber} from 'antd';
import { Descriptions  } from 'antd';
import { Table, Tag, Space } from 'antd';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  EditOutlined,
  DeleteOutlined  
} from '@ant-design/icons';
import { Modal  } from 'antd';
import Loader2 from '../../widget/Loader2';
import BASE_URL_Majid from '../../../BASE_URL_Majid';




class AdminListSuite extends React.Component {

  state = {
    visible: false,
    visible_detail_modal:false,
    visible_delete_modal:false ,
    visible_add_modal:false ,
    visible_edit_modal:false ,
    list_data: [],
    list_hotel : [] ,
    processing: false ,
    delete_id :0 ,
    delete_type :" " ,
    edit_type :" " ,
    detail_data:"",
  }

  formRefEdit = React.createRef();
  
  // نمایش مدال برای حذف پروپرتی
  deleteshowmodal = (id , type) => {
    this.setState({
      visible_delete_modal: true,
      delete_id: id,
      delete_type : type ,
    });
  };


  onFinishAdd=(values)=>{
    this.setState({process:true})
    const postdata = {
      "hotel_id":values.hotel_id,
      "kitchen":values.kitchen,
      "bathroom":values.bathroom,
      "breakfast_included":values.breakfast_included,
      "terrace":values.terrace,
      "air_condition":values.air_condition,
      "bed":values.bed,
      "room":values.room,
      "wifi":values.wifi,
      "tv":values.tv,
      "animal":values.animal,
      "number":values.number,
      "price":values.price
    }
    axios.post(BASE_URL_Majid + `/suites` , postdata )
    .then((res) => {
      if(res.data.result === 'ok'){
        this.setState({
          visible_add_modal : false ,
          processing : false,
        });  
        this.componentDidMount();
      }
      else if (res.data.error === "unauthenticated"){
        localStorage.clear(); 
        return window.location.href = '/login'
      }
    })
    .catch((err)=>{
      this.setState({process : false });
      alert("Network Error")
      console.log("err");
    })
  }

  onFinishEdit=(values)=>{
    this.setState({processing : true});
    const postdata = {
      "hotel_id":values.hotel_id,
      "kitchen":values.kitchen,
      "bathroom":values.bathroom,
      "breakfast_included":values.breakfast_included,
      "terrace":values.terrace,
      "air_condition":values.air_condition,
      "bed":values.bed,
      "room":values.room,
      "wifi":values.wifi,
      "tv":values.tv,
      "animal":values.animal,
      "price":values.price
    }
    axios.put(BASE_URL_Majid + `/suites/${this.state.edit_type}` , postdata )
    .then((res) => {
      if(res.data.result === 'ok'){
        this.setState({
          processing : false,
          visible_edit_modal : false ,
        });  
      }
      else if (res.data.error === "unauthenticated"){
        localStorage.clear(); 
        return window.location.href = '/login'
      }
    })
    .catch((err)=>{
      this.setState({process : false });
      alert("Network Error")
      console.log("err");
    })
  }


  displayEdit=(id , type)=>{
    this.setState({ processing : true});
    axios.get(BASE_URL_Majid + `/hotels/show_all` )
    .then((res) => {
      if(res.data.result === 'ok'){
        this.setState({
          list_hotel : res.data.data ,
          processing : false,
          visible_edit_modal : true ,
          edit_type : type ,
        });  
        for(let i of this.state.list_data){
          if(i.id == id){
            this.formRefEdit.current.setFieldsValue(i);
          }
        }
      }
      else if (res.data.error === "unauthenticated"){
        localStorage.clear(); 
        return window.location.href = '/login'
      }  
    })
    .catch((err)=>{
      this.setState({process : false });
      alert("Network Error")
      console.log("err");
    })
  }

  displayAddSuite=()=>{
    this.setState({ processing : true});
    axios.get(BASE_URL_Majid + `/hotels/show_all` )
    .then((res) => {
      if(res.data.result === 'ok'){
        this.setState({
          list_hotel : res.data.data ,
          processing : false,
          visible_add_modal : true ,
        });  
      }
      else if (res.data.error === "unauthenticated"){
        localStorage.clear(); 
        return window.location.href = '/login'
      }  
    })
    .catch((err)=>{
      this.setState({process : false });
      alert("Network Error")
      console.log("err");
    })
  }


  deleteitem = () => {
    this.setState({processing:true});
    axios.delete(BASE_URL_Majid + `/suites/${this.state.delete_type}` )
    .then((res) => {
      if(res.data.result === 'ok'){
        this.setState({
          processing : false,
          visible_delete_modal:false,
        });  
        this.componentDidMount();
      }
      else if (res.data.error === "unauthenticated"){
        this.setState({processing:false,})
        localStorage.clear(); 
        return window.location.href = '/login'
      }
    })
    .catch((err)=>{
      this.setState({process : false });
      alert("Network Error")
      console.log("err");
    })
  }


  // this function for show destail Modal;
  showdetailmodal = (id) => {
   for(let i of this.state.list_data){
     if(i.id == id){
       this.setState({
         detail_data : i ,
         visible_detail_modal : true ,
       })
     }
   }
  }





  handleCancel = () => {
    this.setState({
      visible: false,
      visible_detail_modal:false,
    visible_delete_modal:false ,

    });
  };

  componentDidMount(){
    this.setState({ processing : true});
    const postdata = {
      "token": localStorage.getItem("admintoken"),
    };
    axios.post(BASE_URL_Majid + `/suites/show_all` )
    .then((res) => {
      if(res.data.result === 'ok'){
        this.setState({
          list_data : res.data.data ,
          processing : false,
        });  
      }
      else if (res.data.error === "unauthenticated"){
        localStorage.clear(); 
        return window.location.href = '/login'
      }
    })
    .catch((err)=>{
      this.setState({process : false });
      alert("Network Error")
      console.log("err");
    })
  }
 
  render() {
 
    const columns = [
      {
        title: 'Suite Id',
        dataIndex: 'id',
        render: (text , row) => <span className="title-list-name" onClick={() => this.showdetailmodal(row.id)}>{row.id }</span>,
      },
      {
        title: 'Hotel Id',
        dataIndex: 'hotel_id',
      },
      {
        title: 'edit',
        render:  row => <EditOutlined onClick={()=>this.displayEdit(row.id , row.type)} />,
      },
      {
        title: 'delete',
        render:  row => <DeleteOutlined onClick={() => this.deleteshowmodal(row.id , row.type)} />,
      },


    ]

    const layout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
    };
    const { Option } = Select;

    return (
      <>
      {this.state.processing ? <Loader2/> : null}
      {/* this modal for show detail */}
      <Modal
          title="Detail"
          visible={this.state.visible_detail_modal}
          onCancel={this.handleCancel}
          footer={null}
          className="detail-property-modal"
          width={1000}
        >    
        <div>
          <Descriptions
            bordered
            // column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
          >
            <Descriptions.Item label="hotel id" span={1}>{this.state.detail_data.hotel_id}</Descriptions.Item>
            <Descriptions.Item label="kitchen" span={1}>{this.state.detail_data.kitchen == 1 ? <>Yes</> : <>No</>}</Descriptions.Item>
            <Descriptions.Item label="Breakfast included" span={1}>{this.state.detail_data.breakfast_included == 1 ? <>Yes</> : <>No</>}</Descriptions.Item>
            <Descriptions.Item label="terrace" span={1}>{this.state.detail_data.terrace == 1 ? <>Yes</> : <>No</>}</Descriptions.Item>
            <Descriptions.Item label="Air condition" span={1}>{this.state.detail_data.air_condition == 1 ? <>Yes</> : <>No</>}</Descriptions.Item>
            <Descriptions.Item label="Wifi" span={1}>{this.state.detail_data.wifi == 1 ? <>Yes</> : <>No</>}</Descriptions.Item>
            <Descriptions.Item label="Tv" span={1}>{this.state.detail_data.tv == 1 ? <>Yes</> : <>No</>}</Descriptions.Item>
            <Descriptions.Item label="Animal" span={1}>{this.state.detail_data.animal == 1 ? <>Yes</> : <>No</>}</Descriptions.Item>
            <Descriptions.Item label="Bathroom" span={1}>{this.state.detail_data.bathroom}</Descriptions.Item>
            <Descriptions.Item label="Bedroom" span={1}>{this.state.detail_data.bed}</Descriptions.Item>
            <Descriptions.Item label="Room" span={1}>{this.state.detail_data.room}</Descriptions.Item>
            <Descriptions.Item label="price" span={1}>{this.state.detail_data.price}</Descriptions.Item>
          </Descriptions>
        </div>
      </Modal>
      <Modal
          title="Add Room"
          visible={this.state.visible_add_modal}
          onCancel={()=>{this.setState({visible_add_modal : false })}}
          footer={null}
          width={1000}
        > 
        <Form {...layout} name="formAddSuite" onFinish={this.onFinishAdd} >
          <Form.Item label="Hotel" name="hotel_id" rules={[{ required: true, message: 'required!' }]}>
            <Select>
              {this.state.list_hotel.map((item, index) =>
                <Option key={index} value={item.id}>{item.name}</Option>
              )}
            </Select>
          </Form.Item>
          <Form.Item label="Kitchen" name="kitchen" rules={[{ required: true, message: 'required!' }]}>
            <Select>
              <Option value={1}>Yes</Option>
              <Option value={0}>No</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Bathroom" name="bathroom" rules={[{ required: true, message: 'required!' }]}>
            <InputNumber/>
          </Form.Item>
          <Form.Item label="Breakfast included" name="breakfast_included" rules={[{ required: true, message: 'required!' }]}>
            <Select>
              <Option value={1}>Yes</Option>
              <Option value={0}>No</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Terrace" name="terrace" rules={[{ required: true, message: 'required!' }]}>
            <Select>
              <Option value={1}>Yes</Option>
              <Option value={0}>No</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Air condition" name="air_condition" rules={[{ required: true, message: 'required!' }]}>
            <Select>
              <Option value={1}>Yes</Option>
              <Option value={0}>No</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Bedroom" name="bed" rules={[{ required: true, message: 'required!' }]}>
            <InputNumber/>
          </Form.Item>
          <Form.Item label="Room" name="room" rules={[{ required: true, message: 'required!' }]}>
            <InputNumber/>
          </Form.Item>
          <Form.Item label="Wifi" name="wifi" rules={[{ required: true, message: 'required!' }]}>
            <Select>
              <Option value={1}>Yes</Option>
              <Option value={0}>No</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Tv" name="tv" rules={[{ required: true, message: 'required!' }]}>
            <Select>
              <Option value={1}>Yes</Option>
              <Option value={0}>No</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Animal" name="animal" rules={[{ required: true, message: 'required!' }]}>
            <Select>
              <Option value={1}>Yes</Option>
              <Option value={0}>No</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Number" name="number" rules={[{ required: true, message: 'required!' }]}>
            <InputNumber/>
          </Form.Item>
          <Form.Item label="Price" name="price" rules={[{ required: true, message: 'required!' }]}>
            <InputNumber/>
          </Form.Item>
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal> 
      <Modal
          title="Edit Room"
          visible={this.state.visible_edit_modal}
          onCancel={()=>{this.setState({visible_edit_modal : false })}}
          footer={null}
          width={1000}
        > 
        <Form {...layout} ref={this.formRefEdit} name="formEdit" onFinish={this.onFinishEdit} >
        <Form.Item label="Hotel" name="hotel_id" rules={[{ required: true, message: 'required!' }]}>
            <Select>
              {this.state.list_hotel.map((item, index) =>
                <Option key={index} value={item.id}>{item.name}</Option>
              )}
            </Select>
          </Form.Item>
          <Form.Item label="Kitchen" name="kitchen" rules={[{ required: true, message: 'required!' }]}>
            <Select>
              <Option value={1}>Yes</Option>
              <Option value={0}>No</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Bathroom" name="bathroom" rules={[{ required: true, message: 'required!' }]}>
            <InputNumber/>
          </Form.Item>
          <Form.Item label="Breakfast included" name="breakfast_included" rules={[{ required: true, message: 'required!' }]}>
            <Select>
              <Option value={1}>Yes</Option>
              <Option value={0}>No</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Terrace" name="terrace" rules={[{ required: true, message: 'required!' }]}>
            <Select>
              <Option value={1}>Yes</Option>
              <Option value={0}>No</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Air condition" name="air_condition" rules={[{ required: true, message: 'required!' }]}>
            <Select>
              <Option value={1}>Yes</Option>
              <Option value={0}>No</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Bedroom" name="bed" rules={[{ required: true, message: 'required!' }]}>
            <InputNumber/>
          </Form.Item>
          <Form.Item label="Room" name="room" rules={[{ required: true, message: 'required!' }]}>
            <InputNumber/>
          </Form.Item>
          <Form.Item label="Wifi" name="wifi" rules={[{ required: true, message: 'required!' }]}>
            <Select>
              <Option value={1}>Yes</Option>
              <Option value={0}>No</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Tv" name="tv" rules={[{ required: true, message: 'required!' }]}>
            <Select>
              <Option value={1}>Yes</Option>
              <Option value={0}>No</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Animal" name="animal" rules={[{ required: true, message: 'required!' }]}>
            <Select>
              <Option value={1}>Yes</Option>
              <Option value={0}>No</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Price" name="price" rules={[{ required: true, message: 'required!' }]}>
            <InputNumber/>
          </Form.Item>
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal> 
      <Modal
          title="Delete"
          visible={this.state.visible_delete_modal}
          onCancel={this.handleCancel}
          onOk={this.deleteitem}
        > 
          <p>Do you want to delete the " {this.state.delete_id} " ?</p>
      </Modal>
      <Alert
        message="How To Use ?"
        description="Add and Manage room for hotel"
        type="info"
        style={{marginBottom:"10px"}}
      />
      <Button onClick={()=>this.displayAddSuite()} type="primary" style={{marginBottom:"10px"}} >Add Suite</Button>
      <Table columns={columns} dataSource={this.state.list_data} />
      </>
     
    );
  }
}


export default AdminListSuite;
