import React from 'react';
import { Form, Input, Button ,Tabs , Modal } from 'antd';
import { UserOutlined , KeyOutlined , PhoneOutlined , UserAddOutlined} from '@ant-design/icons';
import Loader from '../../widget/Loader';
import PANEL_BASE_URL from '../../../PANEL_BASE_URL';
import BASE_URL from '../../../BASE_URL';
import axios from 'axios';
import {connect} from "react-redux";
import {VisibleAuth_State_Action} from '../../../action/ListPeropertyAction';




class ModalUserAuth extends React.Component {

  state = {
    processing : false ,
  }

  onFinishLogin = values => {
    this.setState({processing: true});
    const postdata = {
      "email":values.email ,
      "password":values.password,
      "role":"user",
    }
    axios.post(PANEL_BASE_URL + `/login` , postdata)
    .then((res) => {
      if(res.data.result === 'ok'){ 
        if (res.data.role === 'user'){
          localStorage.setItem("usertoken", res.data.user.Token);
          localStorage.setItem("username", res.data.user.Email);
          localStorage.setItem("role", "user");
          this.props.VisibleAuth_State_Action(false)
          this.setState({processing: false});
        }
        else {
          this.setState({processing: false});
          alert("User Not Found")
        }
      }
      else {
        this.setState({processing: false});
        alert("You entered the username or password incorrectly. Please be careful in choosing the role.")
      }
    })
  };



  onFinishRegister = values => {
    this.setState({processing: true});
    const postdata = {
      "email":values.email ,
      "password":values.password,
      "name":values.name,
      "phoneNumber":values.phone,
    }
    axios.post(BASE_URL + `/create_user`,  postdata )
    .then((res) => {
      if(res.data.result === 'ok'){
        localStorage.clear();
        localStorage.setItem("usertoken", res.data.data.Token);
        localStorage.setItem("username", values.email);
        localStorage.setItem("role", "user");
        this.setState({processing : false,});  
        this.props.VisibleAuth_State_Action(false)
      }
      else {
        this.setState({
          modal_cont : "Emial Exist.Choose Another Email",
          processing : false,
          visible:true,
        });
      }
    })
  };



 
  render() {
    const { TabPane } = Tabs;

    return (
      <>
      {this.state.processing ? <Loader/> : null}
      <Modal
          title="Auth"
          visible={this.props.DisplayVisibleModalAuth}
          onCancel={()=>{this.props.VisibleAuth_State_Action(false)}}
          footer={null}
        >    
        <Tabs defaultActiveKey="1" className="tab-modal-auth">
          <TabPane tab="Login" key="1">
            <Form
              name="basic"
              onFinish={this.onFinishLogin}
            >
              <Form.Item name="email"  rules={[{ required: true, message: 'Please input your Email!' , type: 'email' }]}>
                <Input placeholder="Email" prefix={<UserOutlined />}/>
              </Form.Item>
              <Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]}
              >
                <Input.Password placeholder="Password" prefix={<KeyOutlined />}/>
              </Form.Item>
              <Form.Item >
                <Button style={{width:"100%"}} className="login-btn-panels" type="primary" htmlType="submit">
                  Login
                </Button>
              </Form.Item>
            </Form>      
          </TabPane>
          <TabPane tab="Register" key="2">
            <Form name="register" onFinish={this.onFinishRegister}
            >
              <Form.Item name="name"  rules={[{ required: true, message: 'Please input your Name!' }]}>
                <Input placeholder="Full Name" prefix={<UserAddOutlined  />}/>
              </Form.Item>
              <Form.Item name="email" rules={[{ required: true, message: 'Please input your Email!' , type: 'email' }]}
              >
                <Input placeholder="Email" prefix={<UserOutlined />}/>
              </Form.Item>
              <Form.Item name="phone"  rules={[{ required: true, message: 'Please input your Phone!' }]}>
                <Input placeholder="Phone Number" prefix={<PhoneOutlined  />}/>
              </Form.Item>
              <Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
                <Input.Password placeholder="Password" prefix={<KeyOutlined />}/>
              </Form.Item>
              <Form.Item >
                <Button style={{width:"100%"}} type="primary" htmlType="submit">
                  Register
                </Button>
              </Form.Item>
            </Form>
          </TabPane>
        </Tabs>
      </Modal>
      </>
    );
  }
}




const mapStateToProps = state => {

  return({
    DisplayVisibleModalAuth: state.visibleAuth,

  })
}
const mapDispatchToProps = {VisibleAuth_State_Action};

export default connect(mapStateToProps , mapDispatchToProps)(ModalUserAuth);
