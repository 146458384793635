import React from 'react';
import {
  Form,
  Select,
  Button,
  Collapse,
  Modal ,
} from 'antd';
import {SearchOutlined  } from '@ant-design/icons';
import {connect} from "react-redux";
import BASE_URL from '../../BASE_URL';
import axios from 'axios';
import {Peroperty_State_Action} from '../../action/ListPeropertyAction';
import { Redirect } from 'react-router-dom';
import Loader from './Loader';



class RentSearch extends React.Component {

  state = {
    redirect : false ,
    visible : false ,
    display : false ,
  }

  handleOk = ()=> {
    this.setState({visible:false});
  }



  onFinish = (values)=> {
    this.setState({display:true})
    for(var i in values){
      if(values[i] === undefined){
        values[i] = " ";
      }
    }
    const postdata = {
      "maxArea":values.maxarea,
      "minArea":values.minarea,
      "minBed":values.minbed,
      "maxBed":values.maxbed,
      "minPrice":values.minprice,
      "maxPrice":values.maxprice,
      "type":values.type,
      "city":values.city,
      "due_date":values.due_date ,
    };
    console.log(postdata)
    axios.post(BASE_URL + `/rentFilter` , postdata)
      .then((res) => {
        if(res.data.result === "ok"){
          this.props.Peroperty_State_Action(res.data.data);
          this.setState({
            redirect:true,
            display:false ,
          })
        } 
        else if(res.data.result === "error"){
          this.setState({
            visible : true,
            display:false ,
          })
        }
      })
  }


  render() {
    const { Panel } = Collapse;
    return (
    <>  
      { this.state.redirect ? (<Redirect  to='list'  />) : null }
      { this.state.display == true ? <Loader/> : null}
      <Form
        layout="filter"
        id="filter-form"
        onFinish={this.onFinish}
      >
        <Form.Item name="city" >
          <Select placeholder="City">
              <Select.Option value="Dubai">Dubai</Select.Option>
              <Select.Option value="Abu Dhabi">Abu Dhabi</Select.Option>
              <Select.Option value="Sharjah">Sharjah</Select.Option>
              <Select.Option value="Al Ain">Al Ain</Select.Option>
              <Select.Option value="Ajman">Ajman</Select.Option>
              <Select.Option value="RAK City">RAK City</Select.Option>
              <Select.Option value="Fujairah">Fujairah</Select.Option>
              <Select.Option value="Umm al-Quwain">Umm al-Quwain</Select.Option>
              <Select.Option value="Khor Fakkan">Khor Fakkan</Select.Option>
              <Select.Option value="Kalba">Kalba</Select.Option>
              <Select.Option value="Jebel Ali">Jebel Ali</Select.Option>
              <Select.Option value="Dibba Al-Fujairah">Dibba Al-Fujairah</Select.Option>
              <Select.Option value="Madinat Zayed">Madinat Zayed</Select.Option>
              <Select.Option value="Ruwais">Ruwais</Select.Option>
              <Select.Option value="Liwa Oasis">Liwa Oasis</Select.Option>
              <Select.Option value="Dhaid">Dhaid</Select.Option>
              <Select.Option value="Ghayathi">Ghayathi</Select.Option>
              <Select.Option value="Ar-Rams">Ar-Rams</Select.Option>
              <Select.Option value="Dibba Al-Hisn"> 	Dibba Al-Hisn</Select.Option>
              <Select.Option value="Hatta">Hatta</Select.Option>
              <Select.Option value="Al Madam">Al Madam</Select.Option>
          </Select>
        </Form.Item> 
        <Form.Item name="type" >
            <Select placeholder="Type">
              <Select.Option value="1">Aparteman</Select.Option>
              <Select.Option value="2">villa</Select.Option>
            </Select>
        </Form.Item> 
        <Form.Item name="due_date" >
            <Select placeholder="Due Date">
              <Select.Option value="date1">date 1</Select.Option>
              <Select.Option value="date2">date 2</Select.Option>
            </Select>
        </Form.Item>  
        <Form.Item name="minarea" >
            <Select placeholder="Min Area">
              <Select.Option value="100">100 m</Select.Option>
              <Select.Option value="200">200 m</Select.Option>
              <Select.Option value="300">300 m</Select.Option>
              <Select.Option value="500">500 m</Select.Option>
              <Select.Option value="800">800 m</Select.Option>
              <Select.Option value="1000">1000 m</Select.Option>
              <Select.Option value="2000">2000 m</Select.Option>
            </Select>
        </Form.Item>
        <Form.Item name="maxarea"  >
            <Select placeholder="max Area">
              <Select.Option value="100">100 m</Select.Option>
              <Select.Option value="200">200 m</Select.Option>
              <Select.Option value="300">300 m</Select.Option>
              <Select.Option value="500">500 m</Select.Option>
              <Select.Option value="800">800 m</Select.Option>
              <Select.Option value="1000">1000 m</Select.Option>
              <Select.Option value="2000">2000 m</Select.Option>
            </Select>
        </Form.Item>
        <Form.Item name="maxprice" >
            <Select placeholder="Min Price">
              <Select.Option value="20000">20,000 AED/year</Select.Option>
              <Select.Option value="30000">30,000 AED/year</Select.Option>
              <Select.Option value="40000">40,000 AED/year</Select.Option>
              <Select.Option value="50000">50,000 AED/year</Select.Option>
              <Select.Option value="100000">100,000 AED/year</Select.Option>
            </Select>
        </Form.Item>
        <Form.Item name="minprice" >
            <Select placeholder="Max Price">
              <Select.Option value="20000">20,000 AED/year</Select.Option>
              <Select.Option value="30000">30,000 AED/year</Select.Option>
              <Select.Option value="40000">40,000 AED/year</Select.Option>
              <Select.Option value="50000">50,000 AED/year</Select.Option>
              <Select.Option value="100000">100,000 AED/year</Select.Option>
            </Select>
        </Form.Item>
        <Form.Item name="minbed" >
            <Select placeholder="Min Bedroom">
              <Select.Option value="2">2 Bedroom</Select.Option>
              <Select.Option value="3">3 Bedroom</Select.Option>
              <Select.Option value="4">4 Bedroom</Select.Option>
              <Select.Option value="5">5 Bedroom</Select.Option>
              <Select.Option value="6">6 Bedroom</Select.Option>
            </Select>
        </Form.Item>
        <Form.Item name="maxbed" >
            <Select placeholder="Max Bedroom">
              <Select.Option value="2">2 Bedroom</Select.Option>
              <Select.Option value="3">3 Bedroom</Select.Option>
              <Select.Option value="4">4 Bedroom</Select.Option>
              <Select.Option value="5">5 Bedroom</Select.Option>
              <Select.Option value="6">6 Bedroom</Select.Option>
            </Select>
        </Form.Item>
        <Form.Item className="form-filter-submit">
          <Button htmlType="submit"><SearchOutlined/>Search</Button>
        </Form.Item>
      {/* <button onClick={()=>{console.log(this.props.Displaylistdata)}}> fwef</button> */}
      </Form>
      <Modal  visible={this.state.visible} onCancel={this.handleOk} footer={null}>
        <p>Data Not Found</p>
      </Modal>
    </>  
    );
  }
}

const mapStateToProps = state => {

  return({
    Displaylistdata: state.listdata,
  })
}
const mapDispatchToProps = {Peroperty_State_Action};

export default connect(mapStateToProps,  mapDispatchToProps )(RentSearch);

