import React, { useEffect, useState } from 'react';
import { Map, Marker, TileLayer } from "react-leaflet";


function DetailMap(props) {
  const[markers , setmarkers ]=useState([45.4, -75.7])


  useEffect(()=>{
    if(props.detail.lat != undefined){
      setmarkers([props.detail.lat, props.detail.lng])
    }
  },[props.detail])

    return (
     <div className="detail-map">
       <div className="detail-map-head"><h3>Map</h3></div>
        <Map center={markers} zoom={8} >
          <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
            <Marker  position={markers}>
            </Marker>
        </Map>
     </div>
    );
}




export default DetailMap;

