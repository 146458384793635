import React from 'react';
import { Form, Input, Button ,Select } from 'antd';
import PANEL_BASE_URL from '../../../PANEL_BASE_URL';
import axios from 'axios';
import { UserOutlined , KeyOutlined  } from '@ant-design/icons';
// import logologin from '../../img/logo-login.png'
import { Link} from 'react-router-dom';
import Loader2 from '../../widget/Loader2';
import Logo from '../../img/logo2.png';


class Login extends React.Component {

  state = {
    processing : false ,
  }

  onFinish = values => {
    this.setState({processing: true});
    const postdata = {
      "email":values.email ,
      "password":values.password,
      "role":values.role,
    }
    axios.post(PANEL_BASE_URL + `/login` , postdata)
    .then((res) => {
      localStorage.clear();
      if(res.data.result === 'ok'){ 
        if(res.data.role === 'agent'){
          localStorage.setItem("agenttoken", res.data.agent.token);
          localStorage.setItem("role", "agent");
          this.setState({processing: false});
          return window.location.href = '/panel/agent/agentlistproperty'
        }
        else if (res.data.role === 'company'){
          localStorage.setItem("companytoken", res.data.company.token);
          localStorage.setItem("role", "company");
          this.setState({processing: false});
          return window.location.href = '/panel/company/listagent'
        }
        else if (res.data.role === 'user'){
          localStorage.setItem("usertoken", res.data.user.Token);
          localStorage.setItem("role", "user");
          localStorage.setItem("username", res.data.user.Email);
          this.setState({processing: false});
          return window.location.href = '/panel/user/listcar'
        }
      }
      else {
        this.setState({processing: false});
        alert("You entered the username or password incorrectly. Please be careful in choosing the role.")
      }
    })
  };
 
  render() {

    return (
      <div className="login-amaz">
        {this.state.processing ? <Loader2/> : null}
        <div className="login-amaz-main">
          <div className="login-amaz-head">
            <div className="login-amaz-head-button login-amaz-head-button-active">Login</div>
            <Link to="/register"><div className="login-amaz-head-button">Register</div></Link>
            <Link to="/companyregister"><div className="login-amaz-head-button">Company</div></Link>
          </div>
          <div className="login-amaz-cont">
            <Link to="/"><img src={Logo} alt="logo"></img></Link>
            <h5>Hello Everyone , Welcome to RealEstate</h5>
            <h4>Login</h4>
            <Form
              name="loginAmaz"
              onFinish={this.onFinish}
            >
              <p style={{fontWeight:"bold"}}>Email Address</p>
              <Form.Item 
                name="email"  
                rules={[{ required: true, message: 'Please input your Email!' , type: 'email' }]}
              >
                <Input placeholder="Email" prefix={<UserOutlined />}/>
              </Form.Item>
              <p style={{fontWeight:"bold"}}>Password</p>
              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
              >
                <Input.Password placeholder="Password" prefix={<KeyOutlined />}/>
              </Form.Item>
              <p style={{fontWeight:"bold"}}>Select Role</p>
              <Form.Item name="role" rules={[{ required: true, message: 'Please select your type!' }]} >
                <Select placeholder="Role" >
                  <Select.Option value="user">User</Select.Option>
                  <Select.Option value="agent">Agent</Select.Option>
                  <Select.Option value="company">Company</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item >
                <Button className="login-btn-panels" type="primary" htmlType="submit">
                  Login
                </Button>
              </Form.Item>
            </Form>  
          </div>
        </div>
      </div>
    );
  }
}


export default Login;
