import React from 'react';
import { Form, DatePicker, Button , Alert , Select} from 'antd';
import { Descriptions  } from 'antd';
import { Table, Tag, Space } from 'antd';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  EditOutlined,
  DeleteOutlined  
} from '@ant-design/icons';
import { Modal  } from 'antd';
import Loader2 from '../../widget/Loader2';
import ADMIN_BASE_URL from '../../../ADMIN_BASE_URL';
import BASE_URL_Majid from '../../../BASE_URL_Majid';
import moment from 'moment';


class AdminListReserve extends React.Component {

  state = {
    visible: false,
    visible_delete_modal:false ,
    visible_add_modal : false ,
    list_data: [],
    list_suite : [] ,
    processing: false ,
    delete_id :0 ,
  }

  
  // نمایش مدال برای حذف پروپرتی
  deleteshowmodal = (id ) => {
    this.setState({
      visible_delete_modal: true,
      delete_id: id,
    });
  };


  onFinishAdd=(values)=>{
    this.setState({processing : true}) ;
    const postdata = {
      "suite_id":values.suite_id,
      "start_time":moment(values.date[0]['_d']).format('YYYY-MM-DD'),
      "end_time":moment(values.date[1]['_d']).format('YYYY-MM-DD')
    }
    axios.post(BASE_URL_Majid + `/reservation` , postdata )
    .then((res) => {
      if(res.data.result === 'ok'){
        this.setState({
          processing : false,
          visible_add_modal : false,
        });  
        this.componentDidMount()
      } 
      else if (res.data.result === "error"){
        this.setState({processing : false})
        alert(res.data.error)
      }
    })
    .catch((er)=>{
      this.setState({processing : false}) ;
      alert("Network Error")
      console.log('er');
    });
  }


  displayAddModal=()=>{
    this.setState({ processing : true});
    axios.post(BASE_URL_Majid + `/suites/show_all` )
    .then((res) => {
      if(res.data.result === 'ok'){
        this.setState({
          list_suite : res.data.data ,
          processing : false,
          visible_add_modal : true ,
        });  
      }
      else if (res.data.error === "unauthenticated"){
        localStorage.clear(); 
        return window.location.href = '/login'
      }
    })
    .catch((err)=>{
      this.setState({process : false });
      alert("Network Error")
      console.log("err");
    })
  }


  deleteitem = () => {
    this.setState({processing:true});
    axios.delete(BASE_URL_Majid + `/reservation/${this.state.delete_id}` )
    .then((res) => {
      if(res.data.result === 'ok'){
        this.setState({
          processing : false,
          visible_delete_modal:false,
        });  
        this.componentDidMount();
      }
      else if (res.data.error === "unauthenticated"){
        this.setState({processing:false,})
        localStorage.clear(); 
        return window.location.href = '/login'
      }
    })
    .catch((err)=>{
      this.setState({processing : false})
      alert("Network Error")
      console.log("err");
    })
  }




  componentDidMount(){
    this.setState({ processing : true});
    const postdata = {
      "token": localStorage.getItem("admintoken"),
    };
    axios.post(BASE_URL_Majid + `/reservation/show_all` )
    .then((res) => {
      if(res.data.result === 'ok'){
        this.setState({
          list_data : res.data.data ,
          processing : false,
        });  
      }
      else if (res.data.error === "unauthenticated"){
        localStorage.clear(); 
        return window.location.href = '/login'
      }
    })
    .catch((err)=>{
      this.setState({processing : false})
      alert("Network Error")
      console.log('err');
    })
    }
 
  render() {

    const columns = [
      {
        title: 'Reserve ID',
        dataIndex: 'id',
      },
      {
        title: 'Suite ID',
        dataIndex: 'suite_id',
      },
      {
        title: 'Start Time',
        render : row => (<>{moment(row.start_time).format('YYYY-MM-DD')}</>)
      },
      {
        title: 'End Time',
        render : row => (<>{moment(row.end_time).format('YYYY-MM-DD')}</>)
      },
      {
        title: 'User Email',
        render: (text , row) => <span  >{row.user_email}</span>,
      },
      {
        title: 'delete',
        render:  row => <DeleteOutlined onClick={() => this.deleteshowmodal(row.id)} />,
      },


    ]

    const layout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
    };
    const {Option} = Select ;
    const { RangePicker } = DatePicker;

    return (
      <>
      {this.state.processing ? <Loader2/> : null}
      {/* this modal for show detail */}
      
      <Modal
          title="Delete"
          visible={this.state.visible_delete_modal}
          onCancel={()=>{this.setState({visible_delete_modal : false})}}
          onOk={this.deleteitem}
        > 
          <p>Do you want to delete the " {this.state.delete_id} " ?</p>
      </Modal>
      <Modal
          title="Add Reserve"
          visible={this.state.visible_add_modal}
          onCancel={()=>{this.setState({visible_add_modal : false})}}
          footer = {null}

        > 
        <Form {...layout}  onFinish={this.onFinishAdd} >
          <Form.Item label="Suite ID" name="suite_id" rules={[{ required: true }]}>
            <Select>
              {this.state.list_suite.map((item, index) =>
                <Option key={index} value={item.id}>{item.id}</Option>
              )}
            </Select>
          </Form.Item>
          <Form.Item label="Date" name="date" rules={[{ required: true}]} >
            <RangePicker style={{width:"100%"}}/>
          </Form.Item>
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Alert
        message="How To Use ?"
        description=" Manage Reserve for Suite"
        type="info"
        style={{marginBottom:"10px"}}
      />
      <Button onClick={this.displayAddModal} type="primary" style={{marginBottom:"10px"}} >Add Reserve</Button>
      <Table columns={columns} dataSource={this.state.list_data} />
      </>
     
    );
  }
}


export default AdminListReserve;
