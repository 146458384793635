import React from 'react';
import { Form, Input, Button } from 'antd';
import { Descriptions  } from 'antd';
import { Table, Tag, Space } from 'antd';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  EditOutlined,
  DeleteOutlined  ,
  EyeOutlined
} from '@ant-design/icons';
import { Modal  } from 'antd';
import Loader2 from '../../widget/Loader2';
import PANEL_BASE_URL from '../../../PANEL_BASE_URL';
import BASE_URL_Majid from '../../../BASE_URL_Majid';
import moment from 'moment';



class UserListReserve extends React.Component {

  state = {
    visible: false,
    visible_detail_modal:false,
    list_data: [],
    processing: false ,
    detail_data:"",
  }


  showdetailmodal=(id)=>{
    for(let i of this.state.list_data){
      if(i.id == id){
        this.setState({
          detail_data : i ,
          visible_detail_modal : true ,
        })
      }
    }
  }


  componentDidMount(){
    this.setState({ processing : true});
    const postdata = {
      "user_email": localStorage.getItem('username')
    };
    axios.post(BASE_URL_Majid + `/reservation/show_all`,  postdata )
    .then((res) => {
      if(res.data.result === 'ok'){
        this.setState({
          list_data : res.data.data ,
          processing : false,
        });  
      }
      else if (res.data.error === "there is no car found for this user"){
        this.setState({processing : false })
      }
      else if (res.data.error === "unauthenticated"){
        localStorage.clear(); 
        return window.location.href = '/login'
      }
    })
    .catch((err)=>{
      this.setState({processing : false});
      alert("Network Error")
    })
    
    }
 
  render() {
  
    const columns = [
      {
        title: 'Hotel',
        render: (text , row) => <span >{row.suite.hotel.name}</span>,
      },
      {
        title: 'Start',
        render: (text , row) => <span >{row.start_time}</span>,
      },
      {
        title: 'End',
        render: (text , row) => <span >{row.end_time}</span>,
      },
      {
        title: 'More',
        render: (text , row) => <span className="title-list-name"><EyeOutlined onClick={()=>this.showdetailmodal(row.id)} /></span>,
      },
    ]

    return (
      <>
      {this.state.processing ? <Loader2/> : null}
      <Modal
          title="Delete"
          visible={this.state.visible}
          onOk={() => this.handleOk(this.state.delete_id)}
          onCancel={this.handleCancel}

        >    
        <p>Do you want to delete the " {this.state.delete_id} " ?</p>
      </Modal>
      {/* this modal for show detail */}
      <Modal
          title="Detail"
          visible={this.state.visible_detail_modal}
          onCancel={()=>{this.setState({visible_detail_modal : false})}}
          footer={null}
          className="detail-property-modal"
          width={1000}
        >    
        <div>
        <Descriptions
            bordered
            // column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
          >
            {this.state.detail_data.suite != undefined && 
            <>
            <Descriptions.Item label="Start" span={2}>{this.state.detail_data.start_time}</Descriptions.Item>
            <Descriptions.Item label="End" span={2}>{this.state.detail_data.end_time}</Descriptions.Item>
            <Descriptions.Item label="Price" span={2}>{this.state.detail_data.price}</Descriptions.Item>
            <Descriptions.Item label="Kitchen" span={1}>{this.state.detail_data.suite.kitchen == 1 ? <>YES</> : <>NO</>}</Descriptions.Item>
            <Descriptions.Item label="Bathroom" span={1}>{this.state.detail_data.suite.bathroom}</Descriptions.Item>
            <Descriptions.Item label="Bed" span={1}>{this.state.detail_data.suite.bed}</Descriptions.Item>
            <Descriptions.Item label="Room" span={1}>{this.state.detail_data.suite.room}</Descriptions.Item>
            <Descriptions.Item label="Breakfast included" span={2}>{this.state.detail_data.suite.breakfast_included == 1 ? <>YES</> : <>NO</>}</Descriptions.Item>
            <Descriptions.Item label="Terrace" span={2}>{this.state.detail_data.suite.terrace == 1 ? <>YES</> : <>NO</>}</Descriptions.Item>
            <Descriptions.Item label="Air condition" span={2}>{this.state.detail_data.suite.air_condition == 1 ? <>YES</> : <>NO</>}</Descriptions.Item>
            <Descriptions.Item label="Wifi" span={2}>{this.state.detail_data.suite.wifi == 1 ? <>YES</> : <>NO</>}</Descriptions.Item>
            <Descriptions.Item label="TV" span={2}>{this.state.detail_data.suite.tv == 1 ? <>YES</> : <>NO</>}</Descriptions.Item>
            <Descriptions.Item label="Aanimal" span={2}>{this.state.detail_data.suite.animal == 1 ? <>YES</> : <>NO</>}</Descriptions.Item>
            </>
            }
          </Descriptions>
        </div>
      </Modal>
      <Table columns={columns} dataSource={this.state.list_data} />
      </>
     
    );
  }
}


export default UserListReserve;
