import React from 'react';
import { AreaChartOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import Img1 from '../img/blog1.jpg'
import {Peroperty_State_Action} from '../../action/ListPeropertyAction';
import {connect} from "react-redux";
import { Link} from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import LoaderImage from './LoaderImage';
import Flip from 'react-reveal/Flip';

class ListProductPage extends React.Component {

  state = {
    imageLoader : false ,
  }


  render() {
 

    return (

     <div className="listproductpage">
       <div className="listproductpage-init">
         { this.props.Displaylistdata.map(item =>
         <Flip left>
          <div className="product-section-card">
            <div className="product-section-card-init">
              <Link to={`/detail/${item.id}`}>
                <img   onLoadStartCapture={()=>{this.setState({imageLoader : true})}} onLoad={()=>{this.setState({imageLoader : false})}} src={`https://downloadforever.ir/saeed/RealEstate/public/images/${item.image}`} alt="card"></img>
                {/* <LazyLoadImage afterLoad={()=>{this.setState({imageLoader : false})}} beforeLoad={()=>{this.setState({imageLoader:true})}}  src={`https://downloadforever.ir/saeed/RealEstate/public/images/${item.image}`} alt="card"/> */}
                {this.state.imageLoader ? <LoaderImage/> : null }
              </Link>
              <div className="product-section-card-init-des">
                <div className="product-section-card-init-des-one">
                  <Link to={`/detail/${item.id}`}><h3>{item.name}</h3></Link>
                </div>
                <div className="product-section-card-init-des-two">
                  <div className="product-section-card-init-des-two-item" style={{borderRight:"1px solid #e0e0e0"}}>
                    <AreaChartOutlined /> 
                    <span>{item.bathroom}</span>
                    <span> Bath</span>
                  </div>
                  <div className="product-section-card-init-des-two-item" style={{borderRight:"1px solid #e0e0e0"}}>
                    <AreaChartOutlined /> 
                    <span>{item.bedroom}</span>
                    <span> Bed</span>
                  </div>
                  <div className="product-section-card-init-des-two-item">
                    <AreaChartOutlined />
                    <span>{item.size}</span>
                    <span> Size</span>
                  </div>
                </div> 
              </div>
            </div>
          </div>
          </Flip>
         )}
       </div>
     </div>
    );
  }
}

const mapStateToProps = state => {

  return({
    Displaylistdata: state.listdata,
  })
}
const mapDispatchToProps = {Peroperty_State_Action};

export default connect(mapStateToProps,  mapDispatchToProps )(ListProductPage);

