import React from 'react';
import { Form, Input, Button , Select , InputNumber } from 'antd';
import { Descriptions  } from 'antd';
import { Table, Tag, Space } from 'antd';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  EditOutlined,
  DeleteOutlined  
} from '@ant-design/icons';
import { Modal  } from 'antd';
import Loader2 from '../../widget/Loader2';
import ADMIN_BASE_URL from '../../../ADMIN_BASE_URL';
import BASE_URL from '../../../BASE_URL';
import BASE_URL_Majid from '../../../BASE_URL_Majid';



class AdminListHotel extends React.Component {

  state = {
    visible: false,
    visible_detail_modal:false,
    visible_delete_modal:false ,
    list_data: [],
    processing: false ,
    delete_id :0 ,
    delete_name :" " ,
    detail_data:"",
    detail_data_image:[],
  }

  
  // نمایش مدال برای حذف پروپرتی
  deleteshowmodal = (id , name) => {
    this.setState({
      visible_delete_modal: true,
      delete_id: id,
      delete_name: name,
    });
  };

  deleteitem = () => {
    this.setState({processing:true});
    const postdata = {
      "token": localStorage.getItem("admintoken"),
      "id":this.state.delete_id ,
    }
    axios.delete(BASE_URL_Majid + `/hotels/${this.state.delete_id}` )
    .then((res) => {
      if(res.data.result === 'ok'){
        this.setState({
          processing : false,
          visible_delete_modal:false,
        });  
        this.componentDidMount();
      }
      else if (res.data.error === "unauthenticated"){
        this.setState({processing:false,})
        localStorage.clear(); 
        return window.location.href = '/login'
      }
    })
  }



  // this function for show destail Modal;
  showdetailmodal = (id) => {
    this.setState({
      visible_detail_modal:true,
      processing:true,
    })
    axios.get(BASE_URL_Majid + `/hotels/${id}` )
    .then((res) => {
      if(res.data.result === 'ok'){
        this.setState({
          detail_data : res.data.data ,
          detail_data_image : res.data.data.image ,
          processing : false,
        });  
      }
      else if (res.data.error === "unauthenticated"){
        this.setState({processing:false,})
        localStorage.clear(); 
        return window.location.href = '/login'
      }
    })
  }





  handleCancel = () => {
    this.setState({
      visible: false,
      visible_detail_modal:false,
    visible_delete_modal:false ,

    });
  };

  componentDidMount(){
    this.setState({ processing : true});
    const postdata = {
      "range":"0"
    };
    axios.get(BASE_URL_Majid + `/hotels/show_all` )
    .then((res) => {
      if(res.data.result === 'ok'){
        this.setState({
          list_data : res.data.data ,
          processing : false,
        });  
      }
      else if (res.data.error === "unauthenticated"){
        localStorage.clear(); 
        return window.location.href = '/login'
      }
      
    })
    
    }
 
  render() {
  
    const columns = [
      {
        title: 'id',
        dataIndex: 'id',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        render: (text , row) => <span className="title-list-name" onClick={() => this.showdetailmodal(row.id)}>{text}</span>,
      },
      {
        title: 'edit',
        render:  row => <Link to={`/panel/admin/edithotel/${row.id}`}><EditOutlined /></Link>,
      },
      {
        title: 'delete',
        render:  row => <DeleteOutlined onClick={() => this.deleteshowmodal(row.id , row.name)} />,
      },
    ]

    const layout = {
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
    };

    return (
      <>
      {this.state.processing ? <Loader2/> : null}
      {/* this modal for show detail */}
      <Modal
          title="Detail"
          visible={this.state.visible_detail_modal}
          onCancel={this.handleCancel}
          footer={null}
          className="detail-property-modal"
          width={1000}
        >    
        <div>
          <Descriptions
            bordered
          >
            <Descriptions.Item label="Name" span={3}>{this.state.detail_data.name}</Descriptions.Item>
            <Descriptions.Item label="Level" span={1}>{this.state.detail_data.level}</Descriptions.Item>
            <Descriptions.Item label="Parking" span={1}>{this.state.detail_data.parking == 1 ? <>Yes</> : <>No</>}</Descriptions.Item>
            <Descriptions.Item label="Cafe" span={1}>{this.state.detail_data.cafe == 1 ? <>Yes</> : <>No</>}</Descriptions.Item>
            <Descriptions.Item label="Pool" span={1}>{this.state.detail_data.pool == 1 ? <>Yes</> : <>No</>}</Descriptions.Item>
            <Descriptions.Item label="Restaurant" span={1}>{this.state.detail_data.restaurant == 1 ? <>Yes</> : <>No</>}</Descriptions.Item>
            <Descriptions.Item label="City" span={1}>{this.state.detail_data.city}</Descriptions.Item>
            <Descriptions.Item label="Address" span={3}>{this.state.detail_data.address}</Descriptions.Item>
            <Descriptions.Item label="Description" span={3}>{this.state.detail_data.description}</Descriptions.Item>
            <Descriptions.Item label="Image" span={3}>
              {this.state.detail_data_image.map((item)=>
              <img style={{width:"200px" , height:"200px" , margin:"5px"}} src={`https://downloadforever.ir/saeed/RealEstate/public/images/${item.image}`}></img>
              )}
            </Descriptions.Item>
          </Descriptions>
        </div>
      </Modal>
      <Modal
          title="Delete"
          visible={this.state.visible_delete_modal}
          onCancel={this.handleCancel}
          onOk={this.deleteitem}
        > 
          <p>Do you want to delete the " {this.state.delete_name} " ?</p>
      </Modal>
      <Link to='/panel/admin/addhotel'><Button type="primary" style={{marginBottom:"10px"}} >Add Hotel</Button></Link>
      <Table columns={columns} dataSource={this.state.list_data} />
      </>
     
    );
  }
}


export default AdminListHotel;
