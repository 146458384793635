import React from 'react';
import { SettingOutlined} from '@ant-design/icons';


class ChangeColor extends React.Component {


  showChangeColorDiv = ()=> {
    if (document.getElementById('change-color-main-cont').style.marginLeft == ""){
      document.getElementById('change-color-main-cont').style.marginLeft = '0px';
      document.getElementsByClassName('change-color-main-cont-item')[0].style.marginLeft = '0px';
      document.getElementsByClassName('change-color-main-cont-item')[1].style.marginLeft = '0px';
      document.getElementsByClassName('change-color-main-cont-item')[2].style.marginLeft = '0px';
      document.getElementsByClassName('change-color-main-cont-item')[3].style.marginLeft = '0px';
    }
    else {
      if (document.getElementById('change-color-main-cont').style.marginLeft == '0px'){
        document.getElementById('change-color-main-cont').style.marginLeft = "-100px";
        document.getElementsByClassName('change-color-main-cont-item')[0].style.marginLeft = '-50px';
        document.getElementsByClassName('change-color-main-cont-item')[1].style.marginLeft = '-50px';
        document.getElementsByClassName('change-color-main-cont-item')[2].style.marginLeft = '-50px';
        document.getElementsByClassName('change-color-main-cont-item')[3].style.marginLeft = '-50px';
      }
      else {
        document.getElementById('change-color-main-cont').style.marginLeft = '0px';
        document.getElementsByClassName('change-color-main-cont-item')[0].style.marginLeft = '0px';
        document.getElementsByClassName('change-color-main-cont-item')[1].style.marginLeft = '0px';
        document.getElementsByClassName('change-color-main-cont-item')[2].style.marginLeft = '0px';
        document.getElementsByClassName('change-color-main-cont-item')[3].style.marginLeft = '0px';
      }
    }
  }

  changeRootColor = (id)=> {
    if (id == 1){
      document.documentElement.style.setProperty('--main-color',"#95c41f")
    }
    else if (id == 2){
      document.documentElement.style.setProperty('--main-color',"#3f70ca")
    }
    else if (id == 3){
      document.documentElement.style.setProperty('--main-color',"#da50bc")
    }
    else if (id == 4){
      document.documentElement.style.setProperty('--main-color',"#f3db56")
    }
  }


  render() {
    return (
    <div className="change-color-main">
      <div className="change-color-main-setting" onClick={()=>this.showChangeColorDiv()}>
        <SettingOutlined id="change-color-main-setting-icon" />
      </div>
      <div id="change-color-main-cont">
        <div className="change-color-main-cont-item" onClick={()=>this.changeRootColor(2)}></div>
        <div className="change-color-main-cont-item" onClick={()=>this.changeRootColor(1)}></div>
        <div className="change-color-main-cont-item" onClick={()=>this.changeRootColor(3)}></div>
        <div className="change-color-main-cont-item" onClick={()=>this.changeRootColor(4)}></div>
      </div>
    </div>
    );
  }
}




export default ChangeColor;

