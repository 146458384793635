import React from 'react';


class Loader extends React.Component {


  render() {


    return (
      <div class="loader">
        <div class=" ball-pulse-sync">
          <div></div>
          <div></div>
          <div></div>
        </div>      
      </div>
    );
  }
}

export default Loader;
