import React from 'react';
import { CoffeeOutlined , StarOutlined , CloseOutlined , CheckOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import Img1 from '../img/blog1.jpg'
import {PeropertyHotel_State_Action} from '../../action/ListPeropertyAction';
import {connect} from "react-redux";
import { Link} from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import LoaderImage from './LoaderImage';
import Flip from 'react-reveal/Flip';


class ListHotelPage extends React.Component {

  state = {
    imageLoader : false ,
  }


  render() {
 

    return (
     <div className="listproductpage">
       <div className="listproductpage-init">
         { this.props.Displayhotellistdata.map(item =>
          <Flip left>
            <div className="product-section-card">
              <div className="product-section-card-init">
                <Link to={`/detailhotel/${item.id}`}>
                  {item.images.length != 0 && item.images != undefined && 
                    <img  onLoadStartCapture={()=>{this.setState({imageLoader : true})}} onLoad={()=>{this.setState({imageLoader : false})}}  src={`https://downloadforever.ir/saeed/RealEstate/public/images/${item.images[0].image}`} alt="card"></img>
                  }  
                  {/* <LazyLoadImage afterLoad={()=>{this.setState({imageLoader : false})}} beforeLoad={()=>{this.setState({imageLoader:true})}}  src={`https://downloadforever.ir/saeed/RealEstate/public/images/${item.image}`} alt="card"/> */}
                  {this.state.imageLoader ? <LoaderImage/> : null }
                </Link>
                <div className="product-section-card-init-des">
                  <div className="product-section-card-init-des-one">
                    <Link to={`/detailhotel/${item.id}`}><h3>{item.name}</h3></Link>
                  </div>
                  <div className="product-section-card-init-des-two">
                  <div className="product-section-card-init-des-two-item" style={{borderRight:"1px solid #e0e0e0"}}>
                    <StarOutlined />
                    <span> {item.level}</span>
                    <span> Star </span>
                  </div>
                      <div className="product-section-card-init-des-two-item" style={{borderRight:"1px solid #e0e0e0"}}>
                        {item.cafe == 1 ? <CheckOutlined className="product-section-card-init-des-two-item-icon-hotel" /> : <CloseOutlined className="product-section-card-init-des-two-item-icon-hotel" />}
                        <span> cafe</span>
                      </div>
                      <div className="product-section-card-init-des-two-item">
                        {item.pool == 1 ? <CheckOutlined className="product-section-card-init-des-two-item-icon-hotel" /> : <CloseOutlined className="product-section-card-init-des-two-item-icon-hotel" />}
                        <span> Pool </span>
                      </div>
                  </div> 
                </div>
              </div>
            </div>
          </Flip>
         )}
       </div>
     </div>
    );
  }
}

const mapStateToProps = state => {

  return({
    Displayhotellistdata: state.listhoteldata,

  })
}
const mapDispatchToProps = {PeropertyHotel_State_Action};

export default connect(mapStateToProps,  mapDispatchToProps )(ListHotelPage);

