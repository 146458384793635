import React from 'react';
import { PhoneOutlined ,MailOutlined , CaretDownOutlined} from '@ant-design/icons';
import { Menu } from 'antd';
import Logo from '../img/logo2.png';
import {connect} from "react-redux";
import BASE_URL from '../../BASE_URL';
import BASE_URL_Majid from '../../BASE_URL_Majid';
import axios from 'axios';
import {Peroperty_State_Action , PeropertyCar_State_Action , PeropertyHotel_State_Action} from '../../action/ListPeropertyAction';
import { Redirect , Link} from 'react-router-dom';
import Loader from './Loader';

class Head extends React.Component {

  state = {
    redirect : false ,
    redirectcar : false ,
    redirecthotel : false ,
    display : false ,
  }


  showCategory = (category) => {
    this.setState({display:true});
    var cat_url = '';
    if (category == 'rent'){
      cat_url = BASE_URL + "/rents";
    }
    else if (category == 'buy'){
      cat_url = BASE_URL + "/buys";
    }
    else if (category == 'commercial'){
      cat_url = BASE_URL + "/commercials";
    }
    axios.post(cat_url)
    .then((res) => {
      if(res.data.result === "ok"){
        this.props.Peroperty_State_Action(res.data.data);
        this.setState({
          redirect:true,
          display:false ,
        })
      } 
    })
  }


  showCategoryCar = ()=> {
    this.setState({display:true});
    axios.post(BASE_URL + `/getCarList`,)
    .then((res) => {
      if(res.data.result === "ok"){
        this.props.PeropertyCar_State_Action(res.data.data);
        this.setState({
          display:false ,
          redirectcar : true ,
        });
      } 
    })
  }


  showCategoryHotel = ()=> {
    this.setState({display:true});
    axios.get(BASE_URL_Majid + `/hotels/show_all`)
    .then((res) => {
      if(res.data.result === "ok"){
        this.props.PeropertyHotel_State_Action(res.data.data);
        this.setState({
          display:false ,
          redirecthotel : true ,
        });
      } 
    })
  }


  render() {
    const { SubMenu } = Menu;

    return (
     <div className="head">
      { this.state.display == true ? <Loader/> : null}
      { this.state.redirect ? (<Redirect  to='/list'  />) : null }
      { this.state.redirectcar ? (<Redirect  to='/listcar'  />) : null }
      { this.state.redirecthotel ? (<Redirect  to='/listhotel'  />) : null }
       <div className="head-one">
          <div className="head-one-init">
            <PhoneOutlined/> 0913232525
            <MailOutlined /> info@reaestate.com
          </div>
       </div>
       <div className="head-menu">
        <Link to="/login"><button className="head-menu-button">Submit Property</button></Link>
       <Menu  mode="horizontal">
        <Menu.Item className="head-logo-item" key="Logo" >
          <Link to="/"><img src={Logo} alt="Logo"></img></Link>
        </Menu.Item>
        <Menu.Item key="mail" >
          <Link to="/">Home</Link>
        </Menu.Item>
        <SubMenu key="SubMenu" icon={<CaretDownOutlined />} title="Peroperty">
          <Menu.Item onClick={()=>this.showCategory('rent')} key="1">Rent</Menu.Item>
          {/* <Menu.Item onClick={()=>this.showCategory('buy')} key="2">Buy</Menu.Item> */}
          <Menu.Item onClick={()=>this.showCategory('commercial')} key="3">Commercial</Menu.Item>
          <Menu.Item onClick={()=>this.showCategoryHotel()} key="4">Hotel</Menu.Item>
          <Menu.Item onClick={()=>this.showCategoryCar()} key="5">Car</Menu.Item>
        </SubMenu>
        <Menu.Item className="submit_property_menu_item" key="Submit_Property" >
          <Link to="/login">Submit Property</Link>
        </Menu.Item>
      </Menu>
       </div>
     </div>
    );
  }
}


const mapStateToProps = state => {

  return({
    Displaylistdata: state.listdata,
    Displaycarlistdata: state.listcardata,
    Displayhotellistdata: state.listhoteldata,
  })
}
const mapDispatchToProps = {Peroperty_State_Action , PeropertyCar_State_Action , PeropertyHotel_State_Action};

export default connect(mapStateToProps,  mapDispatchToProps )(Head);

