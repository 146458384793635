import React from 'react';
import 'antd/dist/antd.css';
import Head from '../widget/Head';
import Slider from '../widget/Slider';
import Filter from '../widget/Filter';
import ProductSection from '../widget/ProductSection';
import AgentSection from '../widget/AgentSection';
import PopularPosts from '../widget/PopularPosts';
import BrandSection from '../widget/BrandSection';
import Footer from '../widget/Footer';




class First extends React.Component {


  render() {


    return (
      <div className="main">
        <Head/>
        <Slider/>
        <Filter classdata="filter filter-first"  />
        <ProductSection/>
        <AgentSection/>
        <PopularPosts/>
        <BrandSection/>
        <Footer/>
      </div>
    );
  }
}

export default First;
