import React from 'react';
import { Row } from 'antd';
import axios from 'axios';
import { Form, Input, InputNumber, Button , Select  } from 'antd';
import Loader2 from '../../widget/Loader2';
import BASE_URL from '../../../BASE_URL';
import PANEL_BASE_URL from '../../../PANEL_BASE_URL';
import ADMIN_BASE_URL from '../../../ADMIN_BASE_URL';




class AdminEditProperty extends React.Component {

  state = {
    processing:false,
    detail_data : "",
  }

   onFinish = values => {
    // if(values.name === undefined) {values.name = this.state.detail_data.name;}
    // if(values.bedroom === undefined) {values.bedroom = this.state.detail_data.bedroom;}
    // if(values.bathroom === undefined) {values.bathroom = this.state.detail_data.bathroom;}
    // if(values.size === undefined) {values.size = this.state.detail_data.size;}
    // if(values.category === undefined) {values.category = this.state.detail_data.category;}
    // if(values.description === undefined) {values.description = this.state.detail_data.description;}
    // if(values.address === undefined) {values.address = this.state.detail_data.address;}
    this.setState({ processing : true});
    const postdata = {
      "token": localStorage.getItem("admintoken"),
      "id":`${this.props.match.params.id}`,
      "name":values.name,
      "bedroom":values.bedroom,
      "bathroom":values.bathroom,
      "size":values.size,
      "category":values.category,
      "description":values.description,
      "address":values.address,
    }
    axios.post(ADMIN_BASE_URL + `/update_property`,  postdata )
    .then((res) => {
      if(res.data.result === 'ok'){
        this.setState({
          processing : false,
        });  
        return window.location.href = '/panel/admin/listbuy'
      }
      else if (res.data.error === "unauthenticated"){
        localStorage.clear(); 
        return window.location.href = '/login'
      } 
    })
  };


  formRef = React.createRef();

  componentDidMount(){
    this.setState({ processing : true});
    const postdata = {
      "id":`${this.props.match.params.id}`,
    }
    axios.post(BASE_URL + `/property`,  postdata )
    .then((res) => {
      if(res.data.result === 'ok'){
        this.setState({
          detail_data : res.data.data ,
          processing : false,
        });  
        this.formRef.current.setFieldsValue(this.state.detail_data);
      }
      else if (res.data.error === "unauthenticated"){
        localStorage.clear(); 
        return window.location.href = '/login'
      } 
    })
    
  }  
 
  render() {
    const layout = {
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
    };
    
    return (
      <>
        {this.state.processing ? <Loader2/> : null}
        <Form ref={this.formRef} {...layout} name="nest-messages" onFinish={this.onFinish} >
          <Form.Item name='name' label="name" >
            <Input />
          </Form.Item>
          <Form.Item name='bedroom' label="bedroom" >
            <InputNumber />
          </Form.Item>
          <Form.Item name='bathroom' label="bathroom" >
            <InputNumber />
          </Form.Item>
          <Form.Item name='size' label="size" >
            <InputNumber />
          </Form.Item>
          <Form.Item name='category' label="category" >
            <Select>
              <Select.Option value="buy">Buy</Select.Option>
              <Select.Option value="rent">Rent</Select.Option>
              <Select.Option value="commericial">Commericial</Select.Option>
              <Select.Option value="hotel">Hotel</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='description' label="description" >
            <Input.TextArea />
          </Form.Item>
          <Form.Item name='address' label="address" >
            <Input.TextArea />
          </Form.Item>
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
      </Form>
      </>
     
    );
  }
}


export default AdminEditProperty;
