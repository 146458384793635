import React, { useEffect, useState }  from "react" ;
import Logo from '../img/logo2.png';
import Logo2 from '../img/logo3.png';
import NavData from './NavData';

import {
    MoneyCollectOutlined ,
    AlignLeftOutlined  ,
    BarChartOutlined ,
    LogoutOutlined ,
    BarsOutlined
    } 
from '@ant-design/icons';
import {Link} from 'react-router-dom';

function NavPanel(props) {
    const[activity , setActivity] = useState(0);

    const logout = () => {
        window.localStorage.clear(); 
        return window.location.href = '/login'
      }

    useEffect(() => {
        window.addEventListener('resize', ()=>{
            if(window.innerWidth > 760){
                document.getElementById('nav').style.removeProperty('width');
            }
        });

        if(window.screen.width < 760){
            let ele = document.getElementById('nav');
            if(props.drowerMobile){
                ele.style.width = "250px"
            }
            else {
                ele.style.width = "0px"
            }       
        }
    },[props.drowerMobile]);

    const role = localStorage.getItem('role');

    return (
        <div id="nav" className={`nav ${!props.drower ? 'navClose' : null}`}>
            <div className="nav-head">
                {props.drower ? 
                <img src={Logo} alt="logo" />
                :
                <img src={Logo2} alt="logo" />
                }
                <AlignLeftOutlined  onClick={props.changedrowerMobile}/>
            </div>
            {NavData.map((item , index)=> 
                <>  
                {item.role == role ? 
                    <div onClick={()=>{setActivity(index)}} className='nav-item' >
                        {!item.isSubmenu ? 
                            <Link to={item.link}>
                                <div className={`nav-item-inner ${activity == index ? 'nav-item-inner-active' : null} ${!props.drower ? 'nav-item-inner-small' : null}`}>
                                    <span className="nav-item-cont">
                                        <BarsOutlined className={`${!props.drower ? 'nav-item-cont-icon-small' : null}`}/>
                                        {props.drower ? 
                                            <span className="nav-item-cont-title">{item.name}</span>
                                        :null}
                                    </span>
                                </div> 
                            </Link>
                        :
                            <div className={`nav-item-inner ${activity == index ? 'nav-item-inner-active' : null} ${!props.drower ? 'nav-item-inner-small' : null}`}>
                                <span className="nav-item-cont">
                                    <BarsOutlined className={`${!props.drower ? 'nav-item-cont-icon-small' : null}`}/>
                                    {props.drower ? 
                                        <span className="nav-item-cont-title">{item.name}</span>
                                    :null}
                                </span>
                            </div> 
                        }
                        {item.isSubmenu ? 
                        <div className={`nav-item-sub ${activity == index && 'nav-item-sub-active'}`}>
                            {item.submenu.map(items=> 
                            <Link to={items.link}>
                                <div className="nav-item-sub-item">
                                    <BarChartOutlined  className="nav-item-sub-item-icon" />
                                    {props.drower &&
                                        <span className="nav-item-sub-item-text">{items.name}</span>
                                    }
                                </div>
                            </Link>
                            )}
                        </div>
                        : null}
                    </div>
                :null}
                </>
            )}
            <div onClick={logout}  className='nav-item' >
                <div className={`nav-item-inner`}>
                    <span className="nav-item-cont">
                        <LogoutOutlined />
                        {props.drower &&
                            <span  className="nav-item-cont-title">Logout</span>
                        }    
                    </span>
                </div> 
            </div>
        </div>
    )
}

export default NavPanel;