import React from 'react';
import { Form, Input, InputNumber, Button , Select , Upload} from 'antd';
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import { Row, Col } from 'antd';
import PANEL_BASE_URL from '../../../PANEL_BASE_URL';
import { Modal } from 'antd';
import axios from 'axios';
import Loader2 from '../../widget/Loader2';


  class AgentAddProperty extends React.Component {

    state = {
      processing : false ,
      visible : false ,
      fileList : [],
      markers : [45.4, -75.7] ,
    }

  addMarker = (e) => {
    const newmarker = [];
    newmarker.push(e.latlng.lat);
    newmarker.push(e.latlng.lng);
    this.setState({markers :newmarker});
  }

  handleCancel = () => {
    this.setState({visible :false});
    return window.location.href = '/panel/agent/agentlistproperty'
  }

  onChange = ({ fileList: newFileList }) => {
    this.setState({fileList :newFileList});
  };

  onPreview = async file => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };


  onFinish = values => {
    if(this.state.fileList.length == 0){
      alert("Selecting a photo is required ")
    }
    else {
      this.setState({processing:true});
      const bodyFormData = new FormData();
      bodyFormData.append('name', values.user.name);
      bodyFormData.append('description', values.user.description);
      bodyFormData.append('lat', this.state.markers[0]);
      bodyFormData.append('lng', this.state.markers[1]);
      bodyFormData.append('price', values.user.price);
      bodyFormData.append('size', values.user.size);
      bodyFormData.append('bedroom', values.user.bedroom);
      bodyFormData.append('bathroom', values.user.bathroom);
      bodyFormData.append('address', values.user.address);
      bodyFormData.append('city', values.user.city);
      bodyFormData.append('year_built', "5");
      bodyFormData.append('garage_capacity', 0);
      bodyFormData.append('due_date', values.user.due_date);
      bodyFormData.append('type', values.user.type);
      bodyFormData.append('category', values.user.category);
      bodyFormData.append('token', localStorage.getItem("agenttoken"));
      for(var i in this.state.fileList){
        bodyFormData.append("image"+ i,this.state.fileList[i].originFileObj );
      }
      axios.post(PANEL_BASE_URL + `/createProperty`, bodyFormData , {headers: {"Content-type": "multipart/form-data"}})
      .then((res) => {
        console.log(res)
        if(res.data.result === "ok"){
          this.setState({
            processing:false,
            visible:true,
          });
        }
        else if (res.data.error === "unauthenticated") {
          this.setState({processing:false});
          localStorage.clear(); 
          return window.location.href = '/login';
        }
      })
      .catch((err)=>{
        this.setState({processing : false })
        console.log("err");
      })
    }
  };

  render() {
 
  const layout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 22 },
  };
  
  return (
  <div className="add-product-form"> 
    {this.state.processing ? <Loader2/> : null}
    <Modal
          onCancel={this.handleCancel}
          visible={this.state.visible}
          footer={null}
        >
          <p>Add Success</p>
    </Modal> 
    <Form {...layout} name="nest-messages" onFinish={this.onFinish} >
      <Form.Item name={['user', 'name']} label="name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name={['user', 'price']} label="price" rules={[{ required: true }]}>
        <InputNumber />
      </Form.Item>
      <Form.Item name={['user', 'size']} label="size" rules={[{ required: true }]}>
        <InputNumber />
      </Form.Item>
      <Form.Item name={['user', 'bedroom']} label="bedroom" rules={[{ required: true }]}>
        <InputNumber />
      </Form.Item>
      <Form.Item name={['user', 'bathroom']} label="bathroom" rules={[{ required: true }]}>
        <InputNumber />
      </Form.Item>
      <Form.Item name={['user', 'category']} label="category" rules={[{ required: true }]}>
        <Select>
          <Select.Option value="buy">Buy</Select.Option>
          <Select.Option value="rent">Rent</Select.Option>
          <Select.Option value="commercial">Commericial</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name={['user', 'description']} label="description" rules={[{ required: true }]}>
        <Input.TextArea />
      </Form.Item>
      <Form.Item name={['user', 'due_date']} label="due date" rules={[{ required: true }]}>
        <Select>
          <Select.Option value="1">1</Select.Option>
          <Select.Option value="2">2 </Select.Option>
          <Select.Option value="3">3</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name={['user', 'type']} label="type" rules={[{ required: true }]}>
        <Select>
            <Select.Option value="1">Aparteman</Select.Option>
            <Select.Option value="2">villa</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name={['user', 'city']} label="city" rules={[{ required: true }]}>
        <Select>
          <Select.Option value="Dubai">Dubai</Select.Option>
          <Select.Option value="Abu Dhabi">Abu Dhabi</Select.Option>
          <Select.Option value="Sharjah">Sharjah</Select.Option>
          <Select.Option value="Al Ain">Al Ain</Select.Option>
          <Select.Option value="Ajman">Ajman</Select.Option>
          <Select.Option value="RAK City">RAK City</Select.Option>
          <Select.Option value="Fujairah">Fujairah</Select.Option>
          <Select.Option value="Umm al-Quwain">Umm al-Quwain</Select.Option>
          <Select.Option value="Khor Fakkan">Khor Fakkan</Select.Option>
          <Select.Option value="Kalba">Kalba</Select.Option>
          <Select.Option value="Jebel Ali">Jebel Ali</Select.Option>
          <Select.Option value="Dibba Al-Fujairah">Dibba Al-Fujairah</Select.Option>
          <Select.Option value="Madinat Zayed">Madinat Zayed</Select.Option>
          <Select.Option value="Ruwais">Ruwais</Select.Option>
          <Select.Option value="Liwa Oasis">Liwa Oasis</Select.Option>
          <Select.Option value="Dhaid">Dhaid</Select.Option>
          <Select.Option value="Ghayathi">Ghayathi</Select.Option>
          <Select.Option value="Ar-Rams">Ar-Rams</Select.Option>
          <Select.Option value="Dibba Al-Hisn"> 	Dibba Al-Hisn</Select.Option>
          <Select.Option value="Hatta">Hatta</Select.Option>
          <Select.Option value="Al Madam">Al Madam</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name={['user', 'address']} label="address" rules={[{ required: true }]}>
        <Input.TextArea />
      </Form.Item>
      <Row justify="end">
        <Col span={22}>
       
            <Upload
              accept=".png , .jpg , .jpeg"
              listType="picture-card"
              fileList={this.state.fileList}
              onChange={this.onChange}
              onPreview={this.onPreview}
              customRequest={this.dummyRequest}
              >
              {this.state.fileList.length < 5 && '+ Upload'}
            </Upload>
        </Col>
      </Row>
      <Row justify="end">
        <Col span={22}>
          <Map center={[45.4, -75.7]} zoom={12} onClick={this.addMarker}>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker  position={this.state.markers}>
              </Marker>
          </Map>
        </Col>
      </Row>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
</div>    
  );}
}


export default AgentAddProperty;
