import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Row , Col } from 'antd';
import CheckUserAuth from '../widget/CheckUserAuth';
import {VisibleAuth_State_Action} from '../../action/ListPeropertyAction';
import {connect} from "react-redux";
import axios from 'axios' ;
import BASE_URL_APP from '../../BASE_URL_APP';
import BASE_URL_Majid from '../../BASE_URL_Majid';
import Loader from '../widget/Loader';
import moment from 'moment';



class Comment extends React.Component {

  state = {
    processing : false ,
  }


  onFinishCar=(e)=>{
    this.setState({processing : true })
    e.preventDefault();
    const postdata = {
      'massage':e.target[0].value , 
      'car_id': this.props.peroperty_id ,
      'username':localStorage.getItem('username')
    }
    axios.post(BASE_URL_APP + `/car_add_review`,  postdata )
    .then((res) => {
      if(res.data.result === 'ok'){
        this.setState({processing : false})
      }
      else if (res.data.error === "unauthenticated"){
        localStorage.clear(); 
        return window.location.href = '/login'
      }
      else if (res.data.error == "members can add one review for one item"){
        this.setState({processing : false })
        alert("members can add one review for one item")
      }
    })
    .catch((err)=>{
      this.setState({processing : false }) ;
      alert("Network Error")
      console.log("err");
    })
  }



  onFinishHotel=(e)=>{
    this.setState({processing : true })
    e.preventDefault();
    const postdata = {
      'text':e.target[0].value , 
      'hotel_id': this.props.peroperty_id ,
      'user_email':localStorage.getItem('username')
    }
    axios.post(BASE_URL_Majid + `/hotel_comments`,  postdata )
    .then((res) => {
      if(res.data.result === 'ok'){
        this.setState({processing : false})
      }
      else if (res.data.error === "unauthenticated"){
        localStorage.clear(); 
        return window.location.href = '/login'
      }
      else if (res.data.error == "members can add one review for one item"){
        this.setState({processing : false })
        alert("members can add one review for one item")
      }
    })
    .catch((err)=>{
      this.setState({processing : false }) ;
      alert("Network Error")
      console.log("err");
    })
  }




  onFinishHome=(e)=>{
    this.setState({processing : true })
    e.preventDefault();
    const postdata = {
      'massage':e.target[0].value , 
      'property_id': this.props.peroperty_id ,
      'username':localStorage.getItem('username')
    }
    axios.post(BASE_URL_APP + `/property_add_review`,  postdata )
    .then((res) => {
      if(res.data.result === 'ok'){
        this.setState({processing : false})
      }
      else if (res.data.error === "unauthenticated"){
        localStorage.clear(); 
        return window.location.href = '/login'
      }
      else if (res.data.error == "members can add one review for one item"){
        this.setState({processing : false })
        alert("members can add one review for one item")
      }
    })
    .catch((err)=>{
      console.log("err");
    })
  }


  onFinish=(e)=>{
    e.preventDefault();
    if(CheckUserAuth()){
      if(this.props.type == 'home'){
        this.onFinishHome(e) ; 
      }
      else if (this.props.type == 'car'){
        this.onFinishCar(e) ; 
      }
      else if (this.props.type == 'hotel'){
        this.onFinishHotel(e) ; 
      }
    }
    else {
      this.props.VisibleAuth_State_Action(true)
    }

  }

  render() {
    return (
     <div className="comment">
       {this.state.processing ? <Loader/> : null}
       <div className="comment-head"><h3>Comment</h3></div>
       <div className="comment-cont">
         {this.props.listComment.map(item =>
         <div className="comment-cont-item">
           <div className="comment-cont-item-icon">
            <UserOutlined />
            <span className="comment-cont-item-icon-username">{item.username}</span>
           </div>
           <div className="comment-cont-item-Des">
             <span>
             {item.massage}
             </span>
           </div>
           <div className="comment-cont-item-Date">
             <div>{moment(item.created_at).format('YYYY-MM-DD')}</div>
           </div>
         </div>
         )}
         <div className="comment-cont-form">
           <form onSubmit={this.onFinish}>
             <h3>Add a Review</h3>
             <textarea placeholder="Type Your Comment ..." required></textarea>
             <button> Submit </button>
           </form>
         </div>
       </div>
     </div>
    );
  }
}


const mapStateToProps = state => {

  return({
    DisplayVisibleModalAuth: state.visibleAuth,

  })
}
const mapDispatchToProps = {VisibleAuth_State_Action};

export default connect(mapStateToProps , mapDispatchToProps)(Comment);
