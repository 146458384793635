export function Peroperty_State_Action(input) {
    return {
        type: 'listdata_Loader',
        payload: input
    }
}


export function PeropertyCar_State_Action(input) {
    return {
        type: 'listcardata_Loader',
        payload: input
    }
}


export function PeropertyHotel_State_Action(input) {
    return {
        type: 'listhoteldata_Loader',
        payload: input
    }
}


export function VisibleAuth_State_Action(input) {
    return {
        type: 'visibleAuth_Loader',
        payload: input
    }
}

export default {
    Peroperty_State_Action ,
    PeropertyCar_State_Action ,
    PeropertyHotel_State_Action ,
    VisibleAuth_State_Action ,
    };