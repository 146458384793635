import React  from "react" ;

const NavData = [

    // Route User
    {
        'name':"User List Car" ,
        'isSubmenu': false ,
        'link':'/panel/user/listcar' ,
        'role':'user'
    },
    {
        'name':"User Add Car" ,
        'isSubmenu': false ,
        'link':'/panel/user/addcar' ,
        'role':'user'
    },
    {
        'name':"User List Hotel Reserve" ,
        'isSubmenu': false ,
        'link':'/panel/user/listReserve' ,
        'role':'user'
    },

    // Route Agent
    {
        'name':"Agent List peroperty" ,
        'isSubmenu': false ,
        'link':'/panel/agent/agentlistproperty' ,
        'role':'agent'
    },
    {
        'name':"Agent Add Peroperty" ,
        'isSubmenu': false ,
        'link':'/panel/agent/agentaddproperty' ,
        'role':'agent'
    },
    {
        'name':"Edit Profile" ,
        'isSubmenu': false ,
        'link':'/panel/agent/edit-profile' ,
        'role':'agent'
    },

    // Route Company
    {
        'name':"List Agent" ,
        'isSubmenu': false ,
        'link':'/panel/company/listagent' ,
        'role':'company'
    },
    {
        'name':"Add Agent" ,
        'isSubmenu': false ,
        'link':'/panel/company/addagent' ,
        'role':'company'
    },

    // Route Admin
    {
        'name':"Dashboard" ,
        'isSubmenu': false ,
        'link':'/panel/admin/dashboard' ,
        'role':'admin'
    },
    {
        'name':"Hotel" ,
        'isSubmenu': true ,
        'role':'admin' ,
        'submenu':[
            {
                'name':"List Hotel" ,
                'link':'/panel/admin/listhotel' ,
            },
            {
                'name':"List Suite" ,
                'link':'/panel/admin/listsuite' ,
            },
            {
                'name':"List Reserve" ,
                'link':'/panel/admin/listreserve' ,
            },
        ]
    },
    {
        'name':"List Buy" ,
        'isSubmenu': false ,
        'link':'/panel/admin/listbuy' ,
        'role':'admin'
    },
    {
        'name':"List Rent" ,
        'isSubmenu': false ,
        'link':'/panel/admin/listrent' ,
        'role':'admin'
    },
    {
        'name':"List Commercials" ,
        'isSubmenu': false ,
        'link':'/panel/admin/listcommericials' ,
        'role':'admin'
    },
    {
        'name':"List Car" ,
        'isSubmenu': false ,
        'link':'/panel/admin/listcar' ,
        'role':'admin'
    },
    {
        'name':"List User" ,
        'isSubmenu': false ,
        'link':'/panel/admin/listuser' ,
        'role':'admin'
    },
    {
        'name':"List Company" ,
        'isSubmenu': false ,
        'link':'/panel/admin/listcompany' ,
        'role':'admin'
    },
    {
        'name':"List Agent" ,
        'isSubmenu': false ,
        'link':'/panel/admin/listagent' ,
        'role':'admin'
    },


]

export default NavData ;



// {
//     'name':"صفحات" ,
//     'isSubmenu': true ,
//     'submenu':[
//         {
//             'name':"صفحه اول" ,
//             'link':'/panel' ,
//         },
//         {
//             'name':"صفحه دوم" ,
//             'link':'/panel' ,
//         },
//     ]
// },