import React from 'react';
import { Form, Input, Button } from 'antd';
import { Descriptions  } from 'antd';
import { Table, Tag, Space } from 'antd';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  EditOutlined,
  DeleteOutlined  
} from '@ant-design/icons';
import { Modal  } from 'antd';
import Loader2 from '../../widget/Loader2';
import ADMIN_BASE_URL from '../../../ADMIN_BASE_URL';
import BASE_URL from '../../../BASE_URL';



class AdminListBuy extends React.Component {

  state = {
    visible: false,
    visible_detail_modal:false,
    visible_delete_modal:false ,
    list_data: [],
    processing: false ,
    delete_id :0 ,
    delete_name :" " ,
    detail_data:"",
  }

  // نمایش مدال برای حذف پروپرتی
  deleteshowmodal = (id , name) => {
    this.setState({
      visible_delete_modal: true,
      delete_id: id,
      delete_name: name,
    });
  };

  deleteitem = () => {
    this.setState({processing:true});
    const postdata = {
      "token": localStorage.getItem("admintoken"),
      "id":this.state.delete_id ,
    }
    axios.post(ADMIN_BASE_URL + `/delete_property`,  postdata )
    .then((res) => {
      if(res.data.result === 'ok'){
        this.setState({
          processing : false,
          visible_delete_modal:false,
        });  
        this.componentDidMount();
      }
      else if (res.data.error === "unauthenticated"){
        this.setState({processing:false,})
        localStorage.clear(); 
        return window.location.href = '/login'
      }
    })
  }

  // this function for show destail Modal;
  showdetailmodal = (id) => {
    this.setState({
      visible_detail_modal:true,
      processing:true,
    })
    const postdata = {
      "id":id
    }
    axios.post(BASE_URL + `/property`,  postdata )
    .then((res) => {
      if(res.data.result === 'ok'){
        this.setState({
          detail_data : res.data.data ,
          processing : false,
        });  
      }
      else if (res.data.error === "unauthenticated"){
        this.setState({processing:false,})
        localStorage.clear(); 
        return window.location.href = '/login'
      }
    })
  }





  handleCancel = () => {
    this.setState({
      visible: false,
      visible_detail_modal:false,
      visible_delete_modal:false,
    });
  };

  componentDidMount(){
    this.setState({ processing : true});
    const postdata = {
      "token": localStorage.getItem("admintoken"),
    };
    axios.post(ADMIN_BASE_URL + `/all_buys`,  postdata )
    .then((res) => {
      if(res.data.result === 'ok'){
        this.setState({
          list_data : res.data.data ,
          processing : false,
        });  
      }
      else if (res.data.error === "unauthenticated"){
        localStorage.clear(); 
        return window.location.href = '/login'
      }
      
    })
    
    }
 
  render() {
  
    const columns = [
      {
        title: 'id',
        dataIndex: 'id',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        render: (text , row) => <span className="title-list-name" onClick={() => this.showdetailmodal(row.id)}>{text}</span>,
      },
      {
        title: 'edit',
        render:  row => <Link to={`/panel/admin/admineditproperty/${row.id}`}><EditOutlined /></Link>,
      },
      {
        title: 'delete',
        render:  row => <DeleteOutlined onClick={() => this.deleteshowmodal(row.id , row.name)} />,
      },


    ]

    return (
      <>
      {this.state.processing ? <Loader2/> : null}
      {/* this modal for show detail */}
      <Modal
          title="Detail"
          visible={this.state.visible_detail_modal}
          onCancel={this.handleCancel}
          footer={null}
          className="detail-property-modal"
          width={1000}
        >     
        <div>
          <Descriptions
            bordered
            // column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
          >
            <Descriptions.Item label="Name" span={3}>{this.state.detail_data.name}</Descriptions.Item>
            <Descriptions.Item label="Bedroom" span={1}>{this.state.detail_data.bedroom}</Descriptions.Item>
            <Descriptions.Item label="Bathroom" span={1}>{this.state.detail_data.bathroom}</Descriptions.Item>
            <Descriptions.Item label="Size" span={1}>{this.state.detail_data.size}</Descriptions.Item>
            <Descriptions.Item label="Category" span={1}>{this.state.detail_data.category}</Descriptions.Item>
            <Descriptions.Item label="Address" span={3}>{this.state.detail_data.address}</Descriptions.Item>
            <Descriptions.Item label="Description" span={3}>{this.state.detail_data.description}</Descriptions.Item>
          </Descriptions>
        </div>
      </Modal>
      <Modal
          title="Delete"
          visible={this.state.visible_delete_modal}
          onCancel={this.handleCancel}
          onOk={this.deleteitem}
        > 
          <p>Do you want to delete the " {this.state.delete_name} " ?</p>
        </Modal>
      <Table columns={columns} dataSource={this.state.list_data} />
      </>
     
    );
  }
}


export default AdminListBuy;
