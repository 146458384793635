import React from 'react';
import axios from 'axios';
import { Form, Input, InputNumber, Button , Select , Upload , Row , Col} from 'antd';
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import Loader2 from '../../widget/Loader2';
import BASE_URL_Majid from '../../../BASE_URL_Majid';




class AdminAddHotel extends React.Component {

  state = {
    processing:false,
    visible : false ,
    fileList : [],
    markers : [45.4, -75.7] ,
  }


  addMarker = (e) => {
    const newmarker = [];
    newmarker.push(e.latlng.lat);
    newmarker.push(e.latlng.lng);
    this.setState({markers :newmarker});
  }

  handleCancel = () => {
    this.setState({visible :false});
    return window.location.href = '/panel/admin/listhotel'
  }

  onChange = ({ fileList: newFileList }) => {
    this.setState({fileList :newFileList});
  };

  onPreview = async file => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };


   onFinish = values => {
    if(this.state.fileList.length == 0){
      alert("Selecting a photo is required ")
    }
    else {
      this.setState({ processing : true});
      const bodyFormData = new FormData();
      bodyFormData.append('name', values.name);
      bodyFormData.append('level', values.level);
      bodyFormData.append('cafe', values.cafe);
      bodyFormData.append('pool', values.pool);
      bodyFormData.append('parking', values.parking);
      bodyFormData.append('restaurant', values.restaurant);
      bodyFormData.append('lat', this.state.markers[0]);
      bodyFormData.append('lng', this.state.markers[1]);
      bodyFormData.append('address', values.address);
      bodyFormData.append('city', values.city);
      bodyFormData.append('description', values.description);
      for(var i in this.state.fileList){
        bodyFormData.append("image"+ i,this.state.fileList[i].originFileObj );
      }
      axios.post(BASE_URL_Majid + `/hotels`, bodyFormData , {headers: {"Content-type": "multipart/form-data"}} )
      .then((res) => {
        if(res.data.result === 'ok'){
          this.setState({
            processing : false,
          });  
          return window.location.href = '/panel/admin/listhotel'
        }
        else if (res.data.error === "unauthenticated"){
          localStorage.clear(); 
          return window.location.href = '/login'
        } 
      })
    }
  };


 
  render() {
    const layout = {
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
    };
    
    return (
      <>
        {this.state.processing ? <Loader2/> : null}
        <Form ref={this.formRef} {...layout} name="nest-messages" onFinish={this.onFinish} >
          <Form.Item name='name' label="Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name='level' label="Level" rules={[{ required: true }]}>
            <InputNumber />
          </Form.Item>
          <Form.Item name='cafe' label="Cafe" rules={[{ required: true }]}>
            <Select>
              <Select.Option value={1}>Yes</Select.Option>
              <Select.Option value={0}>No</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='pool' label="Pool" rules={[{ required: true }]}>
            <Select>
              <Select.Option value={1}>Yes</Select.Option>
              <Select.Option value={0}>No</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='parking' label="Parking" rules={[{ required: true }]}>
            <Select>
              <Select.Option value={1}>Yes</Select.Option>
              <Select.Option value={0}>No</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='restaurant' label="Restaurant" rules={[{ required: true }]}>
            <Select>
              <Select.Option value={1}>Yes</Select.Option>
              <Select.Option value={0}>No</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='city' label="City" rules={[{ required: true }]}>
            <Select>
              <Select.Option value="Dubai">Dubai</Select.Option>
              <Select.Option value="Abu Dhabi">Abu Dhabi</Select.Option>
              <Select.Option value="Sharjah">Sharjah</Select.Option>
              <Select.Option value="Al Ain">Al Ain</Select.Option>
              <Select.Option value="Ajman">Ajman</Select.Option>
              <Select.Option value="RAK City">RAK City</Select.Option>
              <Select.Option value="Fujairah">Fujairah</Select.Option>
              <Select.Option value="Umm al-Quwain">Umm al-Quwain</Select.Option>
              <Select.Option value="Khor Fakkan">Khor Fakkan</Select.Option>
              <Select.Option value="Kalba">Kalba</Select.Option>
              <Select.Option value="Jebel Ali">Jebel Ali</Select.Option>
              <Select.Option value="Dibba Al-Fujairah">Dibba Al-Fujairah</Select.Option>
              <Select.Option value="Madinat Zayed">Madinat Zayed</Select.Option>
              <Select.Option value="Ruwais">Ruwais</Select.Option>
              <Select.Option value="Liwa Oasis">Liwa Oasis</Select.Option>
              <Select.Option value="Dhaid">Dhaid</Select.Option>
              <Select.Option value="Ghayathi">Ghayathi</Select.Option>
              <Select.Option value="Ar-Rams">Ar-Rams</Select.Option>
              <Select.Option value="Dibba Al-Hisn"> 	Dibba Al-Hisn</Select.Option>
              <Select.Option value="Hatta">Hatta</Select.Option>
              <Select.Option value="Al Madam">Al Madam</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='description' label="description" rules={[{ required: true }]}>
            <Input.TextArea />
          </Form.Item>
          <Form.Item name='address' label="address" rules={[{ required: true }]}>
            <Input.TextArea />
          </Form.Item>
          <Row justify="end">
            <Col span={22}>
          
                <Upload
                  accept=".png , .jpg , .jpeg"
                  listType="picture-card"
                  fileList={this.state.fileList}
                  onChange={this.onChange}
                  onPreview={this.onPreview}
                  customRequest={this.dummyRequest}
                  >
                  {this.state.fileList.length < 5 && '+ Upload'}
                </Upload>
            </Col>
          </Row>
          <Row justify="end">
            <Col span={22} >
              <Map style={{height:"250px"}} center={[45.4, -75.7]} zoom={12} onClick={this.addMarker}>
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  />
                  <Marker  position={this.state.markers}>
                  </Marker>
              </Map>
            </Col>
          </Row>
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }} style={{marginTop:"20px"}}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
      </Form>
      </>
     
    );
  }
}


export default AdminAddHotel;
