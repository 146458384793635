import React from 'react';
import { Row } from 'antd';
import axios from 'axios';
import { Form, Input, InputNumber, Button , Select  } from 'antd';
import Loader2 from '../../widget/Loader2';
import BASE_URL from '../../../BASE_URL';
import PANEL_BASE_URL from '../../../PANEL_BASE_URL';



class AgentEditProperty extends React.Component {

  state = {
    processing:false,
    detail_data : "",
  }

   onFinish = values => {
    this.setState({ processing : true});
    const postdata = {
      "token":localStorage.getItem("agenttoken"),
      "id":`${this.props.match.params.id}`,
      "name":values.name,
      "bedroom":values.bedroom,
      "bathroom":values.bathroom,
      "size":values.size,
      "category":values.category,
      "description":values.description,
      "address":values.address,
      "city":values.city,
      "type":values.type,
      "due_date":values.due_date,
    }
    axios.post(PANEL_BASE_URL + `/update_property`,  postdata )
    .then((res) => {
      if(res.data.result === 'ok'){
        this.setState({
          processing : false,
        });  
        return window.location.href = '/panel/agent/agentlistproperty'
      }
      else if (res.data.error === "unauthenticated"){
        localStorage.clear(); 
        return window.location.href = '/login'
      } 
    })
  };


  formRef = React.createRef();
 

  componentDidMount(){
    this.setState({ processing : true});
    const postdata = {
      "token":localStorage.getItem("agenttoken"),
      "id":`${this.props.match.params.id}`,
    }
    axios.post(PANEL_BASE_URL + `/selectProperty`,  postdata )
    .then((res) => {
      if(res.data.result === 'ok'){
        this.setState({
          detail_data : res.data.data ,
          processing : false,
        });  
        this.formRef.current.setFieldsValue(this.state.detail_data);
      }
      else if (res.data.error === "unauthenticated"){
        localStorage.clear(); 
        return window.location.href = '/login'
      } 
    })
  }  
 
  render() {
    const layout = {
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
    };
    
    return (
      <>
        {this.state.processing ? <Loader2/> : null}
        <Form ref={this.formRef} {...layout} name="nest-messages" onFinish={this.onFinish} >
          <Form.Item name='name' label="name" >
            <Input />
          </Form.Item>
          <Form.Item name='bedroom' label="bedroom" >
            <InputNumber />
          </Form.Item>
          <Form.Item name='bathroom' label="bathroom" >
            <InputNumber />
          </Form.Item>
          <Form.Item name='size' label="size" >
            <InputNumber />
          </Form.Item>
          <Form.Item name='category' label="category" >
            <Select>
              <Select.Option value="buy">Buy</Select.Option>
              <Select.Option value="rent">Rent</Select.Option>
              <Select.Option value="commericial">Commericial</Select.Option>
              <Select.Option value="hotel">Hotel</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='due_date' label="due date" >
            <Select>
              <Select.Option value="1">1</Select.Option>
              <Select.Option value="2">2 </Select.Option>
              <Select.Option value="3">3</Select.Option>
           </Select>
          </Form.Item>
          <Form.Item name='type' label="type" >
            <Select>
              <Select.Option value="1">1</Select.Option>
              <Select.Option value="2">2</Select.Option>
              <Select.Option value="3">3</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='city' label="city" >
            <Select>
              <Select.Option value="Dubai">Dubai</Select.Option>
              <Select.Option value="AbuDhabi">Abu Dhabi</Select.Option>
              <Select.Option value="Fujairah">Fujairah</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='description' label="description" >
            <Input.TextArea />
          </Form.Item>
          <Form.Item name='address' label="address" >
            <Input.TextArea />
          </Form.Item>
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
      </Form>
      </>
     
    );
  }
}


export default AgentEditProperty;
