import React from 'react';
import {
  Form,
  Select,
  Button,
  Collapse,
  Modal ,
} from 'antd';
import {SearchOutlined  } from '@ant-design/icons';
import {connect} from "react-redux";
import BASE_URL from '../../BASE_URL';
import axios from 'axios';
import {PeropertyCar_State_Action} from '../../action/ListPeropertyAction';
import { Redirect } from 'react-router-dom';
import Loader from './Loader';



class CarSearch extends React.Component {

  state = {
    redirect : false ,
    visible : false ,
    display : false ,
  }

  handleOk = ()=> {
    this.setState({visible:false});
  }

  
  onFinish = (values)=> {
    this.setState({display:true})
    for(var i in values){
      if(values[i] === undefined){
        values[i] = " ";
      }
    }
    const postdata = {
      "seat":values.Seat,
      "color":values.Color,
      "power":values.Power,
      "door":values.Door,
      "company":values.company ,
    };
    axios.post(BASE_URL + `/carFilter` , postdata)
      .then((res) => {
        if(res.data.result === "ok"){
          this.props.PeropertyCar_State_Action(res.data.data);
          this.setState({
            redirect:true,
            display:false ,
          })
        } 
        else if(res.data.result === "error"){
          this.setState({
            visible : true,
            display:false ,
          })
        }
      })
  }



  render() {
    const { Panel } = Collapse;
    return (
    <>  
      { this.state.redirect ? (<Redirect  to='listcar'  />) : null }
      { this.state.display == true ? <Loader/> : null}
      <Form
        layout="filter"
        id="filter-form"
        onFinish={this.onFinish}
      >
        <Form.Item name="city" >
            <Select placeholder="City">
              <Select.Option value="all">All</Select.Option>
              <Select.Option value="Dubai">Dubai</Select.Option>
              <Select.Option value="Abu Dhabi">Abu Dhabi</Select.Option>
              <Select.Option value="Sharjah">Sharjah</Select.Option>
              <Select.Option value="Al Ain">Al Ain</Select.Option>
              <Select.Option value="Ajman">Ajman</Select.Option>
              <Select.Option value="RAK City">RAK City</Select.Option>
              <Select.Option value="Fujairah">Fujairah</Select.Option>
              <Select.Option value="Umm al-Quwain">Umm al-Quwain</Select.Option>
              <Select.Option value="Khor Fakkan">Khor Fakkan</Select.Option>
              <Select.Option value="Kalba">Kalba</Select.Option>
              <Select.Option value="Jebel Ali">Jebel Ali</Select.Option>
              <Select.Option value="Dibba Al-Fujairah">Dibba Al-Fujairah</Select.Option>
              <Select.Option value="Madinat Zayed">Madinat Zayed</Select.Option>
              <Select.Option value="Ruwais">Ruwais</Select.Option>
              <Select.Option value="Liwa Oasis">Liwa Oasis</Select.Option>
              <Select.Option value="Dhaid">Dhaid</Select.Option>
              <Select.Option value="Ghayathi">Ghayathi</Select.Option>
              <Select.Option value="Ar-Rams">Ar-Rams</Select.Option>
              <Select.Option value="Dibba Al-Hisn"> 	Dibba Al-Hisn</Select.Option>
              <Select.Option value="Hatta">Hatta</Select.Option>
              <Select.Option value="Al Madam">Al Madam</Select.Option>
          </Select>
        </Form.Item> 
        <Form.Item name="minprice" >
          <Select placeholder="Min Price" >
            <Select.Option value="20000">20,000 AED/year</Select.Option>
            <Select.Option value="30000">30,000 AED/year</Select.Option>
            <Select.Option value="40000">40,000 AED/year</Select.Option>
            <Select.Option value="50000">50,000 AED/year</Select.Option>
            <Select.Option value="100000">100,000 AED/year</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="maxprice" >
          <Select placeholder="Max Price" >
            <Select.Option value="20000">20,000 AED/year</Select.Option>
            <Select.Option value="30000">30,000 AED/year</Select.Option>
            <Select.Option value="40000">40,000 AED/year</Select.Option>
            <Select.Option value="50000">50,000 AED/year</Select.Option>
            <Select.Option value="100000">100,000 AED/year</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="seat"  >
          <Select placeholder="Seat" >
            <Select.Option value="2">2</Select.Option>
            <Select.Option value="3">3</Select.Option>
            <Select.Option value="4">4</Select.Option>
            <Select.Option value="6">6</Select.Option>
            <Select.Option value="8">8</Select.Option>
            <Select.Option value="10">10</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="color"  >
          <Select placeholder="Color"  >
            <Select.Option value={"gblack"}>Black</Select.Option>
            <Select.Option value={"white"}>White</Select.Option>
            <Select.Option value={"blue"}>Blue</Select.Option>
            <Select.Option value={"red"}>Red</Select.Option>
            <Select.Option value={"yellow"}>Yellow</Select.Option>
            <Select.Option value={"gray"}>Gray</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="door"  >
          <Select placeholder="Door"  >
            <Select.Option value={"2"}>2</Select.Option>
            <Select.Option value={"3"}>3</Select.Option>
            <Select.Option value={"4"}>4</Select.Option>
            <Select.Option value={"6"}>6</Select.Option>
            <Select.Option value={"8"}>8</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="year"  >
          <Select placeholder="Year" >
            <Select.Option value="2021">2021</Select.Option>
            <Select.Option value="2020">2020</Select.Option>
            <Select.Option value="2019">2019</Select.Option>
            <Select.Option value="2018">2018</Select.Option>
            <Select.Option value="2017">2017</Select.Option>
            <Select.Option value="2016">2016</Select.Option>
            <Select.Option value="2015">2015</Select.Option>
            <Select.Option value="2014">2014</Select.Option>
            <Select.Option value="2013">2013</Select.Option>
            <Select.Option value="2012">2012</Select.Option>
            <Select.Option value="2011">2011</Select.Option>
            <Select.Option value="2010">2010</Select.Option>
            <Select.Option value="2009">2009</Select.Option>
            <Select.Option value="2008">2008</Select.Option>
            <Select.Option value="2007">2007</Select.Option>
            <Select.Option value="2006">2006</Select.Option>
            <Select.Option value="2005">2005</Select.Option>
            <Select.Option value="2004">2004</Select.Option>
            <Select.Option value="2003">2003</Select.Option>
            <Select.Option value="2002">2002</Select.Option>
            <Select.Option value="2001">2001</Select.Option>
            <Select.Option value="2000">2000</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="company"  >
          <Select placeholder="Company" >
            <Select.Option value={"benz"}>Benz</Select.Option>
            <Select.Option value={"bmw"}>Bmw</Select.Option>
            <Select.Option value={"audi"}>Audi</Select.Option>
            <Select.Option value={"tesla"}>Tesla</Select.Option>
            <Select.Option value={"porsche"}>Porsche</Select.Option>
            <Select.Option value={"lexus"}>Lexus</Select.Option>
            <Select.Option value={"volvo"}>Volvo</Select.Option>
            <Select.Option value={"lamborghini"}>Lamborghini</Select.Option>
            <Select.Option value={"ferrari"}>Ferrari</Select.Option>
            <Select.Option value={"jaguar"}>Jaguar</Select.Option>
            <Select.Option value={"cadillac"}>Cadillac</Select.Option>
            <Select.Option value={"rolls royce"}>Rolls-Royce</Select.Option>
            <Select.Option value={"maserati"}>Maserati</Select.Option>
            <Select.Option value={"bugatti"}>Bugatti</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="transmission" >
          <Select placeholder="Transmission" >
            <Select.Option value="0">Manual</Select.Option>
            <Select.Option value="1">Automatic</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="insurance" >
          <Select placeholder="Insurance" >
            <Select.Option value="1">Yes</Select.Option>
            <Select.Option value="0">No</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item className="form-filter-submit">
          <Button htmlType="submit"><SearchOutlined/>Search</Button>
        </Form.Item>
      {/* <button onClick={()=>{console.log(this.props.Displaylistdata)}}> fwef</button> */}
      </Form>
      <Modal  visible={this.state.visible} onCancel={this.handleOk} footer={null}>
        <p>Data Not Found</p>
      </Modal>
    </>  
    );
  }
}

const mapStateToProps = state => {

  return({
    Displaylistdata: state.listcardata,
  })
}
const mapDispatchToProps = {PeropertyCar_State_Action};

export default connect(mapStateToProps,  mapDispatchToProps )(CarSearch);

