
import React, { useState } from "react" ;
import NavPanel from './NavPanel';
import ContHeadPanel from './ContHeadPanel';
import { Layout, Menu } from 'antd';
import {
  DesktopOutlined,
  DashboardOutlined ,
  LogoutOutlined ,
  BarsOutlined ,
  UserAddOutlined ,
  FolderAddOutlined 
} from '@ant-design/icons';
import PrivateRouteAgent from './PrivateRouteAgent';
import PrivateRouteCompany from './PrivateRouteCompany';
import PrivateRouteAdmin from './PrivateRouteAdmin';
import PrivateRouteUser from './PrivateRouteUser';
import { BrowserRouter as Router, Route , Link} from 'react-router-dom';
import logopanel from '../img/logo2.png';

// Import For Agent Panel
import AgentListProperty from './Agent/AgentListProperty';
import AgentAddProperty from './Agent/AgentAddProperty';
import AgentEditProperty from './Agent/AgentEditProperty';
import AgentEditProfile from './Agent/AgentEditProfile'

// // Import For User Panel
import UserListCar from './User/UserListCar';
import UserAddCar from './User/UserAddCar';
import UserEditCar from './User/UserEditCar';
import UserListReserve from './User/UserListReserve';


// Import For Company Panel
import CompanyAddAgent from './Company/CompanyAddAgent';
import CompanyListAgent from './Company/CompanyListAgent';
import CompanyEditAgent from './Company/CompanyEditAgent';

// // Import For Admin Panel
import AdminListHotel from './Admin/AdminListHotel';
import AdminEditHotel from './Admin/AdminEditHotel';
import AdminAddHotel from './Admin/AdminAddHotel';
import AdminListSuite from './Admin/AdminListSuite';
import AdminListReserve from './Admin/AdminListReserve';
import AdminListBuy from './Admin/AdminListBuy';
import AdminListRent from './Admin/AdminListRent';
import AdminListCommercials from './Admin/AdminListCommercials';
import AdminListCompany from './Admin/AdminListCompany';
import AdminListAgent from './Admin/AdminListAgent';
import AdminListCar from './Admin/AdminListCar';
import AdminEditCar from './Admin/AdminEditCar';
import AdminEditProperty from './Admin/AdminEditProperty';
import AdminListUser from './Admin/AdminListUser';
import AdminDashboard from './Admin/AdminDashboard';




function Panel(props) {
  const[displayNav , setDisplayNav] = useState(true) ;
  const[displayNavMobile , setDisplayNavMobile] = useState(false) ;

  const changeDisplayNav=()=>{
      if(displayNav){
          setDisplayNav(false)
      }else{
          setDisplayNav(true)
      }
  }

  const changeDisplayMobileNav=()=>{
      if(displayNavMobile){
          setDisplayNavMobile(false)
      }else{
          setDisplayNavMobile(true)
      }
  }
    
    return (
      <div className="main">
            <div className={`cont ${!displayNav ? 'contWidth' : null }`}>
                <ContHeadPanel
                    changeDisplayNav = {changeDisplayNav}
                    changeDisplayMobileNav = {changeDisplayMobileNav}
                />
                <div className="contMain">
                    <div className="contMainPage">
                        {props.children}

                        {/* Route For Agent */}
                        <PrivateRouteAgent exact path='/panel/agent/agentlistproperty' component={AgentListProperty} />
                        <PrivateRouteAgent exact path='/panel/agent/agentaddproperty' component={AgentAddProperty} />
                        <PrivateRouteAgent exact path='/panel/agent/editproperty/:id' component={AgentEditProperty} />
                        <PrivateRouteAgent exact path='/panel/agent/edit-profile' component={AgentEditProfile} />

                        

                        {/* Route For Company */}
                        <PrivateRouteCompany exact path='/panel/company/listagent' component={CompanyListAgent} />
                        <PrivateRouteCompany exact path='/panel/company/addagent' component={CompanyAddAgent} />
                        <PrivateRouteCompany exact path='/panel/company/editagent/:email' component={CompanyEditAgent} />

                        {/* Rout For Admin */}

                        <PrivateRouteAdmin exact path='/panel/admin/dashboard' component={AdminDashboard} />
                        <PrivateRouteAdmin exact path='/panel/admin/listhotel' component={AdminListHotel} />
                        <PrivateRouteAdmin exact path='/panel/admin/edithotel/:id' component={AdminEditHotel} />
                        <PrivateRouteAdmin exact path='/panel/admin/addhotel' component={AdminAddHotel} />
                        <PrivateRouteAdmin exact path='/panel/admin/listsuite' component={AdminListSuite} />
                        <PrivateRouteAdmin exact path='/panel/admin/listreserve' component={AdminListReserve} />
                        <PrivateRouteAdmin exact path='/panel/admin/listbuy' component={AdminListBuy} />
                        <PrivateRouteAdmin exact path='/panel/admin/listrent' component={AdminListRent} />
                        <PrivateRouteAdmin exact path='/panel/admin/listcommericials' component={AdminListCommercials} />
                        <PrivateRouteAdmin exact path='/panel/admin/listcompany' component={AdminListCompany} />
                        <PrivateRouteAdmin exact path='/panel/admin/listagent' component={AdminListAgent} />
                        <PrivateRouteAdmin exact path='/panel/admin/listcar' component={AdminListCar} />
                        <PrivateRouteAdmin exact path='/panel/admin/listuser' component={AdminListUser} />
                        <PrivateRouteAdmin exact path='/panel/admin/admineditcar/:id' component={AdminEditCar} />
                        <PrivateRouteAdmin exact path='/panel/admin/admineditproperty/:id' component={AdminEditProperty} />


                        {/* Rout For User */}
                        <PrivateRouteUser exact path='/panel/user/addcar' component={UserAddCar} />
                        <PrivateRouteUser exact path='/panel/user/listcar' component={UserListCar} />
                        <PrivateRouteUser exact path='/panel/user/editcar/:id' component={UserEditCar} />
                        <PrivateRouteUser exact path='/panel/user/listReserve' component={UserListReserve} />
                    </div>
                </div>
            </div>
            <NavPanel 
                drower={displayNav} 
                drowerMobile={displayNavMobile} 
                changedrowerMobile = {changeDisplayMobileNav}
            />
            
        </div>
                    
    );
}


export default Panel;
