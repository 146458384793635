import React from 'react';
import { Row } from 'antd';
import axios from 'axios';
import { Form, Input, InputNumber, Button , Select  } from 'antd';
import Loader2 from '../../widget/Loader2';
import BASE_URL from '../../../BASE_URL';
import PANEL_BASE_URL from '../../../PANEL_BASE_URL';



class UserEditCar extends React.Component {

  state = {
    processing:false,
    detail_data : [],
  }

   onFinish = values => {
    
    // if(values.door === undefined) {values.door = this.state.detail_data.door;}
    // if(values.seat === undefined) {values.seat = this.state.detail_data.seat;}
    // if(values.power === undefined) {values.power = this.state.detail_data.power;}
    // if(values.transmission === undefined) {values.transmission = this.state.detail_data.transmission;}
    // if(values.odometer === undefined) {values.odometer = this.state.detail_data.odometer;}
    // if(values.year === undefined) {values.year = this.state.detail_data.year;}
    // if(values.company === undefined) {values.company = this.state.detail_data.company;}
    // if(values.model === undefined) {values.model = this.state.detail_data.model;}
    // if(values.color === undefined) {values.color = this.state.detail_data.color;}
    // if(values.age_limit === undefined) {values.age_limit = this.state.detail_data.age_limit;}
    // if(values.insurance === undefined) {values.insurance = this.state.detail_data.insurance;}
    // if(values.cost === undefined) {values.cost = this.state.detail_data.cost;}
    // if(values.limit_distance === undefined) {values.limit_distance = this.state.detail_data.limit_distance;}
    // if(values.extra_distance_charge === undefined) {values.extra_distance_charge = this.state.detail_data.extra_distance_charge;}
    // if(values.description === undefined) {values.description = this.state.detail_data.description;}
    // if(values.address === undefined) {values.address = this.state.detail_data.address;}
    // // this.setState({ processing : true});
    const postdata = {
      "token":localStorage.getItem("usertoken"),
      "id":`${this.props.match.params.id}`,
      "door":values.door,
      "seat":values.seat,
      "power":values.power,
      "transmission":values.transmission,
      "odometer":values.odometer,
      "year":values.year,
      "company":values.company,
      "model":values.model,
      "color":values.color,
      "age_limit":values.age_limit,
      "insurance":values.insurance,
      "cost":values.cost,
      "phone":values.phone,
      "isAvailable":values.available ,
      "limit_distance":values.limit_distance,
      "extra_distance_charge":values.extra_distance_charge,
      "description":values.description,
      "address":values.address,
    }
    this.setState({processing : true});
    axios.post(PANEL_BASE_URL + `/updateCar`,  postdata )
    .then((res) => {
      if(res.data.result === 'ok'){
        this.setState({
          processing : false,
        });  
        return window.location.href = '/panel/user/listcar'
      }
      else if (res.data.error === "unauthenticated"){
        localStorage.clear(); 
        return window.location.href = '/login'
      } 
    })
  };


  formRef = React.createRef();

  componentDidMount(){
    this.setState({ processing : true});
    const postdata = {
      "id":`${this.props.match.params.id}`,
    }
    axios.post(BASE_URL + `/getCarInfo`,  postdata )
    .then((res) => {
      if(res.data.result === 'ok'){
        this.setState({
          detail_data : res.data.data ,
          processing : false,
        });  
        this.formRef.current.setFieldsValue(this.state.detail_data);
      }
      else if (res.data.error === "unauthenticated"){
        localStorage.clear(); 
        return window.location.href = '/login'
      } 
    })
    
  }  
 
  render() {
    const layout = {
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
    };
    
    return (
      <>
        {this.state.processing ? <Loader2/> : null}
        <Form ref={this.formRef} {...layout} name="nest-messages" onFinish={this.onFinish} >
          <Form.Item name='door' label="door" >
            <Select>
              <Select.Option value="2">2</Select.Option>
              <Select.Option value="3">3</Select.Option>
              <Select.Option value="4">4</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='seat' label="seat" >
            <Select>
              <Select.Option value="2">2</Select.Option>
              <Select.Option value="4">4</Select.Option>
              <Select.Option value="6">6</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='power' label="power" >
            <InputNumber />
          </Form.Item>
          <Form.Item name='transmission' label="transmission" >
            <Select>
              <Select.Option value="1">Yes</Select.Option>
              <Select.Option value="0">No</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='available' label="available" >
            <Select>
              <Select.Option value={1}>Yes</Select.Option>
              <Select.Option value={0}>No</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='odometer' label="odometer" >
            <InputNumber />
          </Form.Item>
          <Form.Item name='year'label="year" >
            <InputNumber />
          </Form.Item>
          <Form.Item name='company' label="company" >
            <Input />
          </Form.Item>
          <Form.Item name='model' label="model" >
            <InputNumber />
          </Form.Item>
          <Form.Item name='color' label="color" >
            <Input />
          </Form.Item>
          <Form.Item name='age_limit' label="age limit" >
            <InputNumber />
          </Form.Item>
          <Form.Item name='insurance' label="insurance" >
            <Select>
              <Select.Option value="1">Yes</Select.Option>
              <Select.Option value="0">No</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='cost' label="cost" >
            <InputNumber />
          </Form.Item>
          <Form.Item name='description'label="description" >
            <Input.TextArea />
          </Form.Item>
          <Form.Item name='phone'label="phone" >
            <InputNumber />
          </Form.Item>
          <Form.Item name='address'label="address">
            <Input.TextArea />
          </Form.Item>
          <Form.Item name='limit_distance' label="limit distance" >
            <InputNumber />
          </Form.Item>
          <Form.Item name='extra_distance_charge' label="extra distance charge" >
            <InputNumber />
          </Form.Item>
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
      </Form>
      </>
     
    );
  }
}


export default UserEditCar;
