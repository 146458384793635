import React from 'react';
import { Carousel  } from 'antd';
import Img1 from '../img/img1.jpg';
import Img2 from '../img/img2.jpg';


class Slider extends React.Component {


  render() {
    return (
     <div className="slider">
        <Carousel autoplay>
          <div>
            <img src={Img1} alt="slider"></img>
          </div>
          <div>
            <img src={Img2} alt="slider"></img>
          </div>
        </Carousel>
     </div>
    );
  }
}

export default Slider;
