import React from 'react';
import { Form, Input, Button } from 'antd';
import { Row } from 'antd';
import { Table, Tag, Space } from 'antd';
import { Descriptions } from 'antd';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  EditOutlined,
  DeleteOutlined  
} from '@ant-design/icons';
import { Modal  } from 'antd';
import Loader2 from '../../widget/Loader2';
import PANEL_BASE_URL from '../../../PANEL_BASE_URL';


class CompanyListAgent extends React.Component {

  state = {
    visible: false,
    visible_detail_modal:false,
    list_data: [],
    processing: false ,
    delete_id :" ",
    delete_name :" " ,
    detail_data:"",
  }

    // this function for show destail Modal;
    showdetailmodal = (email) => {
      this.setState({
        visible_detail_modal:true,
        processing:true,
      })
      const postdata = {
        "token":localStorage.getItem("companytoken"),
        "email":email
      }
      axios.post(PANEL_BASE_URL + `/selectAgent`,  postdata )
      .then((res) => {
        if(res.data.result === 'ok'){
          this.setState({
            detail_data : res.data.data ,
            processing : false,
          });  
        }
        else if (res.data.error === "unauthenticated"){
          this.setState({processing:false,})
          localStorage.clear(); 
          return window.location.href = '/login'
        }
      })
    }

  showModal = (email , name) => {
    this.setState({
      visible: true,
      delete_id: email,
      delete_name: name,
    });
  };

  handleOk = () => {
    this.setState({ processing : true});
    const postdata = {
      "id":this.state.delete_id,
      "token":localStorage.getItem("companytoken"),
    }
    axios.post(PANEL_BASE_URL + `/deleteAgent`,  postdata )
    .then((res) => {
      if(res.data.result === "ok"){
        this.setState({ processing : false});
        this.componentDidMount();
      }
      else if (res.data.error === "unauthenticated"){
        this.setState({ processing : false});
        localStorage.clear(); 
        return window.location.href = '/login'
      }
    })
    this.setState({
      visible: false,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      visible_detail_modal:false,
    });
  };

  componentDidMount(){
    this.setState({ processing : true});
    const postdata = {
      "token":localStorage.getItem("companytoken"),
    };
    axios.post(PANEL_BASE_URL + `/list_agent`,  postdata )
    .then((res) => {
      if(res.data.result === 'ok'){
        this.setState({
          list_data : res.data.data ,
          processing : false,
        });  
      }
      else if (res.data.error === "unauthenticated"){
        localStorage.clear(); 
        return window.location.href = '/login'
      }
      
    })
    
  }  
 
  render() {
  
    const columns = [
      {
        title: 'email',
        dataIndex: 'email',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        render: (text , row) => <span className="title-list-name" onClick={() => this.showdetailmodal(row.email)}>{text}</span>,
      },
      {
        title: 'country',
        dataIndex: 'country',
      },
      {
        title: 'edit',
        render:  row => <Link to={`/panel/company/editagent/${row.email}`}><EditOutlined /></Link>,
      },
      {
        title: 'delete',
        render:  row => <DeleteOutlined onClick={() => this.showModal(row.email , row.name)} />,
      },
    ]

    return (
      <>
      {this.state.processing ? <Loader2/> : null}
      <Modal
          title="Delete"
          visible={this.state.visible}
          onOk={() => this.handleOk()}
          onCancel={this.handleCancel}
        >    
        <p>Do you want to delete the " {this.state.delete_name} " ?</p>
      </Modal>
            {/* this modal for show detail */}
        <Modal
          title="Detail"
          visible={this.state.visible_detail_modal}
          onCancel={this.handleCancel}
          footer={null}
          className="detail-property-modal"
          width={1000}
        >    
        <div>
          <Descriptions
            bordered
            // column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
          >
            <Descriptions.Item label="Name" span={2}>{this.state.detail_data.name}</Descriptions.Item>
            <Descriptions.Item label="Country" span={1}>{this.state.detail_data.country}</Descriptions.Item>
            <Descriptions.Item label="Email" span={2}>{this.state.detail_data.email}</Descriptions.Item>
            <Descriptions.Item label="Languages" span={1}>{this.state.detail_data.languages}</Descriptions.Item>
          </Descriptions>
        </div>
      </Modal>
      <Table columns={columns} dataSource={this.state.list_data} />
      </>
     
    );
  }
}


export default CompanyListAgent;
