import React from 'react';
import { Tabs } from 'antd';

import {CheckOutlined } from '@ant-design/icons';



class TabDetailCar extends React.Component {


  render() {
    const { TabPane } = Tabs;
    return (
     <div className="detail-tab">
         <Tabs defaultActiveKey="1" >
          <TabPane tab="Condition" key="1">
            <h3>Condition</h3>
            <div className="detail-tab-item-panel">
             <div className="detail-tab-item-panel-item"><CheckOutlined />   Model : {this.props.detail.model}</div> 
             <div className="detail-tab-item-panel-item"><CheckOutlined />    Door : {this.props.detail.door}</div> 
             <div className="detail-tab-item-panel-item"><CheckOutlined />    Seat : {this.props.detail.seat}</div> 
             <div className="detail-tab-item-panel-item"><CheckOutlined />    Power : {this.props.detail.power}</div> 
             <div className="detail-tab-item-panel-item"><CheckOutlined />    Odometer : {this.props.detail.odometer}</div> 
             <div className="detail-tab-item-panel-item"><CheckOutlined />    Year : {this.props.detail.year}</div> 
             <div className="detail-tab-item-panel-item"><CheckOutlined />    Company : {this.props.detail.company}</div> 
             <div className="detail-tab-item-panel-item"><CheckOutlined />    Trim : {this.props.detail.trim}</div> 
             <div className="detail-tab-item-panel-item"><CheckOutlined />    Color : {this.props.detail.color}</div> 
             <div className="detail-tab-item-panel-item"><CheckOutlined />    Age : {this.props.detail.age_limit}</div> 
            </div>
          </TabPane>
          <TabPane tab="Description" key="2">
            <h3>Description</h3>
            <p>
              {this.props.detail.description}
            </p>
          </TabPane>
        </Tabs>
     </div>
    );
  }
}

export default TabDetailCar;
