import React from 'react';
import DashOne from '../DashOne';
import DashTwo from '../DashTwo';
import DashThree from '../DashThree';



class AdminDashboard extends React.Component {

 


 
  render() {
    
    return (
      <div className="dashboard">
        <DashTwo/>
        <DashOne/>
        <DashThree/>
      </div>
    );
  }
}


export default AdminDashboard;
