import React from 'react';
import { Tabs } from 'antd';
import Img4 from '../img/img4.jpg';
import {AreaChartOutlined   , StarOutlined , CheckOutlined , CloseOutlined } from '@ant-design/icons';
import axios from 'axios';
import BASE_URL from '../../BASE_URL';
import { Link} from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import LoaderImage from './LoaderImage';
import Loader3 from './Loader3';
import Flip from 'react-reveal/Flip';

class ProductSection extends React.Component {

  state = {
    commercial : [],
    rent : [],
    buy : [],
    hotel : [],
    car : [],
    imageLoader : false ,
    mainLoader : false ,
  }



  componentDidMount(){
    this.setState({mainLoader : true})
    axios.post(BASE_URL + '/main_page' )
    .then((res) => {
      if(res.status === 200){
        this.setState({
        commercial: res.data.data.commercial ,
        rent: res.data.data.rent ,
        buy: res.data.data.buy ,
        hotel: res.data.data.hotels,
        car: res.data.data.car ,
        mainLoader : false ,
        });
      }
      else {
        alert("Error");
      }
    })
    .catch((e)=>{
      console.log(e)
    })
  }


  render() {
    
    const { TabPane } = Tabs;
    return (
     <div className="product-section">
       <div className="product-section-head"><h3 >Featured Properties</h3></div>
       <Tabs defaultActiveKey="1">
         {/* {this.state.mainLoader && <Loader3/>} */}
        <TabPane tab="Rent" key="1">
          { this.state.rent.map(item =>
            <Flip left>
              <div className="product-section-card">
                <div className="product-section-card-init">
                  <Link to={`/detail/${item.id}`}>
                    {item.image != null ? 
                    <img  onLoadStartCapture={()=>{this.setState({imageLoader : true})}} onLoad={()=>{this.setState({imageLoader : false})}}  src={`https://downloadforever.ir/saeed/RealEstate/public/images/${item.image.image}`} alt="card"></img>
                    :
                    null
                    }
                    {/* <LazyLoadImage afterLoad={()=>{this.setState({imageLoader : false})}} beforeLoad={()=>{this.setState({imageLoader:true})}} src={`https://downloadforever.ir/saeed/RealEstate/public/images/${item.image}`} alt="card"/> */}
                    {this.state.imageLoader ? <LoaderImage/> : null }
                  </Link>
                  <div className="product-section-card-init-des">
                    <div className="product-section-card-init-des-one">
                      <Link to={`/detail/${item.id}`}><h3>{item.name}</h3></Link>
                    </div>
                    <div className="product-section-card-init-des-two">
                      <div className="product-section-card-init-des-two-item" style={{borderRight:"1px solid #e0e0e0"}}>
                        <AreaChartOutlined /> 
                        <span>{item.bathroom} </span>
                        <span>Bath</span>
                      </div>
                      <div className="product-section-card-init-des-two-item" style={{borderRight:"1px solid #e0e0e0"}}>
                          <AreaChartOutlined />
                        <span>{item.bedroom}</span>
                        <span> Bed </span>
                      </div>
                      <div className="product-section-card-init-des-two-item">
                        <AreaChartOutlined />
                        <span>{item.size} </span>
                        <span>Size </span>
                      </div>
                    </div> 
                  </div>
                </div>
              </div>
            </Flip>
          )}  
        </TabPane>
{/*         
        <TabPane tab="Buy" key="2">
          { this.state.buy.map(item =>
            <Flip left>
              <div className="product-section-card">
                <div className="product-section-card-init">
                  <Link to={`/detail/${item.id}`}>
                    {item.image != null ? 
                    <img  onLoadStartCapture={()=>{this.setState({imageLoader : true})}} onLoad={()=>{this.setState({imageLoader : false})}}  src={`https://downloadforever.ir/saeed/RealEstate/public/images/${item.image.image}`} alt="card"></img>
                    :
                    null
                    }
                    {this.state.imageLoader ? <LoaderImage/> : null }
                  </Link>
                  <div className="product-section-card-init-des">
                    <div className="product-section-card-init-des-one">
                      <Link to={`/detail/${item.id}`}><h3>{item.name}</h3></Link>
                    </div>
                    <div className="product-section-card-init-des-two">
                      <div className="product-section-card-init-des-two-item" style={{borderRight:"1px solid #e0e0e0"}}>
                        <AreaChartOutlined /> 
                        <span>{item.bathroom} </span>
                        <span>Bath</span>
                      </div>
                      <div className="product-section-card-init-des-two-item" style={{borderRight:"1px solid #e0e0e0"}}>
                        <AreaChartOutlined /> 
                        <span>{item.bedroom} </span>
                        <span>Bed</span>
                      </div>
                      <div className="product-section-card-init-des-two-item">
                        <AreaChartOutlined />
                        <span>{item.size} </span>
                        <span>Size </span>
                      </div>
                    </div> 
                  </div>
                </div>
              </div>
            </Flip>
          )}
        </TabPane>
         */}

        <TabPane tab="Commercial" key="3">
          { this.state.commercial.map(item =>
            <Flip left>
              <div className="product-section-card">
                <div className="product-section-card-init">
                  <Link to={`/detail/${item.id}`}>
                    {item.image != null ? 
                    <img  onLoadStartCapture={()=>{this.setState({imageLoader : true})}} onLoad={()=>{this.setState({imageLoader : false})}}  src={`https://downloadforever.ir/saeed/RealEstate/public/images/${item.image.image}`} alt="card"></img>
                    :
                    null
                    }
                    {/* <LazyLoadImage afterLoad={()=>{this.setState({imageLoader : false})}} beforeLoad={()=>{this.setState({imageLoader:true})}}  src={`https://downloadforever.ir/saeed/RealEstate/public/images/${item.image}`} alt="card"/> */}
                    {this.state.imageLoader ? <LoaderImage/> : null }
                  </Link>
                  <div className="product-section-card-init-des">
                    <div className="product-section-card-init-des-one">
                      <Link to={`/detail/${item.id}`}><h3>{item.name}</h3></Link>
                    </div>
                    <div className="product-section-card-init-des-two">
                      <div className="product-section-card-init-des-two-item" style={{borderRight:"1px solid #e0e0e0"}}>
                        <AreaChartOutlined /> 
                        <span>{item.bathroom}</span>
                        <span> Bath</span>
                      </div>
                      <div className="product-section-card-init-des-two-item" style={{borderRight:"1px solid #e0e0e0"}}>
                        <AreaChartOutlined /> 
                        <span>{item.bedroom} </span>
                        <span>Bed</span>
                      </div>
                      <div className="product-section-card-init-des-two-item">
                        <AreaChartOutlined />
                        <span>{item.size}</span>
                        <span> Size </span>
                      </div>
                    </div> 
                  </div>
                </div>
              </div>
            </Flip>
          )}
        </TabPane>
        <TabPane tab="Hotel" key="4">
          { this.state.hotel.map(item =>
            <Flip left>
              <div className="product-section-card">
                <div className="product-section-card-init">
                  <Link to={`/detailhotel/${item.id}`}>
                    {item.image != null ? 
                    <img  onLoadStartCapture={()=>{this.setState({imageLoader : true})}} onLoad={()=>{this.setState({imageLoader : false})}}  src={`https://downloadforever.ir/saeed/RealEstate/public/images/${item.image.image}`} alt="card"></img>
                    :
                    null
                    }  
                    {/* <LazyLoadImage afterLoad={()=>{this.setState({imageLoader : false})}} beforeLoad={()=>{this.setState({imageLoader:true})}}  src={`https://downloadforever.ir/saeed/RealEstate/public/images/${item.image}`} alt="card"/> */}
                    {this.state.imageLoader ? <LoaderImage/> : null }
                  </Link>
                  <div className="product-section-card-init-des">
                    <div className="product-section-card-init-des-one">
                      <Link to={`/detailhotel/${item.id}`}><h3>{item.name}</h3></Link>
                    </div>
                    <div className="product-section-card-init-des-two">
                      <div className="product-section-card-init-des-two-item" style={{borderRight:"1px solid #e0e0e0"}}>
                        <StarOutlined /> 
                        <span>{item.level} </span> 
                        <span>Star </span>
                      </div>
                      <div className="product-section-card-init-des-two-item" style={{borderRight:"1px solid #e0e0e0"}}>
                        {item.cafe == 1 ? <CheckOutlined className="product-section-card-init-des-two-item-icon-hotel" /> : <CloseOutlined className="product-section-card-init-des-two-item-icon-hotel" />}
                        <span>cafe</span>
                      </div>
                      <div className="product-section-card-init-des-two-item">
                        {item.pool == 1 ? <CheckOutlined className="product-section-card-init-des-two-item-icon-hotel" /> : <CloseOutlined className="product-section-card-init-des-two-item-icon-hotel" />}
                        <span> Pool </span>
                      </div>
                    </div> 
                  </div>
                </div>
              </div>
            </Flip>
          )}  
        </TabPane>
        <TabPane tab="Car" key="5">
          { this.state.car.map(item =>
            <Flip left>
              <div className="product-section-card">
                <div className="product-section-card-init">
                  <Link to={`/detailcar/${item.id}`}>
                    {item.image != null ? 
                    <img  onLoadStartCapture={()=>{this.setState({imageLoader : true})}} onLoad={()=>{this.setState({imageLoader : false})}}  src={`https://downloadforever.ir/saeed/RealEstate/public/images/${item.image.image}`} alt="card"></img>
                    :
                    null
                    }
                    {/* <LazyLoadImage afterLoad={()=>{this.setState({imageLoader : false})}} beforeLoad={()=>{this.setState({imageLoader:true})}}  src={`https://downloadforever.ir/saeed/RealEstate/public/images/${item.image}`} alt="card"/> */}
                    {this.state.imageLoader ? <LoaderImage/> : null }
                  </Link>
                  <div className="product-section-card-init-des">
                    <div className="product-section-card-init-des-one">
                      <Link to={`/detailcar/${item.id}`}><h3>{item.company}</h3></Link>
                    </div>
                    <div className="product-section-card-init-des-two">
                      <div className="product-section-card-init-des-two-item" style={{borderRight:"1px solid #e0e0e0"}}>
                        <AreaChartOutlined /> 
                        <span>model </span>
                        <span>{item.model}</span>
                      </div>
                      <div className="product-section-card-init-des-two-item" style={{borderRight:"1px solid #e0e0e0"}}>
                        <AreaChartOutlined />
                        <span>year </span>
                        <span>{item.year}</span>
                        </div>
                      {/* <div className="product-section-card-init-des-two-item"><AreaChartOutlined /> 5</div> */}
                    </div> 
                  </div>
                </div>
              </div>
            </Flip>
          )}
        </TabPane>
      </Tabs>
     </div>
    );
  }
}

export default ProductSection;
