import React from 'react';
import { PhoneOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import Filter from './Filter';



class HeadList extends React.Component {


  render() {
 

    return (
     <div className="headlist">
       <Filter classdata="filter filter-list"/>
     </div>
    );
  }
}

export default HeadList;
