import React from 'react';
import Head from '../widget/Head';
import HeadList from '../widget/HeadList';
import Footer from '../widget/Footer';
import ListCarPage from '../widget/ListCarPage';




class ListHotel extends React.Component {


  render() {


    return (
      <div className="main">
        <Head/>
        <HeadList/>
        <ListCarPage/>
        <Footer/>
      </div>
    );
  }
}

export default ListHotel;
