import React from 'react';
import { Form, Input, Button ,Select , Modal } from 'antd';
import BASE_URL from '../../../BASE_URL';
import axios from 'axios';
import { UserOutlined , KeyOutlined , UserAddOutlined , PhoneOutlined  } from '@ant-design/icons';
// import logologin from '../../img/logo-login.png'
import { Link} from 'react-router-dom';
import Loader2 from '../../widget/Loader2';
import Logo from '../../img/logo2.png';


class Register extends React.Component {

  state = {
    processing : false ,
    visible: false,
    modal_cont : "",
  }

  onFinish = values => {
    this.setState({processing: true});
    const postdata = {
      "email":values.email ,
      "password":values.password,
      "name":values.name,
      "phoneNumber":values.phone,
    }
    axios.post(BASE_URL + `/create_user`,  postdata )
    .then((res) => {
      console.log(res)
      if(res.data.result === 'ok'){
        this.setState({
          modal_cont : "Register Success",
          processing : false,
          visible:true,
        });  

      }
      else {
        this.setState({
          modal_cont : "Emial Exist.Choose Another Email",
          processing : false,
          visible:true,
        });
      }
    })
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };
 
  render() {

    return (
      <div className="login-amaz">
        {this.state.processing ? <Loader2/> : null}
        <Modal
          title="Register"
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
        >
          {this.state.modal_cont}
        </Modal> 
        <div className="login-amaz-main">
          <div className="login-amaz-head">
          <Link to="/login"><div className="login-amaz-head-button ">Login</div></Link>
            <div className="login-amaz-head-button login-amaz-head-button-active">Register</div>
            <Link to="/companyregister"><div className="login-amaz-head-button">Company</div></Link>
          </div>
          <div className="login-amaz-cont">
            <Link to="/"><img src={Logo} alt="logo"></img></Link>
            <h5>Hello Everyone , Welcome to RealEstate</h5>
            <h4>Register</h4>
            <Form
              name="loginAmaz"
              onFinish={this.onFinish}
            >
              <p style={{fontWeight:"bold"}}>Name</p>
              <Form.Item 
                name="name"  
                rules={[{ required: true, message: 'Please input your Name!' }]}
              >
                <Input placeholder="Full Name" prefix={<UserAddOutlined  />}/>
              </Form.Item>
              <p style={{fontWeight:"bold"}}>Email Address</p>
              <Form.Item 
                name="email"  
                rules={[{ required: true, message: 'Please input your Email!' , type: 'email' }]}
              >
                <Input placeholder="Email" prefix={<UserOutlined />}/>
              </Form.Item>
              <p style={{fontWeight:"bold"}}>Phone</p>
              <Form.Item 
                name="phone"  
                rules={[{ required: true, message: 'Please input your Phone!' }]}
              >
                <Input placeholder="Phone Number" prefix={<PhoneOutlined  />}/>
              </Form.Item>
              <p style={{fontWeight:"bold"}}>Password</p>
              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
              >
                <Input.Password placeholder="Password" prefix={<KeyOutlined />}/>
              </Form.Item>
              <Form.Item >
                <Button className="login-btn-panels" type="primary" htmlType="submit">
                  Register
                </Button>
              </Form.Item>
            </Form>  
          </div>
        </div>
      </div>
    );
  }
}


export default Register;
