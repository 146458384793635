import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import brand1 from '../img/brand1.png';
import brand2 from '../img/brand2.png';
import brand3 from '../img/brand3.png';
import brand4 from '../img/brand4.png';


class BrandSection extends React.Component {

  state = {
    options : {
      loop: true,
      margin: 10,
      nav:true,
      responsive:{
          0: {
              items: 1,
          },
          600: {
              items: 3,
          },
          1000: {
              items: 4,
          },
        },
    }
  }   

  render() {
    return (
     <div className="brand-section">
      <div className="brand-section-init">
        <div className="brand-section-init-head"><h3>Brands & Partners</h3></div>
        <div className="brand-section-init-cont">
          <OwlCarousel className='owl-theme' loop margin={10} {...this.state.options} >
            <div class='item'>
                <img src={brand1} alt="brand"></img>
            </div>
            <div class='item'>
                <img src={brand2} alt="brand"></img>
            </div>
            <div class='item'>
               <img src={brand3} alt="brand"></img>
            </div>
            <div class='item'>
                <img src={brand4} alt="brand"></img>
            </div>
          </OwlCarousel>
        </div>  
      </div>  
     </div>
    );
  }
}

export default BrandSection;
