import React from 'react';


class LoaderImage extends React.Component {


  render() {


    return (
      <div className="loaderImage">
        <div class="loaderImageInner">
          <span class="loaderImageInnerCircle"></span>
          <span class="loaderImageInnerCircle"></span>
          <span class="loaderImageInnerCircle"></span>
          <span class="loaderImageInnerCircle"></span>
        </div>
      </div>
    );
  }
}

export default LoaderImage;
