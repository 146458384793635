import React from 'react';



class Privacy extends React.Component {


  render() {


    return (
      <div className="privacy">

        <h1>Privacy Policy</h1>

        <p><strong><h3>1. DEFINITIONS</h3></strong></p>
        <span>Undefined terms in this Privacy Policy have the same definition as in our terms of service (“Terms”).</span>

        <p><strong><h3>2. PERSONAL INFORMATION WE COLLECT</h3></strong></p>

        <p><strong><h4>2.1 Information needed to use the Hala Homes Platform.</h4></strong></p>
        <span>We collect personal information about you when you use the Hala Homes Platform. Without it, we may not be able to provide you with all services requested. This information includes:</span>
        <span>Contact Information, Account, Profile Information. Such as your first name, last name, phone number, postal address, email address, date of birth, and profile photo, some of which will depend on the features you use.</span>
        <span>Identity Verification and Payment Information. Such as images of your government issued ID (as permitted by applicable laws), your ID number or other verification information, bank account or payment account information.</span>

        <p><strong><h4>2.2 Information you choose to give us.</h4></strong></p>
        <span>You can choose to provide us with additional personal information. This information may include:</span>
        <span>Additional Profile Information. Such as gender, preferred language(s), city, and personal description. Some of this information as indicated in your account settings is part of your public profile page and will be publicly visible.</span>
        <span>Address Book Contact Information. Address book contacts you import or enter manually.</span>
        <span>Other Information. Such as when you fill in a form, add information to your account, respond to surveys, post to community forums, participate in promotions, communicate with our customer care team and other Members, or share your experience with us. This may include health information if you choose to share it with us.</span>

        <p><strong><h4>2.3 Information Automatically Collected by Using the Hala Homes Platform and our Payment Services.</h4></strong></p>
        <span>When you use the Hala Homes Platform and Payment Services, we automatically collect personal information. This information may include:</span>
        <span>Geo-location Information. Such as precise or approximate location determined from your IP address or mobile device’s GPS depending on your device settings. We may also collect this information when you’re not using the app if you enable this through your settings or device permissions.</span>
        <span>Usage Information. Such as the pages or content you view, searches for Listings, bookings you have made, and other actions on the Hala Homes Platform.</span>
        <span>Log Data and Device Information. Such as details about how you’ve used the Hala Homes Platform (including if you clicked on links to third party applications), IP address, access dates and times, hardware and software information, device information, device event information, unique identifiers, crash data, cookie data, and the pages you’ve viewed or engaged with before or after using the Hala Homes Platform. We may collect this information even if you haven’t created an Hala Homes account or logged in.</span>

        <p><strong><h4>2.4 Personal Information We Collect from Third Parties.</h4></strong></p>
        <span>We collect personal information from other sources, such as:</span>
        <span>Third-Party Services. If you link, connect, or login to the Hala Homes Platform with a third party service (e.g. Google, Facebook, WeChat), you direct the service to send us information such as your registration, friends list, and profile information as controlled by that service or as authorized by you via your privacy settings at that service.</span>
        <span>Background Information. For Members in the United States, to the extent permitted by applicable laws, we may obtain reports from public records of criminal convictions or sex offender registrations. For Members outside of the United States, to the extent permitted by applicable laws and with your consent where required, we may obtain the local version of police, background or registered sex offender checks. We may use your information, including your full name and date of birth, to obtain such reports.</span>
        <span>Enterprise Product Invitations and Account Management. Organizations that use our Enterprise products may submit personal information to facilitate account management and invitations to use enterprise products.</span>
        <span>Referrals and co-travelers. If you are invited to the Hala Homes Platform such as a co-traveler on a trip, the person who invited you can submit personal information about you such as your email address or other contact information.</span>
        <span>Other Sources. To the extent permitted by applicable law, we may receive additional information about you, such as references, demographic data or information to help detect fraud and safety issues from third party service providers and/or partners, and combine it with information we have about you. For example, we may receive background check results or fraud warnings from identity verification service providers for use in our fraud prevention and risk assessment efforts. We may receive information about you and your activities on and off the Hala Homes Platform, or about your experiences and interactions from our partners. We may receive health information, including but not limited to health information related to contagious diseases.</span>

        <p><strong><h3>3. HOW WE USE INFORMATION WE COLLECT</h3></strong></p>

        <p><strong><h4>3.1 Provide, Improve, and Develop the Hala Homes Platform. We use personal information to:</h4></strong></p>
        <span>enable you to access the Hala Homes Platform and make and receive payments,enable you to communicate with other Members,perform analytics, debug and conduct research,provide customer service,send you messages, updates, security alerts, and account notifications,if you provide us with your contacts’ information such as your friends or co-travellers, we may process this information: (i) to facilitate your referral invitations, (ii) to share your trip details and facilitate trip planning, (iii) for fraud detection and prevention, and (iv) to facilitate your requests or for any other purpose you authorize,personalize and customize your experience based on your interactions with the Hala Homes Platform, your search and booking history, your profile information and preferences, and other content you submit, andenable your use of our enterprise products.</span>
        
        <p><strong><h4>3.2 Create and Maintain a Trusted and Safer Environment. We use personal information to:</h4></strong></p>
        <span>detect and prevent fraud, spam, abuse, security and safety incidents, and other harmful activity,study and combat discrimination conduct security investigations and risk assessments,verify or authenticate information provided by you,conduct checks against databases and other information sources, including background or police checks,comply with our legal obligations, protect the health and well-being of our Guests, Hosts, Hosts’ employees and members of the public,resolve disputes with our Members,enforce our agreements with third parties,comply with law, respond to legal requests, prevent harm and protect our rights (see section 4.5)</span>
        <span>enforce our terms and other policies, and in connection with the activities above, we may conduct profiling based on your interactions with the Hala Homes Platform, your profile information and other content you submit to Hala Homes, and information obtained from third parties. In limited cases, automated processes could restrict or suspend access to the Hala Homes Platform if such processes detect activity that we think poses a safety or other risk to Hala Homes, our community, or third parties. If you would like to challenge the decisioning based on the automated process, please contact us via the Contact Information section below.</span>

        <p><strong><h4>3.3 Provide, Personalize, Measure, and Improve our Advertising and Marketing. We may use personal information to:</h4></strong></p>
        <span>send you promotional messages, marketing, advertising, and other information based on your preferences and social media advertising through social media platforms, personalize, measure, and improve our advertising,administer referral programs, rewards, surveys, sweepstakes, contests, or other promotional activities or events sponsored or managed by Hala Homes or its third-party partners, analyze characteristics and preferences to send you promotional messages, marketing, advertising and other information that we think might be of interest to you, and invite you to events and relevant opportunities.</span>

        <p><strong><h4>3.4 Provide Payment services. Personal information is used to enable or authorize third parties to use Payment Services:</h4></strong></p>
        <span>Detect and prevent money laundering, fraud, abuse, security incidents.</span>
        <span>Conduct security investigations and risk assessments.</span>
        <span>Comply with legal obligations (such as anti-money laundering regulations).</span>
        <span>Enforce the payment terms and other payment policies.</span>
        <span>With your consent, send you promotional messages, marketing, advertising, and other information that may be of interest to you based on your preferences.</span>
        <span>Provide and improve the Payment Services.</span>

        <p><strong><h4>5.2 Linking Third-Party Accounts.</h4></strong></p>
        <span>You can link your Hala Homes account with certain third-party services like social networks. Your contacts on these third-party services are referred to as “Friends.” When you direct the data sharing by creating this link:</span>
        <span>some of the information provided to us from linking accounts may be published on your public profile, your activities on the Hala Homes Platform may be displayed to your Friends on the Hala Homes Platform and/or that third-party service, a link to your public profile on that third-party service may be included in your Hala Homes public profile, other Hala Homes users may be able to see any Friends that you may have in common with them, or that you are a Friend of their Friend if applicable, other Hala Homes users may be able to see any schools, hometowns or other groups you have in common with them as listed on your linked social networking service, information you provide to us from the linking of your accounts may be stored, processed and transmitted for fraud prevention and risk assessment purposes, and publication and display of information that you provide to the Hala Homes Platform through this linkage is subject to your settings and authorizations on the Hala Homes Platform and the third-party service</span>
        
        <p><strong><h4>6.3 Data Erasure.</h4></strong></p>
        <span>In certain jurisdictions, you can request that your personal information be deleted. Please note that if you request the erasure of your personal information:</span>
        <span>We may retain your personal information as necessary for our legitimate business interests, such as prevention of money laundering, fraud detection and prevention, and enhancing safety. For example, if we suspend an Hala Homes Account for fraud or safety reasons, we may retain information from that Hala Homes Account to prevent that Member from opening a new Hala Homes Account in the future. We may retain and use your personal information to the extent necessary to comply with our legal obligations. For example, Hala Homes and Hala Homes Payments may keep information for tax, legal reporting and auditing obligations.</span>
        <span>Information you have shared with others (e.g., Reviews, forum postings) will continue to be publicly visible on Hala Homes, even after your Hala Homes Account is cancelled. However, attribution of such information to youwill be removed. Some copies of your information (e.g., log records) will remain in our database, but are disassociated from personal identifiers.</span>
        <span>Because we take measures to protect data from accidental or malicious loss and destruction, residual copies of your personal information may not be removed from our backup systems for a limited period of time.</span>

        <p><strong><h3>7. SECURITY</h3></strong></p>
        <span>While no organization can guarantee perfect security, we are continuously implementing and updating administrative, technical, and physical security measures to help protect your information against unauthorized access, loss, destruction, or alteration.</span>

        <p><strong><h3>8. CHANGES TO THIS PRIVACY POLICY</h3></strong></p>
        <span>We reserve the right to modify this Privacy Policy at any time in accordance with applicable law. If we do so, we will post the revised Privacy Policy and update the “Last Updated” date at the top. In case of material changes, we will also provide you with notice of the modification by email at least thirty (30) days before the effective date. If you disagree with the revised Privacy Policy, you can cancel your Account. If you do not cancel your Account before the date the revised Privacy Policy becomes effective, your continued access to or use of the Hala Homes Platform will be subject to the revised Privacy Policy.</span>

        
      </div>
    );
  }
}

export default Privacy;
