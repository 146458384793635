import React from 'react';
import { Menu } from 'antd';


class HeadDetial extends React.Component {


  render() {
  

    return (
     <div className="head-detial">
       <div className="head-detial-cover">
         <h2>Prpperty Detail</h2>
         <h4>Home / Product Title</h4>
       </div>
     </div>
    );
  }
}


export default HeadDetial;

