import React from 'react';
import {
  Form,
  Select,
  Button,
  Collapse,
  Modal ,
  Slider ,
} from 'antd';
import {SearchOutlined  } from '@ant-design/icons';
import {connect} from "react-redux";
import BASE_URL from '../../BASE_URL';
import BASE_URL_Majid from '../../BASE_URL_Majid'
import axios from 'axios';
import {Peroperty_State_Action} from '../../action/ListPeropertyAction';
import { Redirect } from 'react-router-dom';
import Loader from './Loader';



class HomeSearch extends React.Component {

  state = {
    redirect : false ,
    visible : false ,
    display : false ,

  }

  handleOk = ()=> {
    this.setState({visible:false});
  }

  
  onFinish = (values)=> {
    this.setState({display : true})
    const bed = [];
    const bath = [];
    // Bedroom
    if(values.minbed !=undefined && values.maxbed != undefined){
      let maxbed = parseInt(values.maxbed) ;
      let minbed = parseInt(values.minbed);
      while(maxbed >= minbed){
        bed.push(minbed)
        minbed = minbed + 1 ;
      }
    }
    else if (values.minbed ==undefined && values.maxbed != undefined){
      bed.push(parseInt(values.maxbed))
    }
    else if (values.minbed !=undefined && values.maxbed == undefined){
      bed.push(parseInt(values.minbed))
    }

    // BathRoom
    if(values.minbath !=undefined && values.maxbath != undefined){
      let maxbath = parseInt(values.maxbath) ;
      let minbath = parseInt(values.minbath);
      while(maxbath >= minbath){
        bath.push(minbath)
        minbath = minbath + 1 ;
      }
    }
    else if (values.minbath ==undefined && values.maxbath != undefined){
      bath.push(parseInt(values.maxbath))
    }
    else if (values.minbath !=undefined && values.maxbath == undefined){
      bath.push(parseInt(values.minbath))
    }
    let postdata = {};
    if(bath.length > 0){postdata['bathroom'] = JSON.stringify(bath)};
    if(bed.length > 0){postdata['bedroom'] = JSON.stringify(bed)};
    if(values.category != undefined){postdata['category']=values.category} ;
    if(values.maxprice != undefined){postdata['price_max']=values.maxprice} ;
    if(values.minprice != undefined){postdata['price_min']=values.minprice} ;
    if(values.type != undefined){postdata['type']=values.type} ;
    if(values.city != undefined){postdata['city']=values.city} ;
    console.log(postdata);
    axios.post(BASE_URL_Majid + `/panel/filter_home` , postdata)
      .then((res) => {
        if(res.data.result === "ok"){
          this.props.Peroperty_State_Action(res.data.data);
          this.setState({
            redirect:true,
            display:false ,
          })
        } 
        else if(res.data.result === "error"){
          this.setState({
            visible : true,
            display:false ,
          })
        }
      })
  }


  render() {
    const { Panel } = Collapse;
    return (
    <>  
      { this.state.redirect ? (<Redirect  to='list'  />) : null }
      { this.state.display == true ? <Loader/> : null}
      <Form
        layout="filter"
        id="filter-form"
        onFinish={this.onFinish}
      >
      <Form.Item name="city" >
          <Select placeholder="City">
              <Select.Option value="all">All</Select.Option>
              <Select.Option value="Dubai">Dubai</Select.Option>
              <Select.Option value="Abu Dhabi">Abu Dhabi</Select.Option>
              <Select.Option value="Sharjah">Sharjah</Select.Option>
              <Select.Option value="Al Ain">Al Ain</Select.Option>
              <Select.Option value="Ajman">Ajman</Select.Option>
              <Select.Option value="RAK City">RAK City</Select.Option>
              <Select.Option value="Fujairah">Fujairah</Select.Option>
              <Select.Option value="Umm al-Quwain">Umm al-Quwain</Select.Option>
              <Select.Option value="Khor Fakkan">Khor Fakkan</Select.Option>
              <Select.Option value="Kalba">Kalba</Select.Option>
              <Select.Option value="Jebel Ali">Jebel Ali</Select.Option>
              <Select.Option value="Dibba Al-Fujairah">Dibba Al-Fujairah</Select.Option>
              <Select.Option value="Madinat Zayed">Madinat Zayed</Select.Option>
              <Select.Option value="Ruwais">Ruwais</Select.Option>
              <Select.Option value="Liwa Oasis">Liwa Oasis</Select.Option>
              <Select.Option value="Dhaid">Dhaid</Select.Option>
              <Select.Option value="Ghayathi">Ghayathi</Select.Option>
              <Select.Option value="Ar-Rams">Ar-Rams</Select.Option>
              <Select.Option value="Dibba Al-Hisn"> 	Dibba Al-Hisn</Select.Option>
              <Select.Option value="Hatta">Hatta</Select.Option>
              <Select.Option value="Al Madam">Al Madam</Select.Option>
          </Select>
        </Form.Item>    
        <Form.Item name="type" >
          <Select placeholder="Type">
            <Select.Option value="1">Aparteman</Select.Option>
            <Select.Option value="2">villa</Select.Option>
          </Select>
        </Form.Item>  
        <Form.Item name="category" >
          <Select placeholder="Category" >
            <Select.Option value="buy">Buy</Select.Option>
            <Select.Option value="rent">Rent</Select.Option>
            <Select.Option value="commericial">Commericial</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="minprice" >
          <Select placeholder="Min Price" >
            <Select.Option value="20000">20,000 AED/year</Select.Option>
            <Select.Option value="30000">30,000 AED/year</Select.Option>
            <Select.Option value="40000">40,000 AED/year</Select.Option>
            <Select.Option value="50000">50,000 AED/year</Select.Option>
            <Select.Option value="100000">100,000 AED/year</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="maxprice" >
          <Select placeholder="Max Price" >
            <Select.Option value="20000">20,000 AED/year</Select.Option>
            <Select.Option value="30000">30,000 AED/year</Select.Option>
            <Select.Option value="40000">40,000 AED/year</Select.Option>
            <Select.Option value="50000">50,000 AED/year</Select.Option>
            <Select.Option value="100000">100,000 AED/year</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="minbed" >
          <Select placeholder="Min Bedroom" >
            <Select.Option value="1">1 Bedroom</Select.Option>
            <Select.Option value="2">2 Bedroom</Select.Option>
            <Select.Option value="3">3 Bedroom</Select.Option>
            <Select.Option value="4">4 Bedroom</Select.Option>
            <Select.Option value="5">5 Bedroom</Select.Option>
            <Select.Option value="6">6 Bedroom</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="maxbed" >
          <Select placeholder="Max Bedroom" >
            <Select.Option value="2">2 Bedroom</Select.Option>
            <Select.Option value="3">3 Bedroom</Select.Option>
            <Select.Option value="4">4 Bedroom</Select.Option>
            <Select.Option value="5">5 Bedroom</Select.Option>
            <Select.Option value="6">6 Bedroom</Select.Option>
            <Select.Option value="7">7 Bedroom</Select.Option>
            <Select.Option value="8">8 Bedroom</Select.Option>
            <Select.Option value="9">9 Bedroom</Select.Option>

          </Select>
        </Form.Item>
        <Form.Item name="minbath" >
          <Select placeholder="Min Bathroom" >
            <Select.Option value="1">1 Bathroom</Select.Option>
            <Select.Option value="2">2 Bathroom</Select.Option>
            <Select.Option value="3">3 Bathroom</Select.Option>
            <Select.Option value="4">4 Bathroom</Select.Option>
            <Select.Option value="5">5 Bathroom</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="maxbath" >
          <Select placeholder="Max Bathroom" >
            <Select.Option value="1">1 Bathroom</Select.Option>
            <Select.Option value="2">2 Bathroom</Select.Option>
            <Select.Option value="3">3 Bathroom</Select.Option>
            <Select.Option value="4">4 Bathroom</Select.Option>
            <Select.Option value="5">5 Bathroom</Select.Option>
            <Select.Option value="6">6 Bathroom</Select.Option>
            <Select.Option value="7">7 Bathroom</Select.Option>
            <Select.Option value="8">8 Bathroom</Select.Option>

          </Select>
        </Form.Item>
        <Form.Item className="form-filter-submit">
          <Button htmlType="submit"><SearchOutlined/>Search</Button>
        </Form.Item>
      {/* <button onClick={()=>{console.log(this.props.Displaylistdata)}}> fwef</button> */}
      </Form>
      <Modal  visible={this.state.visible} onCancel={this.handleOk} footer={null}>
        <p>Data Not Found</p>
      </Modal>
    </>  
    );
  }
}

const mapStateToProps = state => {

  return({
    Displaylistdata: state.listdata,
  })
}
const mapDispatchToProps = {Peroperty_State_Action};

export default connect(mapStateToProps,  mapDispatchToProps )(HomeSearch);

