import React from 'react';
import { Tabs } from 'antd';

import {CheckOutlined } from '@ant-design/icons';



class TabDetailHotel extends React.Component {


  render() {
    const { TabPane } = Tabs;
    return (
     <div className="detail-tab">
         <Tabs defaultActiveKey="2" >
          <TabPane tab="Condition" key="2">
            <h3>Condition</h3>
            <div className="detail-tab-item-panel">
              {this.props.detail.parking == 1 && <div className="detail-tab-item-panel-item"><CheckOutlined /> Parking</div>}
              {this.props.detail.cafe == 1 && <div className="detail-tab-item-panel-item"><CheckOutlined /> Cafe</div>}
              {this.props.detail.pool == 1 && <div className="detail-tab-item-panel-item"><CheckOutlined /> Pool</div>}
              {this.props.detail.restaurant == 1 && <div className="detail-tab-item-panel-item"><CheckOutlined /> Restaurant</div>}
            </div>
          </TabPane>
          <TabPane tab="Description" key="1">
            <h3>Description</h3>
            <p>
              {this.props.detail.description}
            </p>
          </TabPane>
        </Tabs>
     </div>
    );
  }
}

export default TabDetailHotel;
