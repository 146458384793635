import React from 'react';
import { Form, Input, InputNumber, Button, Select, Upload } from 'antd';
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import { Row, Col } from 'antd';
import PANEL_BASE_URL from '../../../PANEL_BASE_URL';
import { Modal } from 'antd';
import axios from 'axios';
import Loader2 from '../../widget/Loader2';


class AgentEditProfile extends React.Component {

  state = {
    processing: false,
    visible: false,
    fileList: [],
    markers: [45.4, -75.7],
    detail_data: {},
  }

  addMarker = (e) => {
    const newmarker = [];
    newmarker.push(e.latlng.lat);
    newmarker.push(e.latlng.lng);
    this.setState({ markers: newmarker });
  }

  handleCancel = () => {
    this.setState({ visible: false });
    // return window.location.href = '/panel/agent/agentlistproperty'
  }

  onChange = ({ fileList: newFileList }) => {
    this.setState({ fileList: newFileList });
  };

  onPreview = async file => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };


  onFinish = values => {
    this.setState({ processing: true });
    const bodyFormData = new FormData();
    bodyFormData.append('name', values.name);
    bodyFormData.append('token', localStorage.getItem("agenttoken"));
    bodyFormData.append('country', values.country);
    bodyFormData.append('languages', values.languages);
    bodyFormData.append('phone', values.phone);
    bodyFormData.append("image", this.state.fileList[0].originFileObj);
    axios.post(PANEL_BASE_URL + `/edit_profile`, bodyFormData, { headers: { "Content-type": "multipart/form-data" } })
      .then((res) => {
        if (res.data.result === "ok") {
          this.setState({
            processing: false,
            visible: true,
          });
        }
        else if (res.data.error === "unauthenticated") {
          this.setState({ processing: false });
          localStorage.clear();
          return window.location.href = '/login';
        }
      })
      .catch((err) => {
        this.setState({ processing: false })
        console.log("err");
      })
  };

  formRef = React.createRef();
  componentDidMount() {
    this.setState({ processing: true });
    axios.post(PANEL_BASE_URL + `/profile?token=${localStorage.getItem("agenttoken")}`)
      .then((res) => {
        if (res.data.result === 'ok') {
          this.setState({
            detail_data: res.data.data,
            processing: false,
          });
          this.formRef.current.setFieldsValue(res.data.data);
        }
        else if (res.data.error === "unauthenticated") {
          localStorage.clear();
          return window.location.href = '/login'
        }
      })
  }

  render() {

    const layout = {
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
    };

    return (
      <div className="add-product-form">
        {this.state.processing ? <Loader2 /> : null}
        <Modal
          onCancel={this.handleCancel}
          visible={this.state.visible}
          footer={null}
        >
          <p>Edit Success</p>
        </Modal>
        <Form ref={this.formRef} {...layout} name="nest-messages" onFinish={this.onFinish} >
          <Form.Item name={'name'} label="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name={'phone'} label="phone" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name={'email'} label="email" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name={'country'} label="country" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name={'languages'} label="languages" >
            <Input />
          </Form.Item>

          <Row justify="end">
            <Col span={22}>

              <Upload
                accept=".png , .jpg , .jpeg"
                listType="picture-card"
                fileList={this.state.fileList}
                onChange={this.onChange}
                onPreview={this.onPreview}
                customRequest={this.dummyRequest}
              >
                {this.state.fileList.length < 1 &&
                  <>
                    {this.state.detail_data?.image == "" ? 'profile' : <img className="agent-profile-load" src={`${`https://downloadforever.ir/saeed/RealEstate/public/images/${this.state.detail_data.image}`}`} />}
                  </>
                }
              </Upload>
            </Col>
          </Row>

          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}


export default AgentEditProfile;
