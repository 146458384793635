import React from 'react';
import { Row , Col } from 'antd';
import Img4 from '../img/img4.jpg';
import Img5 from '../img/blog1.jpg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Zoom from 'react-reveal/Zoom';
import axios from 'axios';
import BASE_URL from '../../BASE_URL';
import LoaderImage from './LoaderImage';
import { Link } from 'react-router-dom';

class PopularPosts extends React.Component {


  state = {
    listData : [] ,
    imageLoader : false ,
    options : {
      loop: true,
      margin: 10,
      nav:true,
      responsive:{
          0: {
              items: 1,
          },
          600: {
              items: 2,
          },
          1000: {
              items: 3,
          },
        },
    }
  }   


  componentDidMount(){
    axios.post(BASE_URL + "/rents")
    .then((res) => {
      if(res.data.result === "ok"){
        console.log(res.data.data);
        this.setState({listData : res.data.data})
      } 
    })
  }


  render() {
    
    return (
      <Zoom left>
     <div className="popular-section">
       <div className="popular-section-head"><h3>Popular Properties</h3></div>
       <div className="popular-section-cont">
        {this.state.listData.length > 0 && 
        <OwlCarousel className='owl-theme' loop margin={10} {...this.state.options} >
          {this.state.listData.map((item , index) =>
            <Col key={index} className="item popular-section-item">
              <div className="popular-section-item-init">
                <div className="popular-section-item-init-img">
                  <Link to={`/detail/${item.id}`}>
                    {item.image != null && <img   src={`https://downloadforever.ir/saeed/RealEstate/public/images/${item.image}`} alt="card"/> }
                  </Link>
                  <div className="popular-section-item-init-img-radius">
                    <div className="popular-section-item-init-img-radius-init">Rent</div>
                  </div>
                </div>
                <div className="popular-section-item-init-des">
                  <div className="popular-section-item-init-des-one">
                    <h3>{item.name}</h3>
                    <p>{item.description.substring(0 , 120)}...</p>
                    <div className="popular-section-item-init-des-one-warp">
                      <div className="popular-section-item-init-des-one-warp-init">{item.price}</div>
                    </div>
                  </div>
                  <div className="popular-section-item-init-des-two">
                    <Link  to={`/detail/${item.id}`}><span>Read More...</span></Link>
                  </div>
                </div>
              </div>
            </Col>
            )} 
          </OwlCarousel>
          }
       </div>
     </div>
     </Zoom>
    );
  }
}

export default PopularPosts;
