import React from 'react';


class Loader3 extends React.Component {


  render() {


    return (
      <div class="loader2">
        <div class=" ball-pulse-sync">
          <div></div>
          <div></div>
          <div></div>
        </div>      
      </div>
    );
  }
}

export default Loader3;
