import React from 'react';
import  { useState } from 'react';
import { Form, Input, InputNumber, Button , Select , Upload } from 'antd';
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import { Row, Col } from 'antd';
import BASE_URL from '../../../BASE_URL';
import PANEL_BASE_URL from '../../../PANEL_BASE_URL';

import { Modal } from 'antd';
import axios from 'axios';
import Loader2 from '../../widget/Loader2';


class UserAddCar extends React.Component {
  
  state = {
    processing : false ,
    visible : false ,
    fileList : [],
    markers : [45.4, -75.7] ,
  }

  addMarker = (e) => {
    const newmarker = [];
    newmarker.push(e.latlng.lat);
    newmarker.push(e.latlng.lng);
    this.setState({markers :newmarker});
  }

  handleCancel = () => {
    this.setState({visible:false});
    return window.location.href = '/panel/user/listcar'
  }

  onChange = ({ fileList: newFileList }) => {
    this.setState({fileList :newFileList});
  };

  onPreview = async file => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  onFinish = values => {
    if(this.state.fileList.length == 0){
      alert("Selecting a photo is required ")
    }
    else {
      this.setState({processing:true});
      const bodyFormData = new FormData();
      bodyFormData.append('door', values.user.door);
      bodyFormData.append('seat', values.user.seat);
      bodyFormData.append('power', values.user.power);
      bodyFormData.append('transmission', values.user.transmission);
      bodyFormData.append('odometer', values.user.odometer);
      bodyFormData.append('year', values.user.year);
      bodyFormData.append('company', values.user.company);
      bodyFormData.append('model', values.user.model);
      bodyFormData.append('color', values.user.color);
      bodyFormData.append('age_limit', values.user.age_limit);
      bodyFormData.append('insurance', values.user.insurance);
      bodyFormData.append('cost', values.user.cost);
      bodyFormData.append('extra_distance_charge', values.user.extra_distance_charge);
      bodyFormData.append('limit_distance', values.user.limit_distance);
      bodyFormData.append('description', values.user.description);
      bodyFormData.append('phone', values.user.phone);
      bodyFormData.append('isAvailable', values.user.available); 
      bodyFormData.append('type', "file");
      bodyFormData.append('address', values.user.address);
      bodyFormData.append('city', values.user.city);
      bodyFormData.append('lat', this.state.markers[0]);
      bodyFormData.append('lng', this.state.markers[1]);
      bodyFormData.append('token', localStorage.getItem('usertoken'));
      for(var i in this.state.fileList){
        bodyFormData.append("image"+ i,this.state.fileList[i].originFileObj );
      }
      axios.post(PANEL_BASE_URL + `/createCarWithoutVIN`, bodyFormData , {headers: {"Content-type": "multipart/form-data"}})
      .then((res) => {
        if(res.data.result === "ok"){
          this.setState({
            processing : false ,
            visible:true,
          });
        }
        else if (res.data.error === "unauthenticated") {
          this.setState({processing : false ,});
          localStorage.clear(); 
          return window.location.href = '/login';
        }
       })
      .catch((err)=>{
        this.setState({processing : false}) ;
        alert('Network Error')
      }) 
    }
  };

render() {
 
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };
  
  return (
  <div className="add-product-form"> 
    {this.state.processing ? <Loader2/> : null}
    <Modal
          onCancel={this.handleCancel}
          visible={this.state.visible}
          footer={null}
        >
          <p>Add Success</p>
    </Modal> 
    <Form {...layout} name="nest-messages" onFinish={this.onFinish} >
      <Form.Item name={['user', 'door']} label="door" rules={[{ required: true }]}>
        <Select>
          <Select.Option value="2">2</Select.Option>
          <Select.Option value="3">3</Select.Option>
          <Select.Option value="4">4</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name={['user', 'seat']} label="seat" rules={[{ required: true }]}>
        <Select>
          <Select.Option value="2">2</Select.Option>
          <Select.Option value="4">4</Select.Option>
          <Select.Option value="6">6</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name={['user', 'power']} label="power" rules={[{ required: true }]}>
        <InputNumber />
      </Form.Item>
      <Form.Item name={['user', 'transmission']} label="transmission" rules={[{ required: true }]}>
        <Select>
          <Select.Option value="1">Yes</Select.Option>
          <Select.Option value="0">No</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name={['user', 'available  ']} label="available " rules={[{ required: true }]}>
        <Select>
          <Select.Option value={1}>Yes</Select.Option>
          <Select.Option value={0}>No</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name={['user', 'odometer']} label="odometer" rules={[{ required: true }]}>
        <InputNumber />
      </Form.Item>
      <Form.Item name={['user', 'year']} label="year" rules={[{ required: true }]}>
        <InputNumber />
      </Form.Item>
      <Form.Item name={['user', 'company']} label="company" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name={['user', 'model']} label="model" rules={[{ required: true }]}>
        <InputNumber />
      </Form.Item>
      <Form.Item name={['user', 'color']} label="color" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name={['user', 'age_limit']} label="age limit" rules={[{ required: true }]}>
        <InputNumber />
      </Form.Item>
      <Form.Item name={['user', 'insurance']} label="insurance" rules={[{ required: true }]}>
        <Select>
          <Select.Option value="1">Yes</Select.Option>
          <Select.Option value="0">No</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name={['user', 'cost']} label="cost" rules={[{ required: true }]}>
        <InputNumber />
      </Form.Item>
      <Form.Item name={['user', 'description']} label="description" rules={[{ required: true }]}>
        <Input.TextArea />
      </Form.Item>
      <Form.Item name={['user', 'phone']} label="phone" rules={[{ required: true }]}>
        <InputNumber />
      </Form.Item>
      <Form.Item name={['user', 'city']} >
            <Select placeholder="City">
              <Select.Option value="Dubai">Dubai</Select.Option>
              <Select.Option value="Abu Dhabi">Abu Dhabi</Select.Option>
              <Select.Option value="Sharjah">Sharjah</Select.Option>
              <Select.Option value="Al Ain">Al Ain</Select.Option>
              <Select.Option value="Ajman">Ajman</Select.Option>
              <Select.Option value="RAK City">RAK City</Select.Option>
              <Select.Option value="Fujairah">Fujairah</Select.Option>
              <Select.Option value="Umm al-Quwain">Umm al-Quwain</Select.Option>
              <Select.Option value="Khor Fakkan">Khor Fakkan</Select.Option>
              <Select.Option value="Kalba">Kalba</Select.Option>
              <Select.Option value="Jebel Ali">Jebel Ali</Select.Option>
              <Select.Option value="Dibba Al-Fujairah">Dibba Al-Fujairah</Select.Option>
              <Select.Option value="Madinat Zayed">Madinat Zayed</Select.Option>
              <Select.Option value="Ruwais">Ruwais</Select.Option>
              <Select.Option value="Liwa Oasis">Liwa Oasis</Select.Option>
              <Select.Option value="Dhaid">Dhaid</Select.Option>
              <Select.Option value="Ghayathi">Ghayathi</Select.Option>
              <Select.Option value="Ar-Rams">Ar-Rams</Select.Option>
              <Select.Option value="Dibba Al-Hisn"> 	Dibba Al-Hisn</Select.Option>
              <Select.Option value="Hatta">Hatta</Select.Option>
              <Select.Option value="Al Madam">Al Madam</Select.Option>
          </Select>
        </Form.Item>
      <Form.Item name={['user', 'address']} label="address" rules={[{ required: true }]}>
        <Input.TextArea />
      </Form.Item>
      <Form.Item name={['user', 'limit_distance']} label="limit distance" rules={[{ required: true }]}>
        <InputNumber />
      </Form.Item>
      <Form.Item name={['user', 'extra_distance_charge']} label="extra distance charge" rules={[{ required: true }]}>
        <InputNumber />
      </Form.Item>
      <Row justify="end">
        <Col span={22}>
        <Upload
              accept=".png , .jpg , .jpeg"
              listType="picture-card"
              fileList={this.state.fileList}
              onChange={this.onChange}
              onPreview={this.onPreview}
              customRequest={this.dummyRequest}
              showUploadList = {
                {showPreviewIcon : false ,}
              }
              >
              {this.state.fileList.length < 5 && '+ Upload'}
        </Upload>
        </Col>
      </Row>
      <Row justify="end">
        <Col span={22}>
          <Map center={[45.4, -75.7]} zoom={12} onClick={this.addMarker}>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker  position={this.state.markers}>
              </Marker>
          </Map>
        </Col>
      </Row>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
</div>    
  );}
}

export default UserAddCar;

