import React from 'react';
import 'antd/dist/antd.css';
import Head from '../widget/Head';
import Footer from '../widget/Footer';
import BrandSection from '../widget/BrandSection';
import CarouselDetail from '../widget/CarouselDetail';
import HeadDetail from '../widget/HeadDetial';
import TabDetail from '../widget/TabDetail';
import DetailMap from '../widget/DetailMap';
import { EnvironmentOutlined, MailOutlined, NotificationOutlined } from '@ant-design/icons';
import Loader from '../widget/Loader';
import axios from 'axios';
import BASE_URL_APP from '../../BASE_URL_APP';
import Comment from '../widget/Comment';
import { Image } from 'antd'
import PlaceHolde_User from '../img/user.png'




class Detail extends React.Component {

  state = {
    detail_data: "",
    processing: false,
    list_image: [],
    list_comment: [],
    detailAgent: "",
  }

  componentDidMount() {
    this.setState({ processing: true });
    const postdata = {
      "id": `${this.props.match.params.id}`,
    }
    axios.post(BASE_URL_APP + `/propertyInfo`, postdata)
      .then((res) => {
        if (res.data.result === 'ok') {
          this.setState({
            detail_data: res.data.data.property,
            processing: false,
            detailAgent: res.data.data.agent,
            list_image: res.data.data.property.images,
            list_comment: res.data.data.review,
          });
        }
      })
  }


  render() {
    const pid = `${this.props.match.params.id}`;

    return (
      <div className="main">
        {this.state.processing ? <Loader /> : null}
        <Head />
        <HeadDetail />
        <div className="detail-cont">
          <div className="detail-cont-one">
            <div className="detail-cont-one-head">
              <div className="detail-cont-one-head-left">
                <h1>{this.state.detail_data.name}</h1>
                <p><EnvironmentOutlined /> {this.state.detail_data.city} {this.state.detail_data.address} </p>
              </div>
              <div className="detail-cont-one-head-right">
                <h3>$ {this.state.detail_data.price}</h3>
              </div>
            </div>
            <CarouselDetail detail={this.state.list_image} />
            <TabDetail detail={this.state.detail_data} />
            <DetailMap detail={this.state.detail_data} />
            <Comment peroperty_id={this.props.match.params.id} type="home" listComment={this.state.list_comment} />
          </div>
          <div className="detail-cont-two">
            <div className="detail-contact-agent">
              {/* <Image
                src={`${`https://downloadforever.ir/saeed/RealEstate/public/images/${this.state.detailAgent.image}`}`}
                fallback={PlaceHolde_User}
                preview={false}
              /> */}
              {this.state.detailAgent.image && this.state.detailAgent.image != "" ?
                <img src={`${`https://downloadforever.ir/saeed/RealEstate/public/images/${this.state.detailAgent.image}`}`} alt="profile" />
                :
                <img src={PlaceHolde_User} alt="profile" />

              }
              <h3>{this.state.detailAgent.name}</h3>
              <div className="detail-contact-agent-social">
                <MailOutlined />
                <span>email : {this.state.detailAgent.email}</span>
              </div>
              <div className="detail-contact-agent-social">
                <EnvironmentOutlined />
                <span>country : {this.state.detailAgent.country}</span>
              </div>
              <div className="detail-contact-agent-social">
                <NotificationOutlined />
                <span>language : {this.state.detailAgent.languages}</span>
              </div>
              <a className="detail-contact-agent-call" href={`tel:${this.state.detailAgent.phone}`}><button>CALL</button></a>
              {/* <a className="detail-contact-agent-wts" href={`tel:${this.state.detailAgent.phone}`}><button>WHATSAPP</button></a> */}

            </div>
          </div>
        </div>
        <BrandSection />
        <Footer />
      </div>
    );
  }
}

export default Detail;
