import React from 'react';
import {Modal , Form , Select , Button , DatePicker , InputNumber} from 'antd';
import 'antd/dist/antd.css';
import Head from '../widget/Head';
import Footer from '../widget/Footer';
import BrandSection from '../widget/BrandSection';
import CarouselDetailHotel from '../widget/CarouselDetailHotel';
import HeadDetail from '../widget/HeadDetial';
import TabDetailHotel from '../widget/TabDetailHotel';
import DetailMap from '../widget/DetailMap';
import {EnvironmentOutlined , CheckOutlined , SearchOutlined} from '@ant-design/icons';
import Loader from '../widget/Loader';
import axios from 'axios';
import BASE_URL_Majid from '../../BASE_URL_Majid';
import Img from '../img/img4.jpg';
import CheckUserAuth from '../widget/CheckUserAuth';
import { changeConfirmLocale } from 'antd/lib/modal/locale';
import {VisibleAuth_State_Action} from '../../action/ListPeropertyAction';
import {connect} from "react-redux";
import moment from 'moment';
import Comment from '../widget/Comment';







class DetailHotel extends React.Component {

  state = {
    detail_data : "" ,
    processing : false ,
    visibleDisplaySuite : false ,
    visiblSelectDate : false ,
    visibleSuccessAddReserve : false ,
    list_image : [],
    list_comment : [] ,
    list_suite : [] ,
  }


  displaySuite=()=>{
    if(CheckUserAuth()){
      if(sessionStorage.getItem("hotelDateStart") != null && sessionStorage.getItem("hotelDateEnd") != null){
        this.getSuiteList()
      }else {
        this.setState({visiblSelectDate : true})
      }
    }
    else {
      this.props.VisibleAuth_State_Action(true)
    }
  }


  onFinishDate=(values)=>{
    sessionStorage.setItem("hotelDateStart", moment(values.date[0]['_d']).format('YYYY-MM-DD'));
    sessionStorage.setItem("hotelDateEnd", moment(values.date[1]['_d']).format('YYYY-MM-DD'));
    this.getSuiteList()
  }

  getSuiteList=()=>{
    this.setState({processing : true}) ;
    const postdata = {
      "hotel_id":this.props.match.params.id,
      "start_time":sessionStorage.getItem('hotelDateStart'),
      "end_time":sessionStorage.getItem('hotelDateEnd')
    }
    axios.post(BASE_URL_Majid + `/suites/show_all` , postdata )
    .then((res) => {
      if(res.data.result === 'ok'){
        this.setState({
          visibleDisplaySuite : true ,
          processing : false,
          list_suite : res.data.data ,
        });  
      } 
    })
    .catch((er)=>{
      this.setState({processing : false}) ;
      alert("Network Error")
      console.log('er');
    });
  }


  addReserve=(suite_id)=>{
    this.setState({processing : true}) ;
    const postdata = {
      "suite_id":suite_id,
      "start_time":sessionStorage.getItem('hotelDateStart'),
      "end_time":sessionStorage.getItem('hotelDateEnd') ,
      "user_email":localStorage.getItem('username')
    }
    axios.post(BASE_URL_Majid + `/reservation` , postdata )
    .then((res) => {
      if(res.data.result === 'ok'){
        this.setState({
          visibleDisplaySuite : false ,
          processing : false,
          visiblSelectDate : false,
          visibleSuccessAddReserve : true ,
        });  
      } 
    })
    .catch((er)=>{
      this.setState({processing : false}) ;
      alert("Network Error")
      console.log('er');
    });
  }


  componentDidMount(){
    this.setState({ processing : true});
    const postdata = {
      "id":`${this.props.match.params.id}`,
    }
    axios.get(BASE_URL_Majid + `/hotels/${this.props.match.params.id}` )
    .then((res) => {
      if(res.data.result === 'ok'){
        this.setState({
          detail_data : res.data.data ,
          list_image : res.data.data.image ,
          list_comment : res.data.data.comments ,
          processing : false,
        });  
      } 
    })
    .catch((er)=>{
      console.log('er');
    });
  }  


  render() {
    const { RangePicker } = DatePicker;

    return (
      <div className="main">
        {this.state.processing ? <Loader/> : null}
        <Head/>
        <HeadDetail/>
        <div className="detail-cont">
          <div className="detail-cont-one">
            <div className="detail-cont-one-head">
              <div className="detail-cont-one-head-left">
                <h1>{this.state.detail_data.name}</h1>
                <p><EnvironmentOutlined/> {this.state.detail_data.city} </p>
              </div>
              <div className="detail-cont-one-head-right">
                <h3>$ 24000</h3>
              </div>
            </div>
            <CarouselDetailHotel detail={this.state.list_image} />
            <TabDetailHotel detail={this.state.detail_data} />
            <DetailMap detail={this.state.detail_data} />
            <Comment peroperty_id={this.props.match.params.id} type="hotel" listComment={this.state.list_comment} />
          </div>
          <div className="detail-cont-two">
            <div className="detail-contact-agent">
              {/* <h3>17,252 AED/month</h3> */}
              {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
              <button onClick={this.displaySuite}>Access Room</button> */}
              <a className="detail-contact-agent-call"><button onClick={this.displaySuite}>Access Room</button></a>
            </div>
          </div>
        </div>
        <BrandSection/>
        <Footer/>
        
        <Modal
          title="Suite"
          visible={this.state.visibleDisplaySuite}
          onCancel={()=>{this.setState({visibleDisplaySuite : false})}}
          footer={null}
          width={1000}
        >    
          <div className="modalSuite">
            {this.state.list_suite.map((item, index) =>
            <div className="modalSuiteItem">
              {this.state.list_image[0] != undefined && this.state.list_image.length > 0 &&
              <img src={`https://downloadforever.ir/saeed/RealEstate/public/images/${this.state.list_image[0].image}`}></img>
              }
              <div className="modalSuiteItemCont">
                {item.kitchen != 0 && <div className="modalSuiteItemContItem"><CheckOutlined /><span>kitchen</span></div>}
                {item.bathroom != 0 &&<div className="modalSuiteItemContItem"><CheckOutlined /><span>{item.bathroom } bath</span></div>}
                {item.breakfast_included != 0 &&<div className="modalSuiteItemContItem"><CheckOutlined /><span> breakfast</span></div>}
                {item.terrace != 0 &&<div className="modalSuiteItemContItem"><CheckOutlined /><span> terrace</span></div>}
                {item.air_condition != 0 &&<div className="modalSuiteItemContItem"><CheckOutlined /><span> air condition</span></div>}
                {item.bed != 0 &&<div className="modalSuiteItemContItem"><CheckOutlined /><span> {item.bed} bed</span></div>}
                {item.room != 0 &&<div className="modalSuiteItemContItem"><CheckOutlined /><span> {item.room} bed</span></div>}
                {item.wifi != 0 &&<div className="modalSuiteItemContItem"><CheckOutlined /><span> wifi </span></div>}
                {item.tv != 0 &&<div className="modalSuiteItemContItem"><CheckOutlined /><span> tv </span></div>}
                {item.animal != 0 &&<div className="modalSuiteItemContItem"><CheckOutlined /><span> animal </span></div>}
                <div className="modalSuiteItemContItem"> <span style={{fontSize:"18px"}}>$ {item.price} </span></div>
              </div>
              <div className="modalSuiteItemBtn">
                <button onClick={()=>this.addReserve(item.id)}>Reserve</button>
              </div>
            </div>
            )}
          </div>
        </Modal>

        <Modal
          title="Select Date"
          visible={this.state.visiblSelectDate}
          onCancel={()=>{this.setState({visiblSelectDate : false})}}
          footer={null}
        >  
          <Form
          id="DateForm"
          onFinish={this.onFinishDate}
          >
          <Form.Item name="date" label="Select Date" rules={[{ required: true}]} >
            <RangePicker style={{width:"100%"}} />
          </Form.Item>
          <Form.Item className="form-filter-submit">
            <Button style={{width:"100%"}} type="primary" htmlType="submit">
                  Search Suite
            </Button>
          </Form.Item> 
          </Form> 
        </Modal>
        <Modal
          title="Success"
          visible={this.state.visibleSuccessAddReserve}
          onCancel={()=>{this.setState({visibleSuccessAddReserve : false})}}
          footer={null}
        >  
        <p>Your suite has been successfully booked </p>
        </Modal>
      </div>
    );
  }
}


const mapStateToProps = state => {

  return({
    DisplayVisibleModalAuth: state.visibleAuth,

  })
}
const mapDispatchToProps = {VisibleAuth_State_Action};

export default connect(mapStateToProps , mapDispatchToProps)(DetailHotel);
