import React from 'react';
import 'antd/dist/antd.css';
import Head from '../widget/Head';
import Footer from '../widget/Footer';
import BrandSection from '../widget/BrandSection';
import CarouselDetailHotel from '../widget/CarouselDetailHotel';
import HeadDetail from '../widget/HeadDetial';
import TabDetailCar from '../widget/TabDetailCar';
import DetailMap from '../widget/DetailMap';
import {  EnvironmentOutlined, MailOutlined , NotificationOutlined  } from '@ant-design/icons';
import Loader from '../widget/Loader';
import axios from 'axios';
import BASE_URL from '../../BASE_URL';
import Comment from '../widget/Comment';
import { Image } from 'antd'
import PlaceHolde_User from '../img/user.png'






class DetailCar extends React.Component {

  state = {
    detail_data: "",
    processing: false,
    list_image: [],
    list_comment: [],
  }

  componentDidMount() {
    this.setState({ processing: true });
    const postdata = {
      "id": `${this.props.match.params.id}`,
    }
    axios.post(BASE_URL + `/getCarInfo`, postdata)
      .then((res) => {
        if (res.data.result === 'ok') {
          this.setState({
            detail_data: res.data.data,
            processing: false,
            list_image: res.data.data.images,
            list_comment: res.data.data.comment,
          });
        }
      })
  }


  render() {
    return (
      <div className="main">
        {this.state.processing ? <Loader /> : null}
        <Head />
        <HeadDetail />
        <div className="detail-cont">
          <div className="detail-cont-one">
            <div className="detail-cont-one-head">
              <div className="detail-cont-one-head-left">
                <h1>{this.state.detail_data.model}</h1>
                <p><EnvironmentOutlined /> {this.state.detail_data.address} </p>
              </div>
              <div className="detail-cont-one-head-right">
                <h3>$ {this.state.detail_data.cost}</h3>
              </div>
            </div>
            <CarouselDetailHotel detail={this.state.list_image} />
            <TabDetailCar detail={this.state.detail_data} />
            <DetailMap detail={this.state.detail_data} />
            <Comment peroperty_id={this.props.match.params.id} type="car" listComment={this.state.list_comment} />

          </div>
          <div className="detail-cont-two">
            <div className="detail-contact-agent">
              {/* <Image
                src={'/'}
                fallback={PlaceHolde_User}
                preview={false}
              /> */}
              {/* <h3>{this.state.detailAgent?.name}</h3>
              <div className="detail-contact-agent-social">
                <MailOutlined />
                <span>email : {this.state.detailAgent?.email}</span>
              </div>
              <div className="detail-contact-agent-social">
                <EnvironmentOutlined />
                <span>country : {this.state.detailAgent?.country}</span>
              </div>
              <div className="detail-contact-agent-social">
                <NotificationOutlined />
                <span>language : {this.state.detailAgent?.languages}</span>
              </div> */}
              {this.state.detail_data.isAvailable == 1 ?
              <a className="detail-contact-agent-call" href={`tel:${this.state.detail_data.phone}`}><button>CALL</button></a>
                // <a href={`tel:${this.state.detail_data.phone}`}><button>Call</button></a>
                :
                // <button style={{ background: "tomato" }} disabled>Not Available</button>
                <a className="detail-contact-agent-call" ><button style={{ background: "tomato" }} disabled>Not Available</button></a>
              }
            </div>
          </div>
        </div>
        <BrandSection />
        <Footer />
      </div>
    );
  }
}

export default DetailCar;
