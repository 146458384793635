import React from 'react';
import { Row } from 'antd';
import axios from 'axios';
import { Form, Input, InputNumber, Button , Select  } from 'antd';
import Loader2 from '../../widget/Loader2';
import BASE_URL_Majid from '../../../BASE_URL_Majid';




class AdminEditHotel extends React.Component {

  state = {
    processing:false,
    detail_data : "",
  }

   onFinish = values => {
    this.setState({ processing : true});
    const postdata = {
      "name":values.name,
      "lat":this.state.detail_data.lat,
      "lng":this.state.detail_data.lng,
      "city":values.city,
      "level":values.level,
      "cafe":values.cafe,
      "pool":values.pool,
      "parking":values.parking,
      "restaurant":values.restaurant,
      "description":values.description,
      "address":values.address,
    }
    axios.put(BASE_URL_Majid + `/hotels/${this.props.match.params.id}`,  postdata )
    .then((res) => {
      if(res.data.result === 'ok'){
        this.setState({
          processing : false,
        });  
        return window.location.href = '/panel/admin/listhotel'
      }
      else if (res.data.error === "unauthenticated"){
        localStorage.clear(); 
        return window.location.href = '/login'
      } 
    })
  };


  formRef = React.createRef();

  componentDidMount(){
    this.setState({ processing : true});
    const postdata = {
      "id":`${this.props.match.params.id}`,
    }
    axios.get(BASE_URL_Majid + `/hotels/${this.props.match.params.id}` )
    .then((res) => {
      if(res.data.result === 'ok'){
        this.setState({
          detail_data : res.data.data ,
          processing : false,
        });  
        this.formRef.current.setFieldsValue(this.state.detail_data);
      }
      else if (res.data.error === "unauthenticated"){
        localStorage.clear(); 
        return window.location.href = '/login'
      } 
    })
    
  }  
 
  render() {
    const layout = {
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
    };
    
    return (
      <>
        {this.state.processing ? <Loader2/> : null}
        <Form ref={this.formRef} {...layout} name="nest-messages" onFinish={this.onFinish} >
          <Form.Item name='name' label="Name" >
            <Input />
          </Form.Item>
          <Form.Item name='level' label="Level" >
            <InputNumber />
          </Form.Item>
          <Form.Item name='cafe' label="Cafe" >
            <Select>
              <Select.Option value={1}>Yes</Select.Option>
              <Select.Option value={0}>No</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='pool' label="Pool" >
            <Select>
              <Select.Option value={1}>Yes</Select.Option>
              <Select.Option value={0}>No</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='parking' label="Parking" >
            <Select>
              <Select.Option value={1}>Yes</Select.Option>
              <Select.Option value={0}>No</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='restaurant' label="Restaurant" >
            <Select>
              <Select.Option value={1}>Yes</Select.Option>
              <Select.Option value={0}>No</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='city' label="City" >
            <Select>
              <Select.Option value="Dubai">Dubai</Select.Option>
              <Select.Option value="Abu Dhabi">Abu Dhabi</Select.Option>
              <Select.Option value="Sharjah">Sharjah</Select.Option>
              <Select.Option value="Al Ain">Al Ain</Select.Option>
              <Select.Option value="Ajman">Ajman</Select.Option>
              <Select.Option value="RAK City">RAK City</Select.Option>
              <Select.Option value="Fujairah">Fujairah</Select.Option>
              <Select.Option value="Umm al-Quwain">Umm al-Quwain</Select.Option>
              <Select.Option value="Khor Fakkan">Khor Fakkan</Select.Option>
              <Select.Option value="Kalba">Kalba</Select.Option>
              <Select.Option value="Jebel Ali">Jebel Ali</Select.Option>
              <Select.Option value="Dibba Al-Fujairah">Dibba Al-Fujairah</Select.Option>
              <Select.Option value="Madinat Zayed">Madinat Zayed</Select.Option>
              <Select.Option value="Ruwais">Ruwais</Select.Option>
              <Select.Option value="Liwa Oasis">Liwa Oasis</Select.Option>
              <Select.Option value="Dhaid">Dhaid</Select.Option>
              <Select.Option value="Ghayathi">Ghayathi</Select.Option>
              <Select.Option value="Ar-Rams">Ar-Rams</Select.Option>
              <Select.Option value="Dibba Al-Hisn"> 	Dibba Al-Hisn</Select.Option>
              <Select.Option value="Hatta">Hatta</Select.Option>
              <Select.Option value="Al Madam">Al Madam</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='description' label="description" >
            <Input.TextArea />
          </Form.Item>
          <Form.Item name='address' label="address" >
            <Input.TextArea />
          </Form.Item>
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
      </Form>
      </>
     
    );
  }
}


export default AdminEditHotel;
