import React from 'react';
import { PhoneOutlined ,MailOutlined , EnvironmentOutlined , ShoppingOutlined  } from '@ant-design/icons';
import { Row , Col } from 'antd';
import Img4 from '../img/img4.jpg';
import Img5 from '../img/blog1.jpg';
import {Link} from 'react-router-dom';


class Footer extends React.Component {


  render() {
    return (
     <div className="footer">
       <div className="footer-one">
        <div className="footer-one-init"> 
          <Col className="footer-one-item" xl={{span:6}} lg={{span:6}} md={{span:12}} sm={{span:12}} xs={{span:24}}>
            <h3>Contact Us</h3>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been industry's printing and </p>
            <Row><EnvironmentOutlined /> <span className="footer-one-item-des">Address: 20/F Green Road, Dhaka</span></Row>
            <Row><PhoneOutlined/> <span className="footer-one-item-des">09132757574</span></Row>
            <Row><MailOutlined/> <span className="footer-one-item-des">info@admin.com</span></Row>
            <Row><ShoppingOutlined /> <span className="footer-one-item-des">Address: 20/F Green Road, Dhaka</span></Row>
          </Col>
          <Col className="footer-one-item" xl={{span:6}} lg={{span:6}} md={{span:12}} sm={{span:12}} xs={{span:24}} style={{paddingLeft:"50px"}}>
            <h3>Links</h3>
            <Row><Link to='/'><span className="">Home</span></Link></Row>
            <Row><Link to='/privacy'><span className="">Privacy</span></Link></Row>
            <Row><Link to='/service'><span className="">Service</span></Link></Row>
            {/* <Row><span className="">Car</span></Row> */}
            {/* <Row><span className="">About me</span></Row> */}
          </Col>
          <Col className="footer-one-item" xl={{span:6}} lg={{span:6}} md={{span:12}} sm={{span:12}} xs={{span:24}}>
            <h3>Popular Posts</h3>
            <div className="footer-one-item-post">
              <div className="footer-one-item-post-img">
                <img src={Img4} alt="image"></img>
              </div>
              <div className="footer-one-item-post-des">
                <h4>title product - descrption</h4>
                <p>Aug 18, 2020 | $485,00</p>
              </div>
            </div>
            <div className="footer-one-item-post">
              <div className="footer-one-item-post-img">
                <img src={Img4} alt="image"></img>
              </div>
              <div className="footer-one-item-post-des">
                <h4>title product - descrption</h4>
                <p>Aug 18, 2020 | $485,00</p>
              </div>
            </div>
            <div className="footer-one-item-post">
              <div className="footer-one-item-post-img">
                <img src={Img4} alt="image"></img>
              </div>
              <div className="footer-one-item-post-des">
                <h4>title product - descrption</h4>
                <p>Aug 18, 2020 | $485,00</p>
              </div>
            </div>
          </Col>
          <Col className="footer-one-item" xl={{span:6}} lg={{span:6}} md={{span:12}} sm={{span:12}} xs={{span:24}}>
            <h3>About Us</h3>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been industry's printing and </p>
          </Col>
        </div>  
       </div>
       <div className="footer-two">
        <div className="footer-two-init">
        © 2020 Theme Vessel. All Rights Reserved.
        </div>
       </div>
     </div>
    );
  }
}

export default Footer;
