import React from 'react';
import { Carousel  } from 'antd';
import Img1 from '../img/1.png';
import Img2 from '../img/2.png';
import Img3 from '../img/3.png';
import Img4 from '../img/4.png';
import Img5 from '../img/5.jpg';







class CarouselDetailHotel extends React.Component {


  render() {
 

    return (
     <div className="carousel-detail">
      <Carousel autoplay>
      {this.props.detail.map((item, index) =>
        <div key={index} className="carousel-detail-item">
          <img src={`https://downloadforever.ir/saeed/RealEstate/public/images/${item.image}`} alt="title"></img>
        </div>
      )}
      </Carousel>
     </div>
    );
  }
}

export default CarouselDetailHotel;
