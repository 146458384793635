import React from 'react';
import  { useState } from 'react';
import { Form, Input, Button  } from 'antd';
import { Row, Col } from 'antd';
import axios from 'axios';
import PANEL_BASE_URL from '../../../PANEL_BASE_URL';
import Loader2 from '../../widget/Loader2';


 
function CompanyAddAgent() {
  
  const [images, setImages] = useState({});
  const [processing, setprocessing] = useState(false);


  const crop = {
    unit: '%',
    aspect: 4 / 3,
    width: '100'
  };


  const onFinish = values => {
    setprocessing(true);
    const postdata = {
      "name":values.user.name,
      "email":values.user.email,
      "type":"agent",
      "country":values.user.country,
      "password":values.user.password,
      "languages":values.user.languages,
      "image":"wdwd",
      "token":localStorage.getItem("companytoken"),
    }
    // for(var i in images){
    //   postdata["img" + i] = images[i];
    // }
    axios.post(PANEL_BASE_URL + `/addAgent`,  postdata )
    .then((res) => {
      if(res.data.result === 'ok'){
        setprocessing(false);
        return window.location.href = '/panel/company/listagent'
      }
      else if (res.data.error === "unauthenticated"){
        localStorage.clear(); 
        return window.location.href = '/login'
      }
    })
  };
 
  const layout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 22 },
  };
  
  return (
  <div className="add-product-form">  
    {processing ? <Loader2/> : null}
    <Form {...layout} name="nest-messages" onFinish={onFinish} >
      <Form.Item name={['user', 'name']} label="name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name={['user', 'email']} label="email"  rules={[{ required: true , type: 'email' }]} >
          <Input/>
      </Form.Item>
      <Form.Item name={['user', 'country']} label="country" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name={['user', 'password']} label="password" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name={['user', 'languages']} label="languages" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      {/* <Row justify="end">
        <Col span={22}>
          <MultiImageInput
          images={images}
          setImages={setImages}
          cropConfig={{ crop, ruleOfThirds: true }}
          max={1}
          theme="light"
          />
        </Col>
      </Row> */}
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
</div>    
  );
}


export default CompanyAddAgent;